// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// Utility functions.
import brixxUtils from '../misc/brixxUtils';
// Translator.
import Translator from '../misc/translator';
// Brixx theme.
import Theme from '../misc/theme';
// BRIXX UI Templates component.
import '../components/ui-templates';
// BRIXX UI Forms component.
import '../components/ui-forms';
// Awesomplete plugin.
import 'awesomplete';
// Form serializer.
import 'jquery-serializejson';

/**
 * Contains all scripts specific to devis forms.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utilities.
 * @param {object} JSON
 *   JSON helper.
 * @param {Translator} Translator
 *   BRIXX Translator.
 * @param {Theme} Theme
 *   BRIXX Theme.
 */
(($, Brixx, brixxUtils, JSON, Translator, Theme, window) => {

    // Whether the calculation-devis module has already been initialized.
    if (typeof Brixx.modules.moduleCalcDevis !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * Calculates devis sums within the devis form.
     *
     * This function is a remainder of the "original"
     * BRIXX JS and should be refactored and simplified
     * as time/budget allow.
     *
     * @param {HTMLElement|null} elem
     *   The form element.
     *
     * @todo: Refactor this legacy function.
     */
    const funcCalcRowsDevis = elem => {
        const $form = $(elem || this);

        // Initialize the total with the service contract total,
        // which should be an empty value for all other types than
        // service contracts.
        let total = Brixx.forms.getNumberValue($('.contract-total', $form), 0.0);

        // Special unit for material items that will exclude the price
        // in material totals.
        const noPriceUnit = Brixx.getSetting('moduleCalcDevis.noPriceUnit', 'n');

        // Whether to apply OTC calculation (positions incl. VAT).
        const isOtc = $('.is-otc', $form).val() || false;

        // Calculate catalogs.
        $('.material-catalogs > .ui-listing-list > .ui-listing-item:not(.hide)', $form).each((indexCatalog, catalog) => {
            const $catalog = $(catalog);
            let catalogTotal = 0.0;

            // Calculate catalog items of the catalog.
            $('.material-items > .ui-listing-list > .ui-listing-item:not(.hide)', $catalog).each((indexItem, item) => {
                const $item = $(item);

                // Whether the item is not a devis material.
                if ($('.ma-type', $item).val() !== 'devis') {
                    return;
                }

                let maTotal = 0;

                const maAmount = Brixx.forms.getNumberValue($('.ma-amount', $item), 0);
                const maPrice = Brixx.forms.getNumberValue($('.ma-price', $item), 0);
                const maUnit = $('.ma-unit', $item).val().toString().toLowerCase();

                if (
                    maAmount
                    && maPrice
                    && maUnit !== 'per'
                    && maUnit !== noPriceUnit
                ) {
                    maTotal = brixxUtils.currencySumRound(maAmount * maPrice);
                }

                Brixx.forms.setValue($('.ma-total', $item), maTotal);
                catalogTotal += maTotal;

                // Handle position discount.
                let discount = JSON.parse($('.ma-discount', $item).val() || '{ }');
                if (
                    !discount
                    || !Object.prototype.hasOwnProperty.call(discount, 'type')
                    || !Object.prototype.hasOwnProperty.call(discount, 'value')
                    || !discount.type
                    || !parseFloat(discount.value)
                ) {
                    $('.ma-discount-info', $item).addClass('hide');
                    $('.ma-discount', $item).val('');
                    return;
                }
                // Replace `@text` token in discount description text.
                let infoText = brixxUtils.formatString(discount.description, {
                    '@text': $('.ma-name', $item).val() || ''
                });
                // Add absolute percentage value, if it's a percent discount.
                if (discount.type === 'percent') {
                    infoText = brixxUtils.numberFormat(Math.abs(parseFloat(discount.value))) + '% ' + infoText;
                }
                // Replace info text in catalog item.
                $('.ma-discount-info__text', $item).html(infoText);

                // Recalculate discount total, if required.
                let value = parseFloat(discount.value);
                let total = parseFloat(discount.total);
                if (discount.type === 'percent') {
                    total = brixxUtils.numberRound(maTotal * value / 100, 2, parseInt(discount.round) ? Brixx.settings.currencyDecimalsMod : 1);
                }
                else if (discount.type !== 'absolute') {
                    total = maTotal - value;
                }
                discount.value = value;
                discount.total = total;

                $('.ma-discount', $item).val(JSON.stringify(discount));
                Brixx.forms.setValue($('.ma-discount-info__value', $item), -1 * total);

                $('.ma-discount-info', $item).removeClass('hide');
                catalogTotal -= total;
            });

            Brixx.forms.setValue($('.catalog-total', $catalog), catalogTotal);
            total += catalogTotal;
        });

        // Set catalogs total value.
        Brixx.forms.setValue($('.catalogs-total', $form), total);

        // Calculate deduction groups.
        $('.deductiongroup', $form).each((indexGroup, group) => {
            const $group = $(group);
            let groupTotal = 0.0;

            // Calculate deductions of the group.
            $('.ui-listing-item:not(.hide) .deduction', $group).each((deductionIndex, deduction) => {
                const $deduction = $(deduction);
                // Calculated total value of the deduction.
                let deductionTotal = 0;

                // Get deduction value.
                const deductionValue = Brixx.forms.getNumberValue($('.deduction-value', $deduction), 0);
                // Determine deduction type.
                const deductionType = $('.deduction-type', $deduction).val();

                // Whether the user has given a non-empty deduction value.
                if (deductionValue) {
                    switch (deductionType) {
                        // Percent value.
                        case 'percent':
                            deductionTotal = brixxUtils.numberRound(total * deductionValue / 100, 2);
                            break;

                        case 'vat':
                            $('.deduction-round-wrapper', $deduction).removeClass('hide');
                            $('.deduction-empty-wrapper', $deduction).addClass('hide');
                            deductionTotal = !isOtc ? brixxUtils.numberRound(total * deductionValue / 100, 2) : brixxUtils.numberRound(total * (1 - (100 / (100 + deductionValue))), 2);
                            break;

                        // Absolute value.
                        case 'absolute':
                            deductionTotal = deductionValue;
                            break;

                        // Unknown/unsupported deduction type.
                        default:
                            // Bail out.
                            return;
                    }

                    const $roundingElement = $('.deduction-round', $deduction);
                    const round = $roundingElement.is('[type="checkbox"]:checked') || ($roundingElement.is('[type="hidden"]') && $roundingElement.val());

                    if (round) {
                        deductionTotal = brixxUtils.currencySumRound(deductionTotal);
                    }

                    const negate = $('.deduction-negate', $deduction).val();
                    groupTotal += deductionTotal;
                    if (deductionType !== 'vat' || !isOtc) {
                        total = (deductionType === 'vat' || negate) ? total + deductionTotal : total - deductionTotal;
                    }
                }
                else if (deductionType === 'vat') {
                    $('.deduction-round-wrapper', $deduction).addClass('hide');
                    $('.deduction-empty-wrapper', $deduction).removeClass('hide');
                }

                Brixx.forms.setValue($('.deduction-total', $deduction), deductionTotal);
            });

            Brixx.forms.setValue($('.deductiongroup-total', $group), groupTotal);
            Brixx.forms.setValue($('.subtotal', $group), total);
        });

        // Set deducted total value.
        Brixx.forms.setValue($('.deducted-total', $form), total);

        // Target total.
        const targetTotal = Brixx.forms.getNumberValue($('.target-total', $form), 0);
        if (targetTotal) {
            const vatPercent = Brixx.forms.getNumberValue($('.deductiongroup-vat .deduction-value', $form), 0);
            const diffTarget = (targetTotal) ? total - targetTotal : 0;

            let totalTargetCalc = '';
            let totalTargetVat = '';
            if (diffTarget) {
                if (!isOtc) {
                    totalTargetCalc = Math.floor(diffTarget * 10000 / (100 + vatPercent)) / 100;
                    totalTargetVat = diffTarget - totalTargetCalc;
                }
                else {
                    totalTargetCalc = diffTarget;
                    totalTargetVat = brixxUtils.numberRound(diffTarget * (1 - (100 / (100 + vatPercent))), 2);
                }
            }

            Brixx.forms.setValue($('.target-total-calc', $form), totalTargetCalc);
            Brixx.forms.setValue($('.target-total-vat', $form), totalTargetVat);
            total = targetTotal;
        }
        else {
            Brixx.forms.setValue($('.target-total-calc', $form), 0);
            Brixx.forms.setValue($('.target-total-vat', $form), 0);
        }

        // Final devis total.
        Brixx.forms.setValue($('.devis-total', $form), total);
    };

    /**
     * BRIXX module for devis lists and forms.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.moduleCalcDevis = {

        /**
         * Saves the source data field for a dimensions calculator modal dialog.
         *
         * This field is set on dialog open.
         */
        modalDataSource: null,

        /**
         * Saves the target field for calculated dimensions.
         *
         * This field is set on dimensions calculator dialog open and allows to
         * write results back on dialog close action.
         */
        modalDataTarget: null,

        /**
         * Shows the 'Add calculation regie' modal dialog.
         *
         * This callback is triggered after the modal content data has
         * been received from the BRIXX App server.
         *
         * @param {object} data
         *   The regie rapports modal content data.
         */
        showRegie: data => {
            const $modal = $('#popup-modal-devis');
            if ($modal.length === 0) {
                return;
            }

            $('.popup-modal-title', $modal).html(Translator.translate('ui.modules.calc-devis.regie-billing'));

            const $formContent = $('form .form-content', $modal);
            Brixx.detachModules($formContent);

            const formContent = Theme.templates['modal_devis_regie'](data);
            $formContent
                .empty()
                .append(formContent);

            Brixx.attachModules($formContent);

            // Bind the modal form submit handler.
            $('.submitter', $modal)
                .show()
                .once('modalDevisSubmit')
                .on('click.modalDevisSubmit', event => {
                    const $form = $(event.currentTarget).closest('form');
                    const data = $form.serializeJSON();
                    const $dialog = $(event.currentTarget).closest('.modal-popup');

                    const $button = $(Brixx.modules.moduleCalcDevis.modalDataSource);
                    const $list = $(Brixx.modules.moduleCalcDevis.modalDataTarget);
                    const $listing = $list.closest('.ui-listing');
                    if ($button.length === 0 || $listing.length === 0 || $list.length === 0 || typeof data.source === 'undefined') {
                        $dialog.foundation('close');
                        return;
                    }

                    const reportsData = JSON.parse(data.source);
                    let $newItem;

                    $.each(data.reports, (index, item) => {
                        if (typeof reportsData.reports[item] === 'undefined') {
                            return;
                        }

                        // Get template ID.
                        const templateId = $button.data('template');

                        // Holds the new row until it has been added to the list.
                        $newItem = Brixx.modules.uiListings.createListingItem($list, templateId);

                        // Apply values.
                        const values = {
                            '.ma-type': 'devis',
                            '.ma-pos': '||AUTOINCREMENT||.ma-type[value="devis"]',
                            '.ma-name': Translator.translate('ui.regie.title-no-date', {'@title': reportsData.reports[item].title, '@number': reportsData.reports[item].number, '@date': brixxUtils.dateFormat(reportsData.reports[item].date, 'L')}),
                            '.ma-unit': 'LE',
                            '.ma-reference': 'ModuleItemCalculationRegie|' + reportsData.reports[item].id,
                            '.ma-amount': 1,
                            '.ma-price': reportsData.reports[item].deductedNet,
                            '.ma-total': reportsData.reports[item].deductedNet
                        };
                        Brixx.modules.uiListings.setListingItemValues($newItem, values, $list);
                        $list.append($newItem);

                        // Reindex items.
                        Brixx.modules.uiListings.reIndexItems($list);
                    });

                    // Hide remove buttons, if only one row exists.
                    $('.button-remove, ' + ($listing.data('handle') || '.handle'), $list).css('display', ($list.children('.ui-listing-item:not(.hide)').filter((index, element) => $(element).closest('.ui-listing').is($listing)).length > 1) ? '' : 'none');

                    // (Re-attach) modules.
                    Brixx.attachModules($listing);

                    // Re-calculate, if we are within one of the BRIXX
                    // calculation forms.
                    $list.closest('form').trigger('calculate');

                    // Close the modal dialog.
                    $dialog.foundation('close');

                    // Set focus on first focusable element within the new row.
                    $newItem.find(':focusable').first().trigger('focus');
                });
        },

        /**
         * Shows the 'Dimensions calculator' modal dialog.
         *
         * @param {DOMelement|jQuery|string} modal
         *   The dimensions calculation modal.
         */
        showCalc: modal => {
            const $modal = $(modal);

            // Initialize the modal dialog element for a new calculation:
            // Detach any event bindings from the dialog elements.
            Brixx.detachModules($modal);

            $('.popup-modal-title', $modal).html(Translator.translate('ui.devis.calc-modal.title'));

            const $formContent = $('form .form-content', $modal);
            Brixx.detachModules($formContent);
            $('.submitter', $modal)
                .off('click.modalDevisSubmit')
                .removeOnce('modalDevisSubmit')
                .hide();

            const formContent = Theme.templates['modal_devis_dimensions']();
            $formContent
                .empty()
                .append(formContent);

            // Show the dialog.
            $modal.foundation('open');
            // Attach event listeners to the dialog elements.
            Brixx.attachModules($modal);

            // Get the data to initialize the dialog content.
            let data = Brixx.modules.moduleCalcDevis.modalDataSource.val() || '[]';
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }

            // Loop through all initial dialog data rows.
            $.each(data, (index, row) => {
                // Whether any of the row values is not empty (to skip empty rows).
                if (row.description || row.formula || row.result) {
                    // Set the field values of the last dialog row.
                    $modal.find('.calc-modal-description').last().val(row.description);
                    $modal.find('.calc-modal-formula').last().val(row.formula);
                    $modal.find('.calc-modal-result').last().val(brixxUtils.numberFormat(row.result || 0, 2));
                    // Trigger a "add row" button click to add another row at the
                    // end.
                    $modal.find('.button-add-one a.button-add').trigger('click');
                }
            });

            // Recalculate the modal totals.
            $modal.find('form').trigger('calculate');

            // Set a focus on the last row's description field.
            $modal.find('.calc-modal-description').last().trigger('focus');

            // Bind the modal form submit handler.
            $('.submitter', $modal)
                .show()
                .once('modalDevisSubmit')
                .on('click.modalDevisSubmit', event => {
                    let $form = $(event.currentTarget).closest('form');
                    let $dialog = $(event.currentTarget).closest('.modal-popup');
                    let values = [];
                    let total = 0.0;

                    Brixx.detachModules($form);
                    let data = $form.serializeJSON();
                    Brixx.attachModules($form);

                    $.each(data.row, (index, item) => {
                        if (item.description || item.formula || item.result) {
                            // BRIXX-258: Re-calculate totals.
                            let formula = brixxUtils.formulaFromLocalizedUserInput(item.formula);
                            let result = formula.evaluate() || 0;
                            total += result;
                            item.formula = brixxUtils.formulaGetLocalizedExpression(formula, 2);
                            item.result = result;

                            values.push(item);
                        }
                    });
                    Brixx.modules.moduleCalcDevis.modalDataSource.val(JSON.stringify(values));
                    Brixx.modules.moduleCalcDevis.modalDataTarget.val(brixxUtils.numberFormat(total || 0, 2));
                    if (values.length > 0) {
                        Brixx.modules.moduleCalcDevis.modalDataTarget.addClass('has-calc').attr('readonly', 'readonly');
                    }
                    else {
                        Brixx.modules.moduleCalcDevis.modalDataTarget.removeClass('has-calc').removeAttr('readonly');
                    }
                    Brixx.modules.moduleCalcDevis.modalDataTarget.closest('form').trigger('calculate');

                    Brixx.modules.moduleCalcDevis.modalDataSource = null;
                    Brixx.modules.moduleCalcDevis.modalDataTarget = null;

                    $dialog.foundation('close');
                });
        },

        /**
         * Shows the 'Discount calculator' modal dialog.
         *
         * @param {DOMelement|jQuery|string} modal
         *   The discount calculation modal.
         */
        showDiscount: modal => {
            const $modal = $(modal);

            // Initialize the modal dialog element for a new calculation:
            // Detach any event bindings from the dialog elements.
            Brixx.detachModules($modal);

            $('.popup-modal-title', $modal).html(Translator.translate('ui.devis.calc-discount.title'));

            const $formContent = $('form .form-content', $modal);
            Brixx.detachModules($formContent);
            $('.submitter', $modal)
                .off('click.modalDevisSubmit')
                .removeOnce('modalDevisSubmit')
                .hide();

            const formContent = Theme.templates['modal_devis_discount']();
            $formContent
                .empty()
                .append(formContent);

            // Show the dialog.
            $modal.foundation('open');
            // Attach event listeners to the dialog elements.
            Brixx.attachModules($modal);

            // Get the data to initialize the dialog content.
            let $dataSource = $(Brixx.modules.moduleCalcDevis.modalDataSource);

            // Description help text with devis position name.
            let baseName = $dataSource.closest('.material-item').find('.ma-name').val() || '';
            $modal.find('.description.mdd-description').html(Translator.translate('ui.devis.calc-discount.description.description', {
                '@name': baseName
            }));

            // Base value from called devis position.
            let baseValue = Brixx.forms.getNumberValue($dataSource.closest('.material-item').find('.ma-total'), 0);
            Brixx.forms.setValue($modal.find('#mdd-base'), baseValue);

            // Decode position discount values.
            let data = Brixx.modules.moduleCalcDevis.modalDataSource.val() || '{ }';
            if (typeof data === 'string') {
                data = JSON.parse(data);
            }

            // Discount type.
            let type = 'percent';
            let isNew = true;
            if (Object.prototype.hasOwnProperty.call(data, 'type')) {
                type = data.type || 'percent';
                isNew = false;
            }
            $modal.find('[name="type"][value="' + type + '"]').prop('checked', true);

            if (!isNew) {
                if (Object.prototype.hasOwnProperty.call(data, 'description')) {
                    $modal.find('[name="description"]').val(data.description);
                }
                if (Object.prototype.hasOwnProperty.call(data, 'value') && data.value) {
                    Brixx.forms.setValue($modal.find('#mdd-' + type), data.value);
                }
                if (Object.prototype.hasOwnProperty.call(data, 'round') && data.round) {
                    $modal.find('[name="round"]').prop('checked', true);
                }
                else {
                    $modal.find('[name="round"]').removeAttr('checked');
                }
            }

            // Recalculate the modal totals.
            $modal.find('form').trigger('calculate');

            // Set a focus on the first form field.
            $modal.find('form :focusable').first().trigger('focus');

            // Bind the modal form submit handler.
            $('.submitter', $modal)
                .show()
                .once('modalDevisSubmit')
                .on('click.modalDevisSubmit', event => {
                    let $form = $(event.currentTarget).closest('form');
                    let $dialog = $(event.currentTarget).closest('.modal-popup');

                    Brixx.detachModules($form);
                    let data = $form.serializeJSON();
                    let $target = $(Brixx.modules.moduleCalcDevis.modalDataSource);

                    // Write discount information to the target field only, if the discount
                    // is valid.
                    if (
                        !Object.prototype.hasOwnProperty.call(data, 'type')
                        || !Object.prototype.hasOwnProperty.call(data, 'value')
                        || !data.type
                        || !parseFloat(data.value)
                    ) {
                        $target.val('');
                    }
                    else {
                        $target.val(JSON.stringify(data));
                    }

                    // Recalculate the devis form to show/hide discount information.
                    $target.closest('form').trigger('calculate');

                    // Clean up and close the modal dialog.
                    Brixx.modules.moduleCalcDevis.modalDataSource = null;
                    Brixx.modules.moduleCalcDevis.modalDataTarget = null;
                    $dialog.foundation('close');
                });
        },

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {

            // Listing checkbox submit.
            $('.ui-listing-item .checkbox-devis', context).once('devis-calc-checkbox').on('click', event => {
                const $form = $(event.currentTarget).closest('form');
                if ($form.length > 0) {
                    Brixx.forms.ajaxSubmitReplace($form);
                }
            });

            // Discount calculator modal initialization.
            $('.devis-modal-discount', context).once('devis-modal').on('click', event => {
                event.preventDefault();

                let $button = $(event.currentTarget);
                let modalId = $button.data('modal-id');
                let $modal = $('#' + modalId);

                // Set data source field.
                Brixx.modules.moduleCalcDevis.modalDataSource = $button.closest('.material-item').find('.ma-discount');

                // Whether the modal dialog element or data source could
                // not be found within the page. This should never happen, if the
                // form markup is correct.
                if (
                    $modal.length === 0
                    || Brixx.modules.moduleCalcDevis.modalDataSource.length === 0
                ) {
                    // Bail out.
                    return;
                }

                Brixx.modules.moduleCalcDevis.showDiscount($modal);
            });

            // Dimensions calculator modal initialization.
            $('.devis-calc-amount', context).once('devis-calc-modal').on('click', event => {
                event.preventDefault();

                let $button = $(event.currentTarget);
                let modalId = $button.data('modal-id');
                let $modal = $('#' + modalId);

                // Set data source and result target field.
                Brixx.modules.moduleCalcDevis.modalDataSource = $button.parent().find('.ma-calc');
                Brixx.modules.moduleCalcDevis.modalDataTarget = $button.parent().find('.ma-amount');

                // Whether the modal dialog element, data source or data target could
                // not be found within the page. This should never happen, if the
                // form markup is correct.
                if (
                    $modal.length === 0
                    || Brixx.modules.moduleCalcDevis.modalDataSource.length === 0
                    || Brixx.modules.moduleCalcDevis.modalDataTarget.length === 0
                ) {
                    // Bail out.
                    return;
                }

                Brixx.modules.moduleCalcDevis.showCalc($modal);
            });

            // Calculate event handler for the dimensions calculator modal
            // content.
            $('.modal-devis-calculations', context)
                .closest('form')
                .once('calc-modal-calculate')
                .on('calculate', event => {
                    const $form = $(event.currentTarget);

                    // Calculate rows total.
                    let sum = 0;
                    $('.calc-modal-result', $form).each((index, element) => {
                        sum += brixxUtils.parseNumberFormat($(element).val(), 0);
                    });
                    $('.calc-modal-total', $form).val(brixxUtils.numberFormat(sum, 2));
                });

            $('.mdd-form input:not([name="description"]):not([type="hidden"])').on('change keyup calculate', event => {
                $(event.currentTarget).closest('form').trigger('calculate');
            });

            $('.mdd-form', context)
                .closest('form')
                .once('mdd-calculate')
                .on('calculate', event => {
                    const $form = $(event.currentTarget);

                    const type = $form.find('[name="type"]:checked').val();
                    const $baseField = $form.find('#mdd-base');
                    const $percentField = $form.find('#mdd-percent');
                    const $absoluteField = $form.find('#mdd-absolute');
                    const $targetField = $form.find('#mdd-target');
                    const $valueField = $form.find('[name="value"]');
                    const $totalField = $form.find('[name="total"]');
                    const $roundField = $form.find('[name="round"]');
                    let value = 0;
                    let percent = 0;
                    let absolute = 0;
                    let total = 0;
                    const base = Brixx.forms.getNumberValue($baseField, 0.0);

                    if (type === 'percent') {
                        $percentField.removeAttr('readonly');
                        $absoluteField.attr('readonly', true);
                        $targetField.attr('readonly', true);
                        $roundField.closest('.form-element').show();

                        value = Brixx.forms.getNumberValue($percentField, 0.0);
                        absolute = brixxUtils.numberRound(base * value / 100, 2, $roundField.is(':checked') ? settings.currencyDecimalsMod : 1);
                        total = base - absolute;

                        Brixx.forms.setValue($absoluteField, absolute);
                        Brixx.forms.setValue($targetField, total);
                    }
                    else if (type === 'absolute') {
                        $percentField.attr('readonly', true);
                        $absoluteField.removeAttr('readonly');
                        $targetField.attr('readonly', true);
                        $roundField.closest('.form-element').hide();

                        value = Brixx.forms.getNumberValue($absoluteField, 0.0);
                        absolute = value;
                        percent = value * 100 / base;
                        total = base - absolute;

                        Brixx.forms.setValue($percentField, percent);
                        Brixx.forms.setValue($targetField, total);
                    }
                    else {
                        $percentField.attr('readonly', true);
                        $absoluteField.attr('readonly', true);
                        $targetField.removeAttr('readonly');
                        $roundField.closest('.form-element').hide();

                        total = value = Brixx.forms.getNumberValue($targetField, 0.0);
                        absolute = base - total;
                        percent = absolute * 100 / base;

                        Brixx.forms.setValue($percentField, percent);
                        Brixx.forms.setValue($absoluteField, absolute);
                    }

                    Brixx.forms.setValue($valueField, value);
                    Brixx.forms.setValue($totalField, absolute);
                });

            $('.calc-modal-formula', context).once('calc-modal-formula').each((index, element) => {
                let $ele = $(element);

                $ele.on('focusout', event => {
                    let $element = $(event.currentTarget);
                    let $listing = $element.closest('.ui-listing');
                    let $item = $element.closest('.ui-listing-item');
                    let $form = $element.closest('form');
                    let $submit = $form.find('button.submitter');

                    Brixx.forms.validate($form);
                    const elementValue = Brixx.forms.getValue($element);
                    if (!$element.hasClass('error') && elementValue) {
                        let formula = brixxUtils.formulaFromLocalizedUserInput(elementValue);
                        let result = formula.evaluate() || 0;
                        $element.val(brixxUtils.formulaGetLocalizedExpression(formula, 2));
                        $item.find('.calc-modal-result').val(brixxUtils.numberFormat(result, 2));
                    }
                    else {
                        $item.find('.calc-modal-result').val('');
                    }

                    $form.trigger('calculate');

                    if ($item.is(':last-child')) {
                        if ($element.val()) {
                            $listing.find('.button-add-one a.button-add').trigger('click');
                            $listing.find('.calc-modal-description').last().trigger('focus');
                        }
                        else {
                            $submit.trigger('focus');
                        }
                    }
                });

                $ele.on('keydown', event => {
                    if (event.keyCode === 13) {
                        event.preventDefault();
                        $(event.currentTarget).trigger('focusout');
                    }
                });
            });

            // Add or remove initial 'has-calc' class on '.ma-amount' elements
            // depending on the content of their related '.ma-calc' field.
            // We require related amount element and calculation element to have
            // the same parent.
            $('.ma-calc', context).once('initMaCalcSource').each((index, element) => {
                let $element = $(element);
                let $amountElement = $element.parent().find('.ma-amount');
                // Whether there is no related amount element.
                if ($amountElement.length === 0) {
                    // Bail out.
                    return;
                }

                // Get the value of the calculation element.
                let data = $element.val() || '[]';
                // Decode JSON encoded formula items.
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }
                // Whether we have an array with content.
                if (Array.isArray(data) && data.length !== 0) {
                    // Add 'has-calc' class to '.ma-amount' element.
                    $amountElement.addClass('has-calc').attr('readonly', 'readonly');
                }
                // We have no formula items.
                else {
                    // Remove the 'has-calc' class from the '.ma-amount'
                    // element.
                    $amountElement.removeClass('has-calc').removeAttr('readonly');
                }
            });

            $('.button-add-regie', context).once('initAddRegieModal').on('click', event => {
                let $button = $(event.currentTarget);
                let modalId = $button.data('modal-id');
                let $modal = $('#' + modalId);

                // Set data source and result target field.
                Brixx.modules.moduleCalcDevis.modalDataSource = $button;
                const $listing = $button.closest('.ui-listing');
                Brixx.modules.moduleCalcDevis.modalDataTarget = $('.ui-listing-list', $listing).filter((index, element) => $(element).closest('.ui-listing').is($listing));

                // Whether the modal dialog element, data source or data target could
                // not be found within the page. This should never happen, if the
                // form markup is correct.
                if (
                    $modal.length === 0
                    || Brixx.modules.moduleCalcDevis.modalDataSource.length === 0
                    || Brixx.modules.moduleCalcDevis.modalDataTarget.length === 0
                ) {
                    // Bail out.
                    return;
                }

                // Detach any event bindings from the dialog elements.
                Brixx.detachModules($modal);
                $('.submitter', $modal)
                    .off('click.modalDevisSubmit')
                    .removeOnce('modalDevisSubmit')
                    .hide();
                $('form .form-content', $modal).empty().append($('<div class="ajax-loader grid-x align-middle"><div class="cell text-center"><p>' + Translator.translate('ui.modules.calc-devis.loading-regie') + '</p><img src="/bundles/brixx/img/form-loader.gif" alt="loader"></div></div>'));

                // Show the dialog.
                $modal.foundation('open');

                // Attach event listeners to the dialog elements.
                Brixx.attachModules($modal);
            });

            $('#popup-modal-devis .form-actions .btn-cancel', context)
                .once('devisModalCancel')
                .on('click', event => {
                    let $dialog = $(event.currentTarget).closest('.modal-popup');

                    Brixx.modules.moduleCalcDevis.modalDataSource = null;
                    Brixx.modules.moduleCalcDevis.modalDataTarget = null;

                    $dialog.foundation('close');
                });

            // (Re-)Calculation handlers.
            $('.module-item-devis-form', context).once('calculate').on('keyup.calculate calculate', event => {
                funcCalcRowsDevis(event.currentTarget);
            }).trigger('calculate');

            $('.module-item-devis-form .deduction-round').once('recalconclick').on('change', event => {
                $(event.currentTarget).closest('form').trigger('calculate');
            });
        },

        detach: context => {
            $('.calc-modal-formula', context).findOnce('calc-modal-formula').each((index, element) => {
                const $element = $(element);
                $element.removeOnce('calc-modal-formula');
                $element.off('focusout').off('keydown');
            });
            $('.ma-calc-source', context).findOnce('initMaCalcSource').each((index, element) => {
                let $element = $(element);
                let $amountElement = $element.parent().find('.ma-amount');
                $element.removeOnce('initMaCalcSource');

                if ($amountElement.length === 0) {
                    return;
                }

                let data = $element.val() || '[]';
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }
                if (data.length === 0) {
                    $amountElement.removeClass('has-calc');
                }
                else {
                    $amountElement.addClass('has-calc');
                }
            });
        }
    };

})(jQuery, Brixx, brixxUtils, JSON, Translator, Theme, window);
