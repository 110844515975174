// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// Brixx object.
import Brixx from '../misc/brixx';
// BRIXX UI ajax handlers.
import '../components/ui-ajax';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    // Whether the notifications module has already been initialized.
    if (typeof Brixx.modules.uiNotifications !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for UI notifications.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiNotifications = {

        /**
         * Module initialization.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach init to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {
            $('body').once('ui-notifications').each(() => {
                const $notificationButton = $('#notifications-button');
                if ($notificationButton.length === 0) {
                    return;
                }
                const url = $notificationButton.data('update-url');
                if (url) {
                    setInterval(() => {
                        $.ajax({
                            type: 'get',
                            url: url
                        })
                            .done(responseData => {
                                Brixx.uiAjaxResponseHandler(responseData);
                            });
                    }, settings.notificationsRefreshInterval || 10000);
                }
            });
        }

    };

})(jQuery, Brixx);
