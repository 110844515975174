// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// BRIXX UI Forms component.
import '../components/ui-forms';

/**
 * Contains all scripts specific to the uploads modules.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    // Whether the upload module has already been initialized.
    if (typeof Brixx.modules.moduleUpload !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for devis lists and forms.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.moduleUpload = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: (context) => {
            $('.folder-item input[type="text"], .folder-item textarea', context).once('module-upload-focus-select').on('focus', event => {
                $(event.currentTarget).select();
            });
            $('.folder-add-button', context).once('module-upload-add-folder').on('click', event => {
                const $button = $(event.currentTarget);
                const $listing = $button.closest('.folder-view').find('.gallery');
                const $addForm = $('.create-new-form', $listing);
                $addForm.removeClass('hide');
                $('textarea, input[type="text"]', $addForm).each((index, element) => {
                    const $element = $(element);
                    $element.val($element.data('default-text') || '').trigger('focus');
                });
            });
            $('.create-new-form .item-add-cancel button', context).once('module-upload-add-folder').on('click', event => {
                $(event.currentTarget).closest('.create-new-form').addClass('hide');
            });
            $('.folder-item .item-edit button', context).once('module-upload-item-edit').on('click', event => {
                const $button = $(event.currentTarget);
                const $item = $button.closest('.folder-item');
                const defaultName = $('.item-details .item-name .ta-grow textarea', $item).data('default-text') || '';
                $('.item-details .item-name .ta-grow textarea', $item).val(defaultName);
                $('.item-details .item-name .ta-grow span', $item).html(defaultName + '&nbsp;');
                $('.item-details .item-name > span, .item-details .item-name > a, .item-details .item-info, .item-actions .item-download, .item-actions .item-edit, .item-actions .item-delete', $item).addClass('hide');
                $('.item-details .item-name .ta-grow, .item-actions .item-save, .item-actions .item-cancel', $item).removeClass('hide');
                $('.item-details .item-name .ta-grow textarea', $item).trigger('focus');
            });
            $('.folder-item .item-cancel button', context).once('module-upload-item-edit').on('click', event => {
                const $button = $(event.currentTarget);
                const $item = $button.closest('.folder-item');
                $('.item-details .item-name > span, .item-details .item-name > a, .item-details .item-info, .item-actions .item-download, .item-actions .item-edit, .item-actions .item-delete', $item).removeClass('hide');
                $('.item-details .item-name .ta-grow, .item-actions .item-save, .item-actions .item-cancel', $item).addClass('hide');
            });
            $('.folder-item .item-delete button', context).once('module-upload-item-delete').on('click', event => {
                const $button = $(event.currentTarget);
                const $item = $button.closest('.folder-item');
                const $itemDelete = $('#' + $item.attr('id') + '-delete');
                $item.addClass('hide');
                $itemDelete.removeClass('hide');
            });
            $('.folder-item .item-delete-cancel button', context).once('module-upload-item-delete').on('click', event => {
                const $button = $(event.currentTarget);
                const $itemDelete = $button.closest('.folder-item');
                const $item = $('#' + $itemDelete.attr('id').replace('-delete', ''));
                $itemDelete.addClass('hide');
                $item.removeClass('hide');
            });
        }

    };

})(jQuery, Brixx);
