// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Theming helper.
import Theme from '../misc/theme';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {Theme} Theme
 *   The BRIXX theming helper.
 */
(($, Brixx, Theme) => {

    // Whether the UI templates module has already been initialized.
    if (typeof Brixx.modules.uiTemplates !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX templates module.
     *
     * Adds all Handlebars templates found within the page to the Theme.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiTemplates = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {

            // Add script templates to the theme templates.
            $('script[type="text/x-handlebars-template"]', context).once('processUiTemplate').each((index, template) => {
                const $template = $(template);
                // Extract the ID of the template. Templates MUST have an ID
                // following the pattern 'template-[ID]'.
                const id = $template.attr('id');
                if (!id) {
                    return;
                }
                if (id.length <= 9 || id.indexOf('template-') !== 0) {
                    return;
                }

                const key = id.substring(9);
                Theme.addTemplate(key, $template.html());
            });

            // Add module settings templates to the theme templates.
            if (typeof settings.templates !== 'undefined') {
                let key;
                for (let templateId in settings.templates) {
                    if (templateId.length > 9 && templateId.indexOf('template-') === 0 && Object.prototype.hasOwnProperty.call(settings.templates, templateId)) {
                        key = templateId.substring(9);
                        if (!Theme.templateExists(key)) {
                            Theme.addTemplate(key, settings.templates[templateId]);
                        }
                    }
                }
            }

        }
    };

})(jQuery, Brixx, Theme);
