// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// Brixx object.
import Brixx from '../misc/brixx';
// BRIXX settings.
import brixxUtils from '../misc/brixxUtils';
// Translator.
import Translator from '../misc/translator';
// FullCalendar plugin.
import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
// Foundation.
import {Foundation} from '../lib/foundation-explicit-pieces';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utilities.
 * @param {Translator} Translator
 *   BRIXX translator.
 * @param {object} window
 *   The global window object.
 */
(($, Brixx, brixxUtils, Translator, Calendar, dayGridPlugin, listPlugin, interactionPlugin, Foundation, window) => {

    // Whether the UI actions have already been initialized.
    if (typeof Brixx.modules.uiCalendar !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX UI actions module.
     *
     * Binds Ajax request handlers to elements with
     * `.use-ajax` CSS class.
     *
     * These elements MUST have either a `data-ajax-url`
     * (e.g. buttons, images, and other DOM elements) or a
     * `href` attribute (links) referring to a BRIXX App
     * endpoint that returns a JSON response with `uiActions`.
     *
     * An optional `data-ajax-event` attribute allows
     * to use an alternative to the default `click` event for
     * binding the Ajax request trigger.
     *
     * @type {Brixx~module}
     * @tutorial brixx-ui-actions
     */
    Brixx.modules.uiCalendar = {

        addEvent: (wrapper, event) => {
            $(wrapper)[0].calendar.addEvent(event, 'brixx');
        },

        deleteEvent: (wrapper, eventId) => {
            let originalEvent = $(wrapper)[0].calendar.getEventById(eventId);
            if (originalEvent) {
                originalEvent.remove();
            }
        },

        editEvent: (wrapper, event) => {
            let originalEvent = $(wrapper)[0].calendar.getEventById(event.id);
            if (originalEvent) {
                originalEvent.setProp('title', event.title);
                originalEvent.setExtendedProp('description', event.description);
                originalEvent.setExtendedProp('type', event.type);
                originalEvent.setExtendedProp('multi', event.multi);
                originalEvent.setExtendedProp('date_start', event.date_start);
                originalEvent.setExtendedProp('date_end', event.date_end);
                originalEvent.setDates(event.start, event.end);
            }
        },

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {
            $('.calendar-wrapper[data-calendar-load]', context).once('uiCalendar').each((index, element) => {
                const createEvent = info => {
                    const $modal = $('#popup-modal-calendar-event');
                    if (!$modal.length) {
                        return;
                    }

                    const dateStart = typeof info !== 'undefined' && typeof info.date !== 'undefined' && Object.prototype.toString.call(info.date) === '[object Date]' ? new Date(info.date.valueOf()) : new Date();

                    // Detach any event bindings from the dialog elements.
                    Brixx.detachModules($modal);

                    // Initialize the modal dialog labels and values.
                    $('[name="wrapper"]', $modal).val('#' + $(element).attr('id'));
                    $('form', $modal).attr('action', $(element).data('calendar-add'));
                    $('.popup-modal-title', $modal).html(Translator.translate('ui.calendar.add'));
                    $('#calendar_event_delete', $modal).hide();
                    $('[name="date"]', $modal)
                        .data('date-format', settings.dateFormat)
                        .val(brixxUtils.dateFormat(dateStart, settings.dateFormat.toUpperCase()));
                    $('[name="date_end"]', $modal)
                        .data('date-format', settings.dateFormat)
                        .val(brixxUtils.dateFormat(dateStart.addDays(1), settings.dateFormat.toUpperCase()));
                    $('[name="title"], [name="description"]', $modal).val('');
                    $('[name="multi"]', $modal).prop('checked', false);
                    $('[name="event_id"]', $modal).val('');
                    $('[name="event_type"]', $modal).val('statutory_holiday');
                    $('#popup-modal-event-date-end-wrapper', $modal).addClass('hide');
                    $('[type="submit"]', $modal).prop('disabled', false);

                    // Attach event bindings.
                    Brixx.attachModules($modal);

                    // Show the dialog.
                    $modal.foundation('open');
                };
                element.calendar = new Calendar(element, {
                    plugins: [dayGridPlugin, listPlugin, interactionPlugin],
                    defaultView: 'listYear',
                    editable: true,
                    eventSources: [
                        {
                            id: 'brixx',
                            url: $(element).data('calendar-load'),
                            method: 'POST',
                            extraParams: {
                                filters: JSON.stringify({})
                            },
                            failure: () => {
                                // alert("There was an error while fetching FullCalendar!");
                            }
                        }
                    ],
                    firstDay: 1,
                    // nextDayThreshold: '00:00:00',
                    displayEventTime: false,
                    eventClick: function (info) {
                        const $modal = $('#popup-modal-calendar-event');
                        if (!$modal.length) {
                            return;
                        }

                        // Detach any event bindings from the dialog elements.
                        Brixx.detachModules($modal);

                        // Initialize the modal dialog labels and values.
                        $('[name="wrapper"]', $modal).val('#' + $(element).attr('id'));
                        // Set the form action URL.
                        $('form', $modal).attr('action', $(element).data('calendar-edit'));
                        // Set the modal title.
                        $('.popup-modal-title', $modal).html(Translator.translate('ui.calendar.edit'));
                        // Set the delete URL and enable the button.
                        $('#calendar_event_delete', $modal).attr('href', brixxUtils.addUrlParameter(info.event.extendedProps.deleteUrl, 'wrapper', '#' + $(element).attr('id'))).show();
                        // Set start date.
                        $('[name="date"]', $modal)
                            .data('date-format', settings.dateFormat)
                            .val(brixxUtils.dateFormat(new Date(info.event.extendedProps.date_start), settings.dateFormat.toUpperCase()));
                        // Set end date and its visibility.
                        $('[name="date_end"]', $modal)
                            .data('date-format', settings.dateFormat)
                            .val(brixxUtils.dateFormat(new Date(info.event.extendedProps.date_end), settings.dateFormat.toUpperCase()));
                        if (info.event.extendedProps.multi) {
                            $('[name="multi"]', $modal).prop('checked', true);
                            $('#popup-modal-event-date-end-wrapper', $modal).removeClass('hide');
                        }
                        else {
                            $('[name="multi"]', $modal).prop('checked', false);
                            $('#popup-modal-event-date-end-wrapper', $modal).addClass('hide');
                        }
                        // Set event title.
                        $('[name="title"]', $modal).val(info.event.title);
                        // Set event description.
                        $('[name="description"]', $modal).val(info.event.extendedProps.description);
                        // Set event ID.
                        $('[name="event_id"]', $modal).val(info.event.id);
                        // Set event type.
                        $('[name="event_type"]', $modal).val(info.event.extendedProps.type);
                        // Enable the submit button, in case the form was used earlier and its
                        // submit button disabled on submit.
                        $('[type="submit"]', $modal).prop('disabled', false);

                        // Attach event bindings.
                        Brixx.attachModules($modal);

                        // Show the dialog.
                        $modal.foundation('open');
                    },
                    dateClick: createEvent,
                    disableDragging: true,
                    eventStartEditable: false,
                    eventRender: info => {
                        // Add tooltips to events with description text.
                        if (typeof info.event.extendedProps.description !== 'undefined' && info.event.extendedProps.description) {
                            info.el.tooltip = new Foundation.Tooltip($(info.el), {
                                tipText: info.event.extendedProps.description
                            });
                        }
                    },
                    customButtons: {
                        addevent: {
                            text: Translator.translate('ui.calendar.add'),
                            click: createEvent
                        }
                    },
                    header: {
                        left: 'prev,title,next',
                        center: 'addevent',
                        right: 'listYear dayGridMonth'
                    },
                    buttonText: {
                        listYear: Translator.translate('ui.calendar.listLabel'),
                        dayGridMonth: Translator.translate('ui.calendar.gridLabel')
                    },
                    locale: settings.language,
                    timeZone: false
                });
                element.calendar.on('loading', function (info) {
                    let button = jQuery('.fc-addevent-button', element);
                    if (jQuery('i', button).length === 0) {
                        button.addClass(['button', 'button-plus', 'left']).css('border', 0);
                    }
                });
                element.calendar.render();
            });

            // End date visibility toggle.
            $('#popup-modal-calendar-event-content [name="multi"]', context).once('calendarEventToggleEnd').on('click.calendarEndToggle', event => {
                const $checkbox = $(event.currentTarget);
                const $wrapper = $checkbox.closest('#popup-modal-calendar-event-content');
                const $endDateWrapper = $('#popup-modal-event-date-end-wrapper', $wrapper);
                if ($checkbox.is(':checked')) {
                    $endDateWrapper.removeClass('hide');
                }
                else {
                    $endDateWrapper.addClass('hide');
                }
            });
        },
        detach: context => {
            // End date visibility toggle.
            $('#popup-modal-calendar-event-content [name="multi"]', context).findOnce('calendarEventToggleEnd').each((index, element) => {
                const $element = $(element);
                $element.off('click.calendarEndToggle').removeOnce('calendarEventToggleEnd');
            });
        }
    };

})(jQuery, Brixx, brixxUtils, Translator, Calendar, dayGridPlugin, listPlugin, interactionPlugin, Foundation, window);
