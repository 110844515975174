// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// Brixx object.
import Brixx from '../misc/brixx';
// Brixx utility functions.
import brixxUtils from '../misc/brixxUtils.js';
// Awesomplete.
import Awesomplete from 'awesomplete';
// Awesomplete util.
import AwesompleteUtil from 'awesomplete-util';
// UI AwesompleteUtil mods.
import '../components/ui-awesompletex';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   Brixx utility functions.
 */
(($, Brixx, brixxUtils, Awesomplete, AwesompleteUtil) => {

    // Whether the invoices module has already been initialized.
    if (typeof Brixx.modules.customers !== 'undefined') {
        return;
    }

    Brixx.customers = {
        customerSelectComplete: event => {
            // Get the awesomplete autocomplete control.
            const $element = $(event.currentTarget);
            // Get the callback URL to check for contact persons.
            const url = $element.data('customer-select');

            // Whether the control does not have a callback URL.
            if (!url) {
                // Bail out.
                return;
            }

            // Determine the dependent field names.
            const elementName = $element.attr('name');
            const fieldNameGroup = brixxUtils.getFieldNameGroup(elementName);

            const addressNameName = fieldNameGroup ? (fieldNameGroup + '[main_address_wrapper][mainAddress][name]') : 'main_address_wrapper[mainAddress][name]';
            const addressStreetName = fieldNameGroup ? (fieldNameGroup + '[main_address_wrapper][mainAddress][address]') : 'main_address_wrapper[mainAddress][address]';
            const addressZipName = fieldNameGroup ? (fieldNameGroup + '[main_address_wrapper][mainAddress][zip]') : 'main_address_wrapper[mainAddress][zip]';
            const addressCityName = fieldNameGroup ? (fieldNameGroup + '[main_address_wrapper][mainAddress][city]') : 'main_address_wrapper[mainAddress][city]';
            const addressCountryName = fieldNameGroup ? (fieldNameGroup + '[main_address_wrapper][mainAddress][country]') : 'main_address_wrapper[mainAddress][country]';

            const billingNameName = fieldNameGroup ? (fieldNameGroup + '[billing_address_wrapper][billingAddress][name]') : 'billing_address_wrapper[billingAddress][name]';
            const billingStreetName = fieldNameGroup ? (fieldNameGroup + '[billing_address_wrapper][billingAddress][address]') : 'billing_address_wrapper[billingAddress][address]';
            const billingZipName = fieldNameGroup ? (fieldNameGroup + '[billing_address_wrapper][billingAddress][zip]') : 'billing_address_wrapper[billingAddress][zip]';
            const billingCityName = fieldNameGroup ? (fieldNameGroup + '[billing_address_wrapper][billingAddress][city]') : 'billing_address_wrapper[billingAddress][city]';
            const billingCountryName = fieldNameGroup ? (fieldNameGroup + '[billing_address_wrapper][billingAddress][country]') : 'billing_address_wrapper[billingAddress][country]';

            const contactFieldName = fieldNameGroup ? (fieldNameGroup + '[name]') : 'name';
            const contactMailFieldName = fieldNameGroup ? (fieldNameGroup + '[email]') : 'email';
            const contactPhoneFieldName = fieldNameGroup ? (fieldNameGroup + '[phone]') : 'phone';

            // Get the dependent form elements.
            const $form = $element.closest('form');

            const $addressNameField = $('[name="' + addressNameName + '"]', $form);
            const $addressStreetField = $('[name="' + addressStreetName + '"]', $form);
            const $addressZipField = $('[name="' + addressZipName + '"]', $form);
            const $addressCityField = $('[name="' + addressCityName + '"]', $form);
            const $addressCountryField = $('[name="' + addressCountryName + '"]', $form);

            const $billingNameField = $('[name="' + billingNameName + '"]', $form);
            const $billingStreetField = $('[name="' + billingStreetName + '"]', $form);
            const $billingZipField = $('[name="' + billingZipName + '"]', $form);
            const $billingCityField = $('[name="' + billingCityName + '"]', $form);
            const $billingCountryField = $('[name="' + billingCountryName + '"]', $form);

            const $contactField = $('[name="' + contactFieldName + '"]', $form);
            const $contactMailField = $('[name="' + contactMailFieldName + '"]', $form);
            const $contactPhoneField = $('[name="' + contactPhoneFieldName + '"]', $form);

            // Whether no contact person field was found.
            if ($contactField.length === 0) {
                // Bail out.
                return;
            }

            // Remove any existing Awesomplete bindings and clear the items list.
            if (typeof $contactField.awcx !== 'undefined') {
                $contactField.awcx.destroy();
                delete $contactField['awcx'];
            }
            $contactField.list = [];

            // Conduct an AJAX call to fetch contact persons of the chosen
            // customer.
            const value = $element.val();
            $.ajax({
                url: brixxUtils.addUrlParameter(url, 's', value)
            })
                .done(responseData => {
                    if (!(responseData && Object.prototype.hasOwnProperty.call(responseData, 'resultsCount'))) {
                        return;
                    }

                    if (Object.prototype.hasOwnProperty.call(responseData, 'address')) {
                        $addressNameField.val(responseData.address.name);
                        $addressStreetField.val(responseData.address.address);
                        $addressZipField.val(responseData.address.zip);
                        $addressCityField.val(responseData.address.city);
                        $addressCountryField.val(responseData.address.country);
                    }
                    else {
                        $addressNameField.val('');
                        $addressStreetField.val('');
                        $addressZipField.val('');
                        $addressCityField.val('');
                        $addressCountryField.val('CH');
                    }

                    if (Object.prototype.hasOwnProperty.call(responseData, 'billing')) {
                        $billingNameField.val(responseData.billing.name);
                        $billingStreetField.val(responseData.billing.address);
                        $billingZipField.val(responseData.billing.zip);
                        $billingCityField.val(responseData.billing.city);
                        $billingCountryField.val(responseData.billing.country);
                    }
                    else {
                        $billingNameField.val('');
                        $billingStreetField.val('');
                        $billingZipField.val('');
                        $billingCityField.val('');
                        $billingCountryField.val('CH');
                    }

                    // Whether we have existing contact persons.
                    if (responseData.resultsCount && Object.prototype.hasOwnProperty.call(responseData, 'data')) {
                        let items = [];
                        Object.keys(responseData.data).forEach(function (key) {
                            const person = responseData.data[key];
                            person.label = person.name;
                            person.value = person.name;
                            items.push(person);
                        });
                        $contactField.list = items;

                        const $label = $('label[for="' + $contactField.attr('id') + '"]') || $contactField.next('label');

                        AwesompleteUtil.startCopy('#' + $contactField.attr('id'), 'phone', '#' + $contactPhoneField.attr('id'));
                        AwesompleteUtil.startCopy('#' + $contactField.attr('id'), 'email', '#' + $contactMailField.attr('id'));
                        $contactField.awcx = AwesompleteUtil.start('#' + $contactField.attr('id'), {
                            prepop: true
                        }, {
                            list: $contactField.list,
                            minChars: $contactField.data('minchars') || 2,
                            maxItems: $contactField.data('maxitems') || 20,
                            filter: AwesompleteUtil.filterContains
                        });

                        // Move the label back after the input field.
                        if ($label.length > 0) {
                            $label.detach().insertAfter($contactField);
                        }
                    }
                });
        }
    };

    /**
     * The customers module of the BRIXX App.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.customers = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: (context) => {
            $('input.awesompletex.awe-customer-contact', context).once('awesompletex').each((index, element) => {
                const $contactField = $(element);
                const elementName = $contactField.attr('name');
                const fieldNameGroup = brixxUtils.getFieldNameGroup(elementName);
                const contactMailFieldName = fieldNameGroup ? (fieldNameGroup + '[email]') : 'email';
                const contactPhoneFieldName = fieldNameGroup ? (fieldNameGroup + '[phone]') : 'phone';

                // Get the dependent form elements.
                const $form = $contactField.closest('form');
                const $contactMailField = $('[name="' + contactMailFieldName + '"]', $form);
                const $contactPhoneField = $('[name="' + contactPhoneFieldName + '"]', $form);

                $contactField.list = $contactField.data('xlist') || [];

                if (typeof $contactField.list === 'string') {
                    let settingsList = Brixx.getSetting($contactField.list, null);
                    if (settingsList) {
                        $contactField.list = settingsList.map(listItem => brixxUtils.isObject(listItem) ? {...listItem} : listItem);
                    }
                }

                if ($contactField.list) {
                    const $label = $('label[for="' + $contactField.attr('id') + '"]') || $contactField.next('label');

                    AwesompleteUtil.startCopy('#' + $contactField.attr('id'), 'phone', '#' + $contactPhoneField.attr('id'));
                    AwesompleteUtil.startCopy('#' + $contactField.attr('id'), 'email', '#' + $contactMailField.attr('id'));
                    $contactField.awcx = AwesompleteUtil.start('#' + $contactField.attr('id'), {
                        prepop: false
                    }, {
                        list: $contactField.list,
                        minChars: $contactField.data('minchars') || 2,
                        maxItems: $contactField.data('maxitems') || 20,
                        filter: AwesompleteUtil.filterContains
                    });

                    // Move the label back after the input field.
                    if ($label.length > 0) {
                        $label.detach().insertAfter($contactField);
                    }
                }
            });
        }

    };

})(jQuery, Brixx, brixxUtils, Awesomplete, AwesompleteUtil);
