// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Translator.
import Translator from '../misc/translator';
// Noty.
import Noty from '../lib/noty';

/**
 * Provides functions to show notification messages to the user.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {Translator} Translator
 *   BRIXX translator.
 * @param {Noty} Noty
 *   Noty screen notes.
 */
(($, Brixx, Translator, Noty) => {

    // Whether the UI messages module has already been initialized.
    if (typeof Brixx.modules.uiMessages !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * Shows a notification message to the user.
     *
     * This method uses Brixx.settings.notyConfig as base configuration
     * for showing a Noty message. It is a short version that requires
     * only a message and optionally the message type.
     *
     * @param {string} text
     *   The text to show. This text supports UI translation strings.
     * @param {object.<string, string>} [args]
     *   An object of replacements pairs to make after translation. Incidences
     *   of any key in this object are replaced with the corresponding value.
     * @param {string} [type]
     *   (Optional) Message type. Allowed values are 'alert', 'success',
     *   'error', 'warning' and 'info'.
     *   Defaults to 'info'.
     */
    Brixx.showMessage = (text, args, type) => {
        new Noty({
            ...Brixx.settings.notyConfig,
            ...{
                type: type || 'info',
                text: Translator.translate(text, args || {})
            }
        }).show();
    };

    /**
     * Shows a success message to the user.
     *
     * @param {string} text
     *   The text to show. This text supports UI translation strings.
     * @param {object.<string, string>} [args]
     *   An object of replacements pairs to make after translation. Incidences
     *   of any key in this object are replaced with the corresponding value.
     */
    Brixx.showSuccess = (text, args) => {
        Brixx.showMessage(text, args || {}, 'success');
    };

    /**
     * Shows an error message to the user.
     *
     * @param {string} text
     *   The text to show. This text supports UI translation strings.
     * @param {object.<string, string>} [args]
     *   An object of replacements pairs to make after translation. Incidences
     *   of any key in this object are replaced with the corresponding value.
     */
    Brixx.showError = (text, args) => {
        Brixx.showMessage(text, args || {}, 'error');
    };

    /**
     * Shows an info message to the user.
     *
     * @param {string} text
     *   The text to show. This text supports UI translation strings.
     * @param {object.<string, string>} [args]
     *   An object of replacements pairs to make after translation. Incidences
     *   of any key in this object are replaced with the corresponding value.
     */
    Brixx.showInfo = (text, args) => {
        Brixx.showMessage(text, args || {}, 'info');
    };

    /**
     * Shows a warning message to the user.
     *
     * @param {string} text
     *   The text to show. This text supports UI translation strings.
     * @param {object.<string, string>} [args]
     *   An object of replacements pairs to make after translation. Incidences
     *   of any key in this object are replaced with the corresponding value.
     */
    Brixx.showWarning = (text, args) => {
        Brixx.showMessage(text, args || {}, 'warning');
    };

    /**
     * BRIXX UI messages module.
     *
     * Parses header messages JSON to display UI messages after the page
     * has been loaded.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiMessages = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            $('body', context).once('ui-messages').each(() => {
                $('head script[data-brixx-selector="brixx-messages-json"]').each((index, element) => {
                    const messages = JSON.parse($(element).html());
                    if (!messages) {
                        return;
                    }
                    messages.forEach((message, i) => {
                        // Use a little timeout between multiple messages to allow
                        // the user to read 'em all.
                        setTimeout(() => {
                            Brixx.showMessage(message.message || '', message.args || {}, message.type || 'info');
                        }, i * 1000);
                    });
                });
            });
        }

    };

})(jQuery, Brixx, Translator, Noty);
