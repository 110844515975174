// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// UI listings.
import '../components/ui-listings';
// Foundation framework.
import '../components/foundation';

/**
 * Contains all scripts specific to BrixxUser related views.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    /**
     * BRIXX module for BrixxUser related views.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.users = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {

            $('#sortableCatalog', context).once('clients-pref-sortable-catalog').each((index, catalogList) => {
                const $catalogList = $(catalogList);
                $catalogList.sortable({
                    handle: '.handlecatalog',
                    opacity: 0.7,
                    update: () => {
                        let order = 1;
                        $('li input[name="sortorder[]"]', $catalogList).each((index, orderField) => {
                            $(orderField).val(order++);
                        });
                    }
                }).disableSelection();
            });

            // Submit handler.
            $('.form-memberprefs-data').once('clients-pref-submit').on('submit', event => {
                event.preventDefault();
                Brixx.forms.ajaxSubmitReplace(event.currentTarget).done(data => {
                    if (data.includes('<input type="hidden" name="action" value="success">')) {
                        setTimeout(() => {
                            $('#panel').foundation('toggle');
                        }, 1000);
                    }
                });
            });

        }
    };

})(jQuery, Brixx);
