// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    // Whether the UI tabs search module has already been initialized.
    if (typeof Brixx.modules.uiTabsSearch !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for filtering the main navigation UI tabs.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiTabsSearch = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {

            /**
             * The UI tabs.
             *
             * @type {jQuery}
             */
            const $uiTabs = $('#tabslevel1 .li-level-one');

            /**
             * Callback to filter the UI tabs based on `searchTerm`.
             *
             * @param {string} searchTerm
             *   The search term to find within the text of the
             *   UI tabs.
             */
            const filterUiTabs = searchTerm => {
                $uiTabs.filter((index, tab) => {
                    const $tab = $(tab);
                    $tab.toggle($tab.text().toLowerCase().indexOf(searchTerm) > -1);
                });
            };

            $('#itemInput', context).once('ui-tabs-search').on('keyup awesomplete-select', event => {
                const $searchBox = $(event.currentTarget);
                const value = $searchBox.val().toLowerCase();
                filterUiTabs(value);
            });

        }

    };

})(jQuery, Brixx);
