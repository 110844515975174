// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// Foundation framework.
import './foundation';
// Brixx object.
import Brixx from '../misc/brixx';
// BRIXX UI ajax handlers.
import '../components/ui-ajax';
// Translator.
import Translator from '../misc/translator';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {Translator} Translator
 *   BRIXX translator.
 * @param {object} window
 *   The global window object.
 */
(($, Brixx, Translator, window) => {

    // Whether the UI navigation module has already been initialized.
    if (typeof Brixx.modules.uiNavigation !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX UI navigation module.
     *
     * Provides customizations for the navigation/menu of the BRIXX UI.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiNavigation = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {
            $('.condensed-switch', context).once('ui-condensed').on('click', event => {
                const $link = $(event.currentTarget);
                const mode = $link.data('list-mode');

                $('.condensed-switch').removeClass('active');
                $link.addClass('active');

                if (mode === 'condensed') {
                    $('#dd-form').addClass('condensed-nav');
                }
                else {
                    $('#dd-form').removeClass('condensed-nav');
                }
            });

            $('.tabs-title [data-tabs-target]', context).once('ui-tabs').on('click', event => {
                event.preventDefault();
                const $link = $(event.currentTarget);
                $link.closest('.tabs').foundation('selectTab', $link.data('tabs-target'), true);

                const state = {};
                let pageTitle = '';
                if ($link.data('title')) {
                    pageTitle = 'BRIXX | ' + $link.data('title');
                    state.pageTitle = pageTitle;
                }

                if ($link.data('update-location')) {
                    window.history.pushState(state, pageTitle, $link.attr('href'));
                }

                if (pageTitle) {
                    window.document.title = pageTitle;
                }

                const urls = $link.data('urls');
                if (urls) {
                    for (let locale in urls) {
                        if (Object.prototype.hasOwnProperty.call(urls, locale)) {
                            $('a.lang-switch[data-locale="' + locale + '"]').attr('href', urls[locale]);
                        }
                    }
                }

                const $activeTabsContentTab = $('#' + $link.data('tabs-target') + ' .tabs .tabs-title.is-active');
                if ($activeTabsContentTab.length > 0) {
                    const $subLink = $activeTabsContentTab.find('a');
                    if ($subLink.length === 0) {
                        return;
                    }

                    const $panel = $subLink.data('tabs-target') ? $('#' + $subLink.data('tabs-target')) : $($subLink.attr('href'));
                    if ($panel.length === 0) {
                        return;
                    }

                    let url;

                    if ($subLink.data('tabs-target')) {
                        url = $subLink.attr('href');
                    }
                    else if ($panel.length > 0) {
                        url = $panel.data('url');
                    }

                    if (!url) {
                        return;
                    }

                    const $wrapper = $('.ui-content', $panel);

                    $.ajax({
                        timeout: settings.ajaxTimeout,
                        retries: settings.ajaxRetries,
                        retriesInterval: settings.ajaxRetriesInterval,
                        url: url
                    })
                        .done(responseData => {
                            if (!Brixx.uiAjaxResponseHandler(responseData, $wrapper)) {
                                Brixx.detachModules($panel);
                                $wrapper.html(responseData);
                                Brixx.attachModules($panel);
                            }
                        })
                        .fail((responseData, statusText) => {
                            Brixx.uiAjaxResponseHandler(responseData, $wrapper, statusText);
                        });
                }
            });

            // Nav toggler.
            $('.nav-toggler', context).once('nav-toggler').on('click', (event) => {
                const $toggler = $(event.currentTarget);
                const $navigation = $('#project-nav');
                const $container = $navigation.closest('.brixx-ui');

                if ($container.hasClass('nav-collapsed')) {
                    $container.removeClass('nav-collapsed');
                    $toggler.attr('aria-expanded', 'true');
                }
                else {
                    $container.addClass('nav-collapsed');
                    $toggler.attr('aria-expanded', 'false');
                }
            });

            // Active state of off canvas menu button and
            // closing of other off-canvas elements on opening.
            $('.off-canvas', context).once('off-canvas').each((index, element) => {
                const $element = $(element);
                const id = $element.attr('id');
                const $button = $('[data-target="' + id + '"]');
                $element.on('opened.zf.offCanvas', () => {
                    $('.off-canvas.is-open').each((index, el) => {
                        const $el = $(el);
                        if ($el.attr('id') !== id) {
                            $el.foundation('close');
                        }
                    });
                    if ($button.length > 0) {
                        $button.addClass('is-active');
                    }
                }).on('closed.zf.offCanvas', () => {
                    if ($button.length > 0) {
                        $button.removeClass('is-active');
                    }
                });
            });

            // Close off-canvas menu on large devices.
            $('body', context).once('off-canvas').each(() => {
                $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
                    if (newSize === 'large' || newSize === 'xlarge' || newSize === 'xxlarge') {
                        $('.off-canvas').foundation('close');
                    }
                    if (newSize === 'small' || newSize === 'xsmall' || newSize === 'medium' || newSize === 'xmedium') {
                        // $('#offCanvas').foundation('close');
                    }
                });
            });

            // Main navigation loader(s).
            $('.ui-nav-tabs.ui-nav-tabs-lazy', context)
                .once('main-nav-ajax-loader')
                .on('down.zf.accordion', (event, $panel) => {
                    const thisUrl = $panel ? $panel.attr('data-url') : null;

                    if (thisUrl && $panel && !$panel.is('[data-lazy-loaded="true"]')) {
                        const $wrapper = $('.ui-content', $panel);

                        $.ajax({
                            timeout: settings.ajaxTimeout,
                            retries: settings.ajaxRetries,
                            retriesInterval: settings.ajaxRetriesInterval,
                            url: thisUrl
                        })
                            .done(responseData => {
                                if (!Brixx.uiAjaxResponseHandler(responseData, $wrapper)) {
                                    Brixx.detachModules($panel);
                                    $wrapper.html(responseData);
                                    Brixx.attachModules($panel);
                                }
                                if ($panel.is('[data-lazy-loaded]')) {
                                    $panel.attr('data-lazy-loaded', 'true');
                                }
                            })
                            .fail((responseData, statusText) => {
                                Brixx.uiAjaxResponseHandler(responseData, $wrapper, statusText);
                            });
                    }
                })
                .on('change.zf.tabs', (event, $tab) => {
                    if (!$tab) {
                        return;
                    }

                    const $link = $tab.find('a');
                    if ($link.length === 0) {
                        return;
                    }

                    const $panel = $link.data('tabs-target') ? $('#' + $link.data('tabs-target')) : $($link.attr('href'));
                    if ($panel.length === 0) {
                        return;
                    }

                    let url;
                    if ($link.data('tabs-target')) {
                        url = $link.attr('href');
                    }
                    else if (($panel.length > 0)) {
                        url = $panel.data('url');
                    }

                    if (!url || ($panel && $panel.is('[data-lazy-loaded="true"]'))) {
                        return;
                    }

                    const $wrapper = $('.ui-content', $panel);

                    $.ajax({
                        timeout: settings.ajaxTimeout,
                        retries: settings.ajaxRetries,
                        retriesInterval: settings.ajaxRetriesInterval,
                        url: url
                    })
                        .done(responseData => {
                            if (!Brixx.uiAjaxResponseHandler(responseData, $wrapper)) {
                                Brixx.detachModules($panel);
                                $wrapper.html(responseData);
                                Brixx.attachModules($panel);
                            }
                            if ($panel.is('[data-lazy-loaded]')) {
                                $panel.attr('data-lazy-loaded', 'true');
                            }
                        })
                        .fail((responseData, statusText) => {
                            Brixx.uiAjaxResponseHandler(responseData, $wrapper, statusText);
                        });
                });

            // Add margin to '.listing-nav-header', if '.listing-nav-content' has scrollbars.
            $('body', context).once('listing-nav-margin').each(() => {
                $(window)
                    .on('resize.listing-nav-margin', function () {
                        const $navListingHeaderItem = $('.listing-nav-header .ui-nav-item');
                        const $navListingContent = $('.listing-nav-content');
                        const $navListingContentItem = $('.brixx-nav .ui-item', $navListingContent).first();

                        let margin = '';
                        if (window.Foundation.MediaQuery.is('large up') && $navListingContentItem.length) {
                            margin = $navListingContent.outerWidth() - $navListingContentItem.innerWidth();
                            margin += 'px';
                        }

                        $navListingHeaderItem.css('margin-right', margin);
                    })
                    .trigger('resize');
            });

        }

    };

})(jQuery, Brixx, Translator, window);
