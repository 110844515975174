// jQuery.
import jQuery from 'jquery';
// BRIXX core.
import Brixx from '../misc/brixx';
// BRIXX utility functions.
import brixxUtils from '../misc/brixxUtils';
// BRIXX notification messages.
import '../components/ui-messages';

/**
 * Wraps the jQuery .ajax() function in a version with automatic retries.
 *
 * The retries can be controlled by the optional Ajax settings:
 * - retries {number} Number of retries before failing the
 *   Ajax call. Defaults to 3.
 * - retriesInterval {number} Pause between retries in
 *   milliseconds. Defaults to 1000.
 * - retryOmitStatus {Array<String>} Array of Ajax status strings
 *   that will NOT trigger a retry. Defaults to `[
 *     'success',
 *     'parsererror',
 *     'error',
 *     'notmodified',
 *     'abort'
 *   ]`
 *
 * Pro tip: Use the timeout setting to force retries after
 * a certain amount of milliseconds.
 *
 * @param {jQuery} $
 *   The jQuery object.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   The BRIXX utility functions.
 */
(($, Brixx, brixxUtils) => {

    // Don't run the wrapping twice.
    if (typeof $.hasModifiedAjax !== 'undefined') {
        return;
    }

    // Wrap the original jQuery.ajax() method in our custom
    // version.
    $.ajax = ($oldAjax => {

        /**
         * Custom Ajax function.
         *
         * @param {object} [settings]
         *   The original Ajax settings.
         * @param {Deferred} [deferred]
         *   The deferred object returned by our wrapper. This
         *   object will be passed on to repeated Ajax calls, so
         *   we can resolve/reject it according to the final
         *   Ajax callback results.
         * @param {Array} [jqXHRs]
         *   Internally used array of Ajax jqXHRs triggered by
         *   the originating request.
         *
         * @return {Deferred}
         *   A jQuery Deferred object providing the ability to use
         *   .then(), .done() and .fail() to act upon on the Ajax
         *   results.
         */
        return (settings, deferred, jqXHRs) => {
            // Wrapping deferred object. This is used to allow
            // following scripts to act on Ajax results.
            deferred = deferred || $.Deferred();

            // Keep track of related jqXHR objects.
            jqXHRs = jqXHRs || [];

            // In case of a retry, this variable will hold
            // the related timeout reference.
            let scheduled;

            // Add an `isAjax` parameter to the URL to avoid browser caching
            // of JSON returned by an AJAX callback.
            if (typeof settings.url !== 'undefined') {
                settings.url = brixxUtils.addUrlParameter(settings.url, 'isAjax', 1);
            }
            // Additionally prevent AJAX results from being cached.
            settings.cache = false;

            // Do an Ajax call using the generic jQuery.ajax()
            // method and do our magic in its .always() callback.
            jqXHRs.push($oldAjax(settings).always((data, statusText, additional) => {
                if (typeof settings.retries === 'undefined') {
                    settings.retries = Brixx.settings.ajaxRetries;
                }
                if (typeof settings.retriesInterval === 'undefined') {
                    settings.retriesInterval = Brixx.settings.ajaxRetriesInterval;
                }
                if (typeof settings.retryOmitStatus === 'undefined') {
                    settings.retryOmitStatus = Brixx.settings.ajaxRetryOmitStatus;
                }

                // Whether the max. number of retries has not been reached.
                let shouldRetry = settings.retries-- > 0;

                // Whether the status text matches a retry reason.
                if (shouldRetry) {
                    settings.retryOmitStatus.forEach(status => {
                        shouldRetry = shouldRetry && statusText !== status;
                    });
                }

                // If we arrive here, cancel previously scheduled
                // requests.
                if (scheduled) {
                    scheduled.cancel();
                }

                // Schedule the actual retry.
                if (shouldRetry) {
                    scheduled = setTimeout(() => {
                        Brixx.showWarning('ui.error.408.repeat-msg');
                        // Remove the timeout from the last retry if the
                        // app is running in debug mode. This may reveal
                        // server errors hidden within the server reply.
                        if (
                            typeof Brixx.settings.brixx.isDebugMode !== 'undefined'
                            && Brixx.settings.brixx.isDebugMode
                            && typeof settings.timeout !== 'undefined'
                            && settings.timeout > 0
                            && settings.retries <= 0
                        ) {
                            settings.timeout = 0;
                        }
                        $.ajax(settings, deferred, jqXHRs);
                    }, settings.retriesInterval);
                    return;
                }

                // Whether the request was successful.
                if (statusText === 'success') {
                    // Resolve the deferred object.
                    deferred.resolve(data, statusText, additional);
                }
                // Otherwise.
                else {
                    // Reject the deferred object.
                    deferred.reject(data, statusText, additional);
                }
            }));

            // Mimic the jqXHR.abort() method. This is required for
            // the jQuery DataTables plugin.
            const promise = deferred.promise();
            promise.abort = text => {
                jqXHRs.forEach(jqXHR => {
                    jqXHR.abort(text);
                });
            };

            // Return the wrapping deferred objects' promise.
            return promise;
        };
    })($.ajax);

    $.hasModifiedAjax = true;

})(jQuery, Brixx, brixxUtils);
