// Handlebars templating engine.
import Handlebars from 'handlebars';

Handlebars.registerHelper({
    eq: (v1, v2) => v1 === v2,
    ne: (v1, v2) => v1 !== v2,
    lt: (v1, v2) => v1 < v2,
    gt: (v1, v2) => v1 > v2,
    lte: (v1, v2) => v1 <= v2,
    gte: (v1, v2) => v1 >= v2,
    and() {
        return Array.prototype.slice.call(arguments, 0, arguments.length - 1).every(Boolean);
    },
    or() {
        return Array.prototype.slice.call(arguments, 0, arguments.length - 1).some(Boolean);
    },
    toJson: (object, options) => new Handlebars.SafeString(options.hash.isAttr ? Theme.plainText(JSON.stringify(object)) : JSON.stringify(object))
});

/**
 * Theming functions for the BRIXX App.
 */
const Theme = {

    /**
     * Holds all handlebars templates found in the current page.
     */
    templates: {},

    /**
     * Adds a template to the theme templates.
     *
     * @param {string} key
     *   The template key.
     * @param {string} template
     *   The template Handlebars markup string.
     */
    addTemplate: (key, template) => {
        Theme.templates[key] = Handlebars.compile(template);
    },

    /**
     * Checks whether a template with the given key exists.
     *
     * @param {string} key
     *   The template key.
     *
     * @return {boolean}
     *   `true`, if the key exists, `false` otherwise.
     */
    templateExists: key => Object.prototype.hasOwnProperty.call(Theme.templates, key),

    /**
     * Generates the themed representation of a BRIXX UI object.
     *
     * All requests for themed output must go through this function. It examines
     * the request and routes it to the appropriate theme function. If the current
     * theme does not provide an override function, the generic theme function is
     * called.
     *
     * <caption>To retrieve the HTML for text that should be emphasized and
     * displayed as a placeholder inside a sentence.</caption>
     * ```javascript
     * Theme.theme('placeholder', text);
     * ```
     *
     * @param {function} func
     *   The name of the theme function to call.
     * @param {...args} args
     *   Additional arguments to pass along to the theme function.
     *
     * @return {string|Object|HTMLElement|jQuery}
     *   Any data the theme function returns. This could be a plain HTML string,
     *   but also a complex object.
     *
     * @memberof Theme
     * @method theme
     */
    theme: (func, ...args) => {
        if (func in Theme) {
            return Theme[func](...args);
        }
    },

    /**
     * Encodes special characters in a plain-text string for display as HTML.
     *
     * @param {string} str
     *   The string to be encoded.
     *
     * @return {string}
     *   The encoded string.
     *
     * @memberof Theme
     * @method plainText
     */
    plainText: str => str
        .toString()
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;'),

    /**
     * Formats text for emphasized display in a placeholder inside a sentence.
     *
     * @param {string} str
     *   The text to format (plain-text).
     *
     * @return {string}
     *   The formatted text (html).
     *
     * @memberof Theme
     * @method placeholder
     */
    placeholder: str => `<em class="placeholder">${Theme.plainText(str)}</em>`

};

export default Theme;
