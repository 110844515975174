// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Utility functions.
import brixxUtils from '../misc/brixxUtils';
// Ensure the UI forms module is initialized.
import '../components/ui-forms';
// Awesomplete.
import 'awesomplete';
// Awesomplete util.
import AwesompleteUtil from 'awesomplete-util';

/**
 * BRIXX UI component for AwesompleteX.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utilities.
 * @param {AwesompleteUtil} AwesompleteUtil
 *   Awesomplete utilities.
 */
(($, Brixx, brixxUtils, AwesompleteUtil) => {

    // Whether the uiAwesompleteX component has already been initialized.
    if (typeof Brixx.modules.uiAwesompleteX !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * Event callback to copy a value from the Awesomplete list to another DOM element.
     *
     * Rather than the generic copy function of the Awesomplete Utils, this callback
     * will NOT change the target value, if no match has been found.
     *
     * @param {(string|jQuery)} sourceId
     *   The jQuery selector or object of the Awesomplete source field.
     * @param {(string|null)} dataField
     *   The data field key, if the Awesomplete list contains objects, or
     *   `null`, if the list has string values and the value shall be
     *   copied.
     * @param {(string|jQuery|function)} targetId
     *   The jQuery selector or object of the target field, or a callback function
     *   to set the target element value.
     * @param {boolean} [keepValues=false]
     *   (Optional) Whether to NOT overwrite target values, if there is
     *   no match. Defaukts to `false`, which is the Awesomplete Utils
     *   default behavior.
     * @param {Event} event
     *   The triggering awesomplete event.
     */
    function awesompleteCopyFunction(sourceId, dataField, targetId, keepValues, event) {
        let $sourceElement = $(sourceId);
        let $lockElement = $sourceElement.closest('.form-element').find('.lock');
        let $targetElement;
        let val;
        let lock = false;

        if (!$(event.target).is($sourceElement)) {
            return;
        }

        if (typeof targetId === 'function') {
            targetId(event, dataField);
            return;
        }

        // Lookup target element, if it isn't resolved yet.
        $targetElement = $(targetId);
        // Skip processing the target element, if the user is currently editing it.
        if ($targetElement && $targetElement !== document.activeElement) {
            // Event must contain 1 item from suggestion list.
            val = Array.isArray(event.detail) && event.detail.length === 1 ? event.detail[0] : null;

            // If a datafield is specified, take that value.
            if (dataField && val) {
                val = val[dataField];
            }

            // Whether the value is `null`.
            if (val === null) {
                // Whether to NOT overwrite target values on no match.
                if (keepValues) {
                    if ($sourceElement.hasClass('add-locks')) {
                        $lockElement.removeClass('locked');
                    }

                    // Bail out.
                    return;
                }
                // Sanitize the value to an empty string.
                else {
                    val = '';
                }
            }
            else {
                lock = true;
            }

            // Remove any not found/loading CSS classes.
            $targetElement.removeClass(AwesompleteUtil._CLS_NOT_FOUND).removeClass(AwesompleteUtil._CLS_LOADING);

            // Alter lock.
            if ($sourceElement.hasClass('add-locks')) {
                if (lock) {
                    $lockElement.addClass('locked');
                }
                else {
                    $lockElement.removeClass('locked');
                }
            }

            // With keepValues on prepop, we don't want to alter existing
            // values but set the lock classes only.
            if (event.type === 'awesomplete-prepop' && keepValues) {
                $sourceElement.addClass('awe-found');
                return;
            }

            Brixx.forms.setValue($targetElement, val);
            $targetElement.closest('form').trigger('calculate');
        }
    }

    /**
     * Creates an event listener to copy an Awesomplete list value to another DOM element.
     *
     * @param {string} sourceId
     *   The jQuery selector of the Awesomplete field.
     * @param {(string|null)} dataField
     *   The data field key, if the Awesomplete list contains objects, or
     *   `null`, if the list has string values and the value shall be
     *   copied.
     * @param {string} targetId
     *   The jQuery selector of the target field.
     * @param {boolean} [keepValues=false]
     *   (Optional) Whether to NOT overwrite target values, if there is
     *   no match. Defaukts to `false`, which is the Awesomplete Utils
     *   default behavior.
     *
     * @return {function}
     *   A copy of the awesompleteCopyFunction function with the specified
     *   `this` value, and initial arguments.
     */
    AwesompleteUtil.createCopyFun = function (sourceId, dataField, targetId, keepValues) {
        if (typeof keepValues === 'undefined') {
            keepValues = false;
        }
        return awesompleteCopyFunction.bind(null, $(sourceId) || sourceId, dataField, $(targetId) || targetId, keepValues);
    };

    /**
     * Override for convert input helper without trimming input text.
     *
     * This allows to enter values with a trailing space to unmatch
     * entries.
     *
     * @param {*} text
     *   The input value.
     *
     * @return {string}
     *   The converted input.
     *
     * @see BRIXX-583
     */
    AwesompleteUtil.convertInput = function (text) {
        return typeof text === 'string' ? text.toLowerCase() : '';
    };

    /**
     * BRIXX UI component for AwesompleteX.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiAwesompleteX = {

        /**
         * Detach module callback.
         *
         * Removes the AwesompleteX bindings created with 'awesompletex' once.
         *
         * @type {Brixx~modulesDetach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to detach from.
         */
        detach: context => {
            // Detach Awesomplete.
            $('input.awesompletex, textarea.awesompletex', context).findOnce('awesompletex').each((elementIndex, element) => {
                const $element = $(element);

                // Remove any existing Awesomplete bindings and clear the items list.
                if (typeof $element.awcx !== 'undefined') {
                    $element.awcx.destroy();
                    delete $element['awcx'];
                }
                $element.list = [];

                // Whether to remove lock icon.
                if ($element.hasClass('add-locks')) {
                    const $lock = $element.closest('.form-element').find('.lock');
                    if ($lock.length !== 0) {
                        $lock.remove();
                    }
                }

                // Remove any possibly remaining awesomplete wrapper elements.
                const $awesompleteWrapper = $element.closest('div.awesomplete');
                if ($awesompleteWrapper.length > 0) {
                    $awesompleteWrapper.children().not('ul[role="listbox"], span[role="status"]').detach().insertBefore($awesompleteWrapper);
                    $awesompleteWrapper.remove();
                }

                // Disable all awesomplete event listeners.
                $element
                    .off('awesomplete-close')
                    .off('awesomplete-loadcomplete')
                    .off('awesomplete-match')
                    .off('awesomplete-prepop')
                    .off('awesomplete-select')
                    .off('awesomplete-selectcomplete');
                // Remove once.
                $element
                    .removeOnce('awesompletex');
            });
        }

    };

})(jQuery, Brixx, brixxUtils, AwesompleteUtil);
