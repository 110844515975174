import jQuery from 'jquery';

if (typeof window.jQuery === 'undefined') {
    window.jQuery = jQuery;
}
if (typeof window.$ === 'undefined') {
    window.$ = jQuery;
}
import './misc/jquery.ajax';

// Add days to date.
// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

import 'what-input';
import './lib/event-submitter-polyfill';
import 'jquery-ui-bundle';
import 'jquery-once';
require('jquery-ui-touch-punch');
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

// BRIXX UI core.
import Brixx from './misc/brixx';
import './misc/translator';
import './misc/brixxUtils';

// BRIXX UI components.
import './components/foundation';
import './components/ui-templates';
import './components/ui-general';
import './components/ui-navigation';
import './components/ui-messages';
import './components/ui-tabs-search';
import './components/ui-tabs-sort';
import './components/ui-lists-search';
import './components/ui-ajax';
import './components/ui-forms';
import './components/ui-calendar';
import './components/ui-listings';
import './components/ui-scroll';
import './components/ui-gallery';
// import './components/ui-slick';
import './components/ui-notifications';
import './components/ui-deleteconfirm';

// BRIXX UI modules.
import './modules/users';
import './modules/customers';
import './modules/employees';
import './modules/modules';
import './modules/print-settings';
import './modules/module-material-item';
import './modules/module-expense-item';
import './modules/module-calc-devis';
import './modules/module-calc-regie';
import './modules/module-calc-simple';
import './modules/module-payables';
import './modules/module-upload';
import './modules/planner';
import './modules/invoices';
import './modules/expenses-reports';
import './modules/offers';
import './modules/time-reports-project';
import './modules/time-reports-employee';
import 'select2';
import './modules/module-order';

// Initialize all component/module handlers.
(($, Brixx) => {

    $(document).ready(() => {
        Brixx.attachModules(document, Brixx.settings);

        $('.select2').select2();
        $(document).ajaxComplete(function () {
            $('.select2').select2();
        });
    });

})(jQuery, Brixx);
