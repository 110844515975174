// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// Brixx object.
import Brixx from '../misc/brixx';
// Translator.
import Translator from '../misc/translator';
// Foundation framework.
import '../components/foundation';

/**
 * Contains all scripts specific to the deleteconfirm modal.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {Translator} Translator
 *   BRIXX translator.
 */
(($, Brixx, Translator) => {

    // Whether the deleteconfirm module has already been initialized.
    if (typeof Brixx.modules.uiDeleteconfirm !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for the deleteconfirm modal.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiDeleteconfirm = {

        /**
         * Removes the delete confirm click handler and closes the modal.
         */
        closeModal: () => {
            // Reset event handlers, URL and 'use-ajax' CSS class.
            $('#deleteconfirm-link')
                .off('click.uiDeleteconfirm')
                .attr('href', '#');
            // Close the modal dialog.
            $('#popup-modal-deleteconfirm').foundation('close');
        },

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            $('[data-deleteconfirm]', context).once('uiDeleteconfirm').each((index, confirmElement) => {
                const $confirmElement = $(confirmElement);
                $confirmElement.on('click.uiDeleteconfirm', event => {
                    const $modal = $('#popup-modal-deleteconfirm');

                    // Whether the modal markup is missing.
                    if (!$modal.length) {
                        return;
                    }

                    // Whether the modal dialog is already visible.
                    if ($modal.is(':visible')) {
                        // The "delete" button in the modal was clicked and
                        // the click event of the original element triggered.
                        // Allow the original click event and close our modal.
                        Brixx.modules.uiDeleteconfirm.closeModal();
                        return;
                    }

                    // Prevent click handler from firing/the browser from
                    // opening the link target.
                    event.preventDefault();
                    // Stop event propagation to prevent handlers on parent
                    // objects from being triggered.
                    event.stopImmediatePropagation();

                    // Get the title of the object to delete.
                    const title = $confirmElement.data('deleteconfirm');

                    // Initialize the modal dialog element:
                    // Detach any event bindings from the dialog elements.
                    Brixx.detachModules($modal);

                    // Set title.
                    $('#popup-modal-deleteconfirm-title', $modal).html(
                        Translator.translate('ui.general.object-delete-title', {
                            '@object': title
                        })
                    );
                    // Set delete message.
                    $('#deleteconfirm-message', $modal).html(
                        Translator.translate(
                            'ui.general.object-delete-message', {
                                '@object': title
                            }
                        )
                    );

                    // Set the deleteconfirm link target.
                    const $deleteconfirmLink = $('#deleteconfirm-link');
                    if ($confirmElement.is('a') && $confirmElement.attr('href') !== '#') {
                        $deleteconfirmLink.attr('href', $confirmElement.attr('href'));
                        if ($confirmElement.hasClass('use-ajax')) {
                            $deleteconfirmLink
                                .addClass('use-ajax')
                                .attr('data-ajax-actions', '[{"name":"callback","arguments":{"callback":"Brixx.modules.uiDeleteconfirm.closeModal"}}]');
                        }
                        else {
                            $deleteconfirmLink
                                .removeClass('use-ajax')
                                .attr('data-ajax-actions', '');
                        }
                    }
                    else {
                        // Add a click event handler to the modal delete link.
                        $deleteconfirmLink
                            .removeClass('use-ajax')
                            .attr('data-ajax-actions', '')
                            .on('click.uiDeleteconfirm', event => {
                                // Prevent the browser from opening '#' URL.
                                event.preventDefault();
                                // Retrigger click of the original element.
                                $confirmElement.trigger('click');
                            });
                    }

                    // Enable modal event bindings.
                    Brixx.attachModules($modal);

                    // Show the dialog.
                    $modal.foundation('open');
                });

                // Ensure our event handler has precedence over any previously
                // bound handlers.
                const handlers = $._data(confirmElement, 'events')['click'];
                const handler = handlers.pop();
                handlers.splice(0, 0, handler);
            });

            $('#popup-modal-deleteconfirm .modal-close', context).once('uiDeleteconfirm').on('click', event => {
                // Prevent the browser from opening '#' URL.
                event.preventDefault();
                // Close the modal.
                Brixx.modules.uiDeleteconfirm.closeModal();
            });
        },

        detach: context => {
            $('[data-deleteconfirm]', context)
                .findOnce('uiDeleteconfirm')
                .off('click.uiDeleteconfirm')
                .removeOnce('uiDeleteconfirm');

            $('#deleteconfirm-link', context)
                .off('click.uiDeleteconfirm');

            $('#popup-modal-deleteconfirm .modal-close', context)
                .findOnce('uiDeleteconfirm')
                .off('click.deleteConfirm')
                .removeOnce('uiDeleteconfirm');
        }
    };

})(jQuery, Brixx, Translator);
