// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// Foundation framework.
import '../components/foundation';

/**
 * Contains all scripts specific to the print settings form.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    // Whether the print settings module has already been initialized.
    if (typeof Brixx.modules.printSettings !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for the print settings.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.printSettings = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            // Hide or show the footer font settings.
            $('.print-footer-font-settings', context).once('printFooterFont').each((index, element) => {
                const $element = $(element);
                const $checkbox = $element.closest('form').find('.print-footer-font-custom');
                if ($checkbox.length === 0) {
                    return;
                }

                if ($checkbox.is(':checked')) {
                    $element.addClass('hide');
                }
                else {
                    $element.removeClass('hide');
                }

                $checkbox.on('click', () => {
                    if ($checkbox.is(':checked')) {
                        $element.addClass('hide');
                    }
                    else {
                        $element.removeClass('hide');
                    }
                });
            });

            $('.print-preview-scale-dec, .print-preview-scale-inc', context).once('printPreviewScale').on('click', event => {
                const $element = $(event.currentTarget);
                const $scale = $element.closest('form').find('.print-preview-scale');
                if ($scale.length === 0) {
                    return;
                }
                const scale = Brixx.forms.getNumberValue($scale) + ($element.hasClass('print-preview-scale-inc') ? 10 : -10);
                Brixx.forms.setValue($scale, scale);
                $scale.trigger('change');
            });

            $('.print-preview-scale', context).once('printPreviewScale').on('change', event => {
                const $element = $(event.currentTarget);
                const $previewFrame = $element.closest('form').find('.print-preview');
                if ($previewFrame.length === 0) {
                    return;
                }
                const $contentBody = $('body', $previewFrame[0].contentWindow.document);
                if ($contentBody.length === 0) {
                    return;
                }

                let scale = Brixx.forms.getNumberValue($element);
                if (scale) {
                    scale = scale / 100;
                }

                $contentBody.css('transform', 'scale(' + scale + ')');
            });
        }
    };

})(jQuery, Brixx);
