// jQuery.
import $ from 'jquery';
// jQuery Once.
import 'jquery-once';
// Moment.js.
import moment from 'moment';
// Datepicker.
import duDatepicker from '@dmuy/datepicker';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 * @param {moment} moment
 * @param {duDatepicker} duDatepicker
 * @param {object} window
 */
(($, moment, duDatepicker, window) => {

    /**
     * Filter functions for datatables.
     */
    const datatablesFilter = {

        /**
         *
         * @param {jQuery} $table
         *   The datatable jQuery object.
         * @param {*} table
         *   The datatable.
         * @param {*} settings
         *   Brixx settings.
         * @param {int} columnIndex
         *   The column index.
         * @param {string} dateFormat
         *   The date format.
         * @param {string} yearInput
         *   The year input selector.
         * @param {string} daterangeInput
         *   The daterange input selector.
         * @param {string} dateTypeSelect
         *   The date type select selector.
         *
         * @memberOf datatablesFilter
         * @method addDateFilter
         */
        addDateFilter: ($table, table, settings, columnIndex, dateFormat = 'DD.MM.YYYY', yearInput = '#year', daterangeInput = '#daterange', dateTypeSelect = '.date-filter') => {
            // Initialize the datepicker.
            duDatepicker(daterangeInput, {
                format: 'dd.mm.yyyy',
                range: true,
                rangeDelim: '-',
                // Close immediately after selection.
                auto: true,
                // Set Monday as first day of the week.
                firstDay: 1,
                // Set the datepicker language.
                i18n: settings.language
            });

            const periodWrapper = $('.dt-period-wrapper');

            $(table).dataTable.ext.search.push(function (settings, data, dataIndex) {
                if (settings.nTable.id !== $table.attr('id')) {
                    return true;
                }

                var daterange = $(daterangeInput).val();
                var year = parseInt($(yearInput).val(), 10);
                var date = data[columnIndex];
                if (year !== '' && !isNaN(year)) {
                    date = moment(data[columnIndex], dateFormat).format('DD.MM.YYYY').split('.');
                    if (year === '' || isNaN(year) || (year === parseInt(date[2]))) {
                        return true;
                    }
                    return false;
                }
                else if (daterange !== '') {
                    date = data[columnIndex];
                    // Get from date and to date.
                    var from = moment(daterange.split('-')[0], dateFormat);
                    var to = moment(daterange.split('-')[1], dateFormat);
                    if (from === '' || to === '' || typeof from === 'undefined' || typeof to === 'undefined' || (moment(date, dateFormat).isSameOrAfter(from) && moment(date, dateFormat).isSameOrBefore(to))) {
                        return true;
                    }
                    return false;
                }
                else {
                    return true;
                }
            });

            // Add the date filter event handlers.
            $(daterangeInput).once('range-filter').on('keyup change', function () {
                table.draw();
            });
            $(yearInput).once('year-filter').on('keyup change', function () {
                table.draw();
            });
            $(dateTypeSelect).once('date-type-select-init').each(function () {
                // Hide inputs if date type is all.
                if ($(this).val() === 'all') {
                    $(yearInput).hide();
                    $(daterangeInput).hide();
                    $(periodWrapper).hide();
                }

                if ($(this).val() === 'year') {
                    $(periodWrapper).show();
                    $(yearInput).show();
                    $(daterangeInput).hide();
                }
            });
            $(dateTypeSelect).once('date-type-select').on('keyup change', function () {
                // Delete previously selected dates.
                if ($(daterangeInput).val() !== '') {
                    duDatepicker(daterangeInput, 'setValue', '');
                }
                if ($(yearInput).val() !== '') {
                    $(yearInput).val('');
                }
                // Get the selected date type.
                var dateType = $(this).val();

                if (dateType === 'year') {
                    $(periodWrapper).show();
                    $(yearInput).show();
                    $(daterangeInput).hide();
                }
                else if (dateType === 'custom') {
                    $(periodWrapper).show();
                    $(yearInput).hide();
                    $(daterangeInput).show();
                }
                else {
                    $(periodWrapper).hide();
                    $(yearInput).hide();
                    $(daterangeInput).hide();
                }
                table.draw();
            });

        }

    };

    const datatablesButtons = {

        /**
         * Add a button to generate a pdf based on current filters.
         *
         * @param {string} tableId
         *  The table ID.
         * @param {object} filters
         *  The filter css selectors.
         *
         * @memberOf datatablesButtons
         * @method addButtonGeneratePdf
         */
        addButtonGeneratePdf: (tableId, filters) => {
            $.fn.dataTable.ext.buttons['generatepdf-' + tableId] = {
                className: 'buttons-pdf',

                action: function (e, dt, node, config) {
                    // Get the current table data with all applied filters.
                    var tableData = dt.rows({filter: 'applied'}).data().toArray();

                    // Get the current filter values.
                    var filterValues = [];
                    if (typeof filters !== 'undefined' && !$.isEmptyObject(filters)) {
                        // For each filter get the value and save it with key.
                        $.each(filters, function (key, selector) {
                            if ($(selector).val() !== '') {
                                filterValues[key] = $(selector).val();
                            }
                        });
                    }
                    else {
                        console.log('No filters defined.');
                    }

                    const table = $('#' + tableId);
                    const url = table.attr('data-pdf-url');
                    const type = table.attr('data-pdf-type') || 'POST';

                    $('body').addClass('loading');
                    // Call the generator function with ajax and pass the filter values.
                    $.ajax({
                        url,
                        type,
                        xhrFields: {responseType: 'blob'},
                        data: {
                            tableData: type === 'POST' ? tableData : null,
                            filters: filterValues
                        },
                        success: function (data, status, xhr) {
                            var filename = 'Invoices.pdf';
                            var disposition = xhr.getResponseHeader('Content-Disposition');

                            if (disposition) {
                                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                var matches = filenameRegex.exec(disposition);
                                if (matches != null && matches[1]) {
                                    filename = matches[1].replace(/['"]/g, '');
                                }
                            }

                            // Create a blob from the response data.
                            var blob = new Blob([data], {type: xhr.getResponseHeader('content-type')});
                            // Create a link to download the blob.
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = filename;
                            // Append the link to the document.
                            document.body.appendChild(link);
                            // Trigger the click event on the link.
                            link.click();
                            // Remove the link from the document.
                            document.body.removeChild(link);
                            $('body').removeClass('loading');
                        },
                        error: function (errMsg) {
                            alert(errMsg);
                            $('body').removeClass('loading');
                        }
                    });
                }
            };
        }
    };

    if (typeof window.datatablesFilter === 'undefined') {

        /**
         * General utility functions for the BRIXX Application.
         *
         * @global
         *
         * @namespace datatablesFilter
         */
        window.datatablesFilter = datatablesFilter;

    }

    if (typeof window.datatablesButtons === 'undefined') {

        /**
         * General utility functions for the BRIXX Application.
         *
         * @global
         *
         * @namespace datatablesButtons
         */
        window.datatablesButtons = datatablesButtons;

    }
})($, moment, duDatepicker, window);

export const datatablesFilter = window.datatablesFilter;
export const datatablesButtons = window.datatablesButtons;
