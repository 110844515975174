// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// Brixx utility functions.
import brixxUtils from '../misc/brixxUtils';
// Brixx theme.
import Theme from '../misc/theme';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utility functions.
 * @param {Theme} Theme
 *   BRIXX theme.
 */
(($, Brixx, brixxUtils, Theme) => {

    // Whether the UI listings module has already been initialized.
    if (typeof Brixx.modules.uiListings !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for UI listings.
     *
     * UI listings are primarly intended to wrap form tables for
     * multiple items with multiple input fields per item.
     * They allow to sort the items, add new rows (items), and
     * delete rows (items).
     *
     * Within the BRIXX App, they are used for catalog item forms,
     * invoice item forms, and alike.
     *
     * @type {Brixx~module}
     * @tutorial brixx-ui-listings
     */
    Brixx.modules.uiListings = {

        /**
         * Reindexes a UI listing list.
         *
         * Loops through all `.ui-listing-item` items of the list, sets
         * the value of the sort order field specified in the listings'
         * `data-sort-field` selector, and optionally alters the name
         * index/IDs of the child form elements according to the item
         * index, if the `data-auto-index` attribute of the listing has
         * been set.
         *
         * @param {string|HTMLElement|jQuery} list
         *   The `.ui-listing-list` to reindex. Expects either a specific
         *   jQuery selector, a jQuery object, or a DOM element.
         */
        reIndexItems: list => {
            const $list = $(list).first();
            const $listing = $list.closest('.ui-listing');

            if ($listing.length === 0) {
                return;
            }

            const listNamespace = $listing.data('namespace');
            const autoIndex = $listing.data('auto-index');
            const sortFieldSelector = $listing.data('sort-field');

            if (sortFieldSelector || autoIndex) {
                // As we're changing the DOM and therefore potentially break
                // event handlers that are working with element IDs, we detach
                // module bindings first.
                Brixx.detachModules($listing);

                let order = 0;
                $list.children('.ui-listing-item:not(.hide)').filter((index, element) => $(element).closest('.ui-listing').is($listing)).each((index, listItem) => {
                    const $listItem = $(listItem);
                    if (sortFieldSelector) {
                        $(sortFieldSelector, $listItem).val(order + 1);
                    }

                    // Whether to rename all name atttributes of all children
                    // according to the sort order.
                    if (autoIndex) {
                        $('[name^="' + listNamespace + '"]', $listItem).each((index, element) => {
                            // Set the new name.
                            const $element = $(element);
                            const oldName = $element.attr('name');
                            const newName = oldName.replace(new RegExp('^(' + brixxUtils.escapeRegExp(listNamespace) + '\\[[^\\]]*\\])'), listNamespace + '[' + order + ']');
                            $element.attr('name', newName);

                            // Set a new ID and label reference.
                            const oldId = $element.attr('id');
                            const newId = brixxUtils.trim((($list.attr('id') ? ($list.attr('id') + '_') : '') + newName).replace(/[.*+?^${}()|[\]\\]/g, '_').replace(/__+/g, '_'), '_');
                            $('label[for="' + oldId + '"]', $listItem).attr('for', newId);
                            $('[data-validate="#' + oldId + '"]', $listItem).attr('data-validate', '#' + newId);
                            $element.attr('id', newId);
                        });
                    }
                    order++;
                });

                // Restore module bindings.
                Brixx.attachModules($listing);
            }
        },

        /**
         * Returns the highest numeric index of the list items.
         *
         * Parses the name attributes of form fields in the given lists'
         * items and returns the highest numeric index used for list items.
         *
         * @param {string|HTMLElement|jQuery} list
         *   The `.ui-listing-list` to return the highest item index for.
         *   Expects either a specific jQuery selector, a jQuery object, or
         *   a DOM element.
         *
         * @return {number}
         *   The highest numeric list item index used in the list.
         */
        getHighestItemIndex: list => {
            const $list = $(list);
            const $listing = $list.closest('.ui-listing');

            if ($listing.length === 0) {
                return;
            }

            const listNamespace = $listing.data('namespace');
            let maxIndex = 0;

            $list.children('.ui-listing-item').filter((index, element) => $(element).closest('.ui-listing').is($listing)).each((index, listItem) => {
                const name = $('[name^="' + listNamespace + '["]', listItem).first().attr('name');
                if (!name) {
                    return;
                }
                const suffix = name.substring(listNamespace.length + 1);
                const listIndex = parseInt(suffix.substring(0, suffix.indexOf(']')));
                if (isNaN(listIndex)) {
                    return;
                }
                if (listIndex > maxIndex) {
                    maxIndex = listIndex;
                }
            });

            return maxIndex;
        },

        /**
         * Extracts a template key placeholder from the given template key.
         *
         * A template key with placeholder usually has the form `'[placeholder]|[random_ID]'`.
         * This helper returns the `'[placeholder]'` part of such a key.
         *
         * @param {string} key
         *   The template key.
         *
         * @return {string}
         *   The placeholder part of the key, or the entire key, if it doesn't have a
         *   placeholder prefix.
         */
        getTemplateKeyPlaceholder: key => (key.indexOf('|') !== -1) ? key.substring(0, key.indexOf('|')) : key,

        /**
         * Replaces a template placeholder within template markup with a list item index.
         *
         * Safari still doesn't support regular expressions with negative lookbehind.
         * This would allow us to just use:
         *
         * <code>
         * template.replace(
         *     new RegExp('(?<!data-template=")(' + brixxUtils.escapeRegExp(placeholder) + ')', 'g'),
         *     replacement
         * );
         * </code>
         *
         * Instead, we're using a replace callback that does the lookbehind.
         *
         * @param {string} template
         *   The template markup.
         * @param {string} placeholder
         *   The placeholder.
         * @param {string|number} replacement
         *   The new list item index.
         *
         * @return {string}
         *   The template with replaced placeholders.
         */
        replaceTemplatePlaceholder: (template, placeholder, replacement) => template.replace(new RegExp(brixxUtils.escapeRegExp(placeholder), 'g'), (match, idx) => {
            const isDataTemplate = template.substring(idx - 15, idx) === 'data-template="';
            if (!isDataTemplate) {
                return replacement;
            }
            return match;
        }),

        /**
         * Returns a blank list item row for being added to the given list.
         *
         * @param {string|HTMLElement|jQuery} list
         *   The `.ui-listing-list` to return the highest item index for.
         *   Expects either a specific jQuery selector, a jQuery object, or
         *   a DOM element.
         * @param {string} [templateId]
         *   (Optional) The ID of a Theme template to use.
         *   If not specified or the given template doesn't exist, the new
         *   row will be derived from the LAST existing list item with emptied
         *   form field values.
         *
         * @return {jQuery|undefined}
         *   A new listing item row that can be added to the listing list, or
         *   no value if an error occurred.
         */
        createListingItem: (list, templateId) => {
            const $list = $(list);
            const $listing = $list.closest('.ui-listing');

            if ($listing.length === 0) {
                return;
            }

            // The new list item.
            let $newItem;

            // Whether no template ID was given or the template doesn't exist.
            if (!templateId || !Object.prototype.hasOwnProperty.call(Theme.templates, templateId)) {
                // Use the last listing item as template.
                // Detach modules from the list items, as Awesomplete is
                // heavily changing the DOM and many list items use
                // Awesomplete. We use the list parent to really remove all
                // module attachments in an item row. Also delete item
                // event listeners.
                Brixx.detachModules($listing);

                // Create a clone of the last item.
                const $lastItem = $list.children('.ui-listing-item:not(.hide)').filter((index, element) => $(element).closest('.ui-listing').is($listing)).last();
                // Important: The item input element names and IDs are the
                // same as in the last row of the list. Ensure that auto index
                // is enabled and runs on the new item!
                $newItem = $lastItem.clone(true);

                // Reattach modules to the list.
                Brixx.attachModules($listing);

                // Clear old values.
                const $inputElements = $(':input', $newItem);
                // Remove checked and selected attribute from checkboxes,
                // radio buttons and select options.
                $inputElements
                    .not(':button, :submit, :reset, :hidden')
                    .prop('checked', false)
                    .prop('selected', false);
                // Delete value from input and textareas.
                $inputElements
                    .not(':button, :submit, :reset, :checkbox, :radio, select')
                    .val('');
                // Remove calculate triggers.
                $inputElements
                    .removeAttr('value')
                    .removeOnce('calculate');
            }
            else {
                // Determine a new item index.
                const newItemIndex = Brixx.modules.uiListings.getHighestItemIndex($list) + 1;

                // Get the template markup from our theme.
                let template = Theme.templates[templateId]();

                // Replace the template ID within the template text with the
                // new item index.
                // The `BrixxCollectionType` provides templates with the
                // template ID as form field name and ID placeholders.
                const placeholder = Brixx.modules.uiListings.getTemplateKeyPlaceholder(templateId);
                template = Brixx.modules.uiListings.replaceTemplatePlaceholder(template, placeholder, newItemIndex);

                // Create a new item from the template markup.
                $newItem = $(template);

                // Sub templates may contain the placeholder as well. This happens with nested
                // addable listings and may break indexing. We therefore have to create sub template copies
                // with replaced placeholders.
                $newItem.find('[data-templates-hash]').each((idx, element) => {
                    const $element = $(element);
                    const oldHash = $element.data('templates-hash');
                    const newHash = brixxUtils.generateId();

                    $newItem.find('[data-template$="' + oldHash + '"]').each((sidx, addButtonElement) => {
                        const $addButtonElement = $(addButtonElement);
                        const addIdOld = $addButtonElement.data('template');

                        // Whether we don't have the old template markup in the BRIXX settings.
                        // This *should* be added by the `form/elements/collection.html.twig`
                        // BRIXX template for all list item prototypes of addable listings.
                        if (!Object.prototype.hasOwnProperty.call(Brixx.settings.templates, 'template-' + addIdOld)) {
                            return;
                        }

                        // Alter the template reference.
                        const addIdPlaceholder = Brixx.modules.uiListings.getTemplateKeyPlaceholder(addIdOld);
                        const addIdNew = addIdPlaceholder + '|' + newHash;
                        $addButtonElement.attr('data-template', addIdNew).data('template', addIdNew);

                        // Whether a template exists already.
                        if (Object.prototype.hasOwnProperty.call(Brixx.settings.templates, 'template-' + addIdNew)) {
                            return;
                        }

                        // Create a template copy with replaced placeholder.
                        const newTemplate = Brixx.modules.uiListings.replaceTemplatePlaceholder(Brixx.settings.templates['template-' + addIdOld], placeholder, newItemIndex);
                        Brixx.settings.templates['template-' + addIdNew] = newTemplate;
                    });

                    $element.attr('data-templates-hash', newHash).data('templates-hash', newHash);
                });
            }

            return $newItem;
        },

        /**
         * Sets the (field) values of a listing item.
         *
         * Please note: Values and auto increment item filters must NOT use
         * a sequence of two or more '|', as they are used for separating the
         * auto increment instructions from the actual values. (See
         * description of the `values` parameter.)
         *
         * @param {string|HTMLElement|jQuery} listingItem
         *   The `.ui-listing-item` to set (field) values in.
         *   Expects either a specific jQuery selector, a jQuery object, or
         *   a DOM element.
         * @param {object} values
         *   List of scalar values to apply keyed by their jQuery
         *   selector. Numeric values can have an optional auto increment
         *   instruction using the following pattern
         *   '[empty value]||AUTOINCREMENT[||[item filter]]' with:
         *   - '[empty value]': The value to be used, if no previous
         *     item values were found.
         *   - '[item filter]': (Optional) Selector that must match
         *      for at least one element in the rows that shall be
         *      considered as predecessors. E.g., '.type[value="item"]'.
         * @param {string|HTMLElement|jQuery} [list]
         *   (Optional) The `.ui-listing-list` to use when determining
         *   AUTOINCREMENT values. Expects either a specific jQuery selector,
         *   a jQuery object, or a DOM element. If no list has been given,
         *   AUTOINCREMENT values are calculated based on the closest
         *   `.ui-listing-list` parent of `listingItem`.
         *
         * @return {array}
         *   List of elements with set default values. This list may be
         *   used to trigger a change event after modules have been
         *   (re-)attached to the fields.
         */
        setListingItemValues: (listingItem, values = {}, list) => {
            const $listingItem = $(listingItem);
            let changedElements = [];

            $.each(values, (selector, value) => {
                const $element = $(selector, $listingItem);

                // Whether the element could not be found in the listing item.
                if ($element.length === 0) {
                    // Bail out.
                    return;
                }

                // Checkboxes and radio elements.
                if ($element.is(':checkbox') || $element.is(':radio')) {
                    $element.prop('checked', value);
                    changedElements.push($element);
                }
                else {
                    // Whether the value has an auto increment
                    // instruction.
                    let valueParts = value.toString().split('||');
                    if (valueParts.length > 1 && (valueParts[1] === 'AUTOINCREMENT' || valueParts[1] === 'INCREMENT')) {
                        // Determine the list to use for auto increment.
                        let $list = $(list);
                        if ($list.length === 0) {
                            $list = $listingItem.closest('.ui-listing-list');
                        }

                        // Get the default value.
                        value = valueParts[0];

                        // Whether we have an auto increment source list.
                        if ($list.length > 0) {
                            let oldValue = '';
                            let filter = valueParts[1] === 'INCREMENT' ? ('^(' + brixxUtils.escapeRegExp(value) + '[ ]?[\\d]*)$') : '([\\d]+)([^\\d]*)$';

                            // Find all predecessors with a value that has a
                            // numeric part to be incremented.
                            let candidates = $('.ui-listing-item:not(.hide) ' + selector, $list).filter((index, element) => $(element).closest('.ui-listing-list').is($list) && $(element).val() && $(element).val().toString().match(new RegExp(filter, 'ig')));

                            // Whether an item filter condition is defined.
                            if (candidates.length && valueParts.length > 2) {
                                // Filter the candidates.
                                candidates = candidates.filter((index, element) => {
                                    const $li = $(element).closest('.ui-listing-item');
                                    const $filter = $(valueParts[2], $li).filter((idx, el) => $(el).closest('.ui-listing-item').is($li));
                                    return $filter.length > 0;
                                });
                            }
                            // Whether we still have candidates.
                            if (candidates.length) {
                                // Collect all previous values in an array.
                                const previousValues = [];
                                candidates.each((index, candidate) => previousValues.push($(candidate).val()));
                                // Sort the array locale aware with numeric sort enabled.
                                previousValues.sort((a, b) => a.toString().localeCompare(b.toString(), Brixx.settings.locale, {numeric: true}));
                                // Get the highest value. It will be used
                                // as basis for the increment.
                                oldValue = previousValues.pop();
                            }

                            // Whether a value was found.
                            if (oldValue) {
                                // Find occurrences of numbers in that value
                                // that can be incremented.
                                let matches = oldValue.match(/([\d]+)([^\d]*)$/);
                                // Whether there's a number to be incremented.
                                if (matches) {
                                    matches.shift();
                                    // Prefix ahead of the number.
                                    let prefix = oldValue.substring(0, oldValue.length - matches.join('').toString().length);
                                    // The actual numeric part that will be incremented.
                                    let numeric = matches[0];
                                    // Suffix after the number.
                                    let suffix = matches.length > 1 ? matches[1] : '';
                                    // Length of the numeric part in characters.
                                    let numericLength = numeric.length;
                                    // The numeric value that will be incremented.
                                    let numericValue = parseInt(numeric);
                                    // Put everything back together with an incremented
                                    // numeric part.
                                    value = prefix + ((numericValue + 1).toString().padStart(numericLength, '0')) + suffix;
                                }
                                else {
                                    value = value ? (value + ' 2') : '1';
                                }
                            }
                        }
                    }
                    // Input, textarea, select element or buttons.
                    if ($element.is(':input')) {
                        // Set new value.
                        if (!isNaN(parseFloat(value)) && parseFloat(value).toString() === value) {
                            Brixx.forms.setValue($element, Number(value));
                        }
                        else {
                            $element.val(value);
                        }
                        changedElements.push($element);
                    }
                    // HTML markup.
                    else {
                        // Set new value.
                        if (!isNaN(parseFloat(value)) && parseFloat(value).toString() === value) {
                            Brixx.forms.setValue($element, Number(value));
                        }
                        else {
                            $element.html(value);
                        }
                    }
                }
            });

            return changedElements;
        },

        /**
         * Shows or hides remove button and drag handle.
         *
         * If the given list has only one direct child of type
         * `.ui-listing-item`, their `.button-remove` and drag handle
         * are hidden. If the list has more than one listing items,
         * both are shown.
         *
         * @param {string|HTMLElement|jQuery} list
         *   The `.ui-listing-list` to toggle delete buttons and drag handles
         *   for.
         */
        toggleDeleteAndDragHandle: list => {
            const $list = (list);
            const $listing = $list.closest('.ui-listing');

            if ($listing.length === 0) {
                return;
            }

            $('.button-remove, ' + ($listing.data('handle') || '.handle'), $list)
                .filter((index, element) => $(element).closest('.ui-listing').is($listing))
                .css('display', ($list.children('.ui-listing-item:not(.hide)').filter((index, element) => $(element).closest('.ui-listing').is($listing)).length > 1) ? '' : 'none');
        },

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            // Add template hash to template IDs.
            $('[data-template]', context).once('templateHash').each((index, element) => {
                const $element = $(element);
                let templateId = $element.data('template');

                // Whether the template ID is empty.
                if (!templateId) {
                    return;
                }
                // Whether the template ID is hashed already.
                if (templateId.toString().indexOf('|') >= 0) {
                    return;
                }

                // Find parent that defines a templates hash.
                const $parent = $element.closest('[data-templates-hash]');
                if ($parent.length === 0) {
                    return;
                }

                // Add templates hash to template ID.
                const templatesHash = $parent.data('templates-hash');
                templateId = templateId + '|' + templatesHash;
                $element.data('template', templateId).attr('data-template', templateId);
            });

            $('.ui-listing-list', context).each((index, list) => {
                const $list = $(list);

                // Determine the related items list.
                const $listing = $list.closest('.ui-listing');
                if ($listing.length === 0) {
                    return;
                }

                // Sortable.
                if ($listing.hasClass('sortable')) {
                    $list.once('ui-listing-sort').sortable({
                        handle: $listing.data('sort-handle') || '.handle',
                        opacity: 0.7,
                        update: () => {
                            Brixx.detachModules($list);
                            Brixx.modules.uiListings.reIndexItems($list);
                            // (Re-attach) modules.
                            Brixx.attachModules($list);

                            const $form = $listing.closest('form');
                            if ($form.length > 0) {
                                // Recalculate form values.
                                $form.trigger('calculate');

                                // Whether to automatically submit the form
                                // after resort.
                                if ($listing.data('sort-update-submit')) {
                                    Brixx.forms.ajaxSubmitReplace($form);
                                }
                            }
                        }
                    }).disableSelection();
                }

                // Addable.
                if ($listing.hasClass('addable')) {

                    /**
                     * Callback for adding a row to the current listing.
                     *
                     * This callback is used on multiple places, therefore
                     * defined as inline function.
                     *
                     * @param {object} event
                     *   Original event object.
                     */
                    const addListingRow = event => {
                        // Prevent the add link targets ('#') from being triggered.
                        event.preventDefault();

                        // The button or link that has been clicked.
                        const $addButton = $(event.currentTarget);
                        // Add position.
                        const position = $addButton.data('add-position') || 'end';
                        // Get template ID.
                        let templateId = $addButton.data('template');
                        let changedElements = [];

                        // Holds the new row until it has been added to the list.
                        const $newItem = Brixx.modules.uiListings.createListingItem($list, templateId);

                        // Whether the UI listing defines default values for
                        // new items.
                        if ($listing.data('default-values')) {
                            // Get the default values.
                            let defaultValues = $listing.data('default-values');

                            // Whether we have a template ID.
                            if (templateId) {
                                // Remove any unique template identifier from
                                // the template ID.
                                if (templateId.indexOf('|') !== -1) {
                                    templateId = templateId.substring(0, templateId.indexOf('|'));
                                }
                                // Whether the default values have an entry for
                                // the template ID.
                                if (Object.prototype.hasOwnProperty.call(defaultValues, templateId)) {
                                    // Use template specific default values.
                                    defaultValues = defaultValues[templateId];
                                }
                            }

                            changedElements = Brixx.modules.uiListings.setListingItemValues($newItem, defaultValues, $list);
                        }

                        // Append the clone as new row.
                        if (position === 'start') {
                            $list.prepend($newItem);
                        }
                        else {
                            $list.append($newItem);
                        }

                        // Reindex items.
                        Brixx.modules.uiListings.reIndexItems($list);

                        // Hide remove buttons, if only one row exists.
                        Brixx.modules.uiListings.toggleDeleteAndDragHandle($list);

                        // (Re-attach) modules.
                        Brixx.attachModules($listing);

                        // Trigger change event for all elements with default values.
                        changedElements.forEach(element => {
                            $(element).trigger('change');
                        });

                        // Re-calculate, if we are within one of the BRIXX
                        // calculation forms.
                        $list.closest('form').trigger('calculate');

                        // Set focus on first focusable element within the new row.
                        $newItem.find(':focusable').first().focus();
                    };

                    // Bind callback to add listing row.
                    $('.button-add', $listing).filter((index, element) => $(element).closest('.ui-listing').is($listing)).once('ui-listing-add').on('click.uiListingAdd', addListingRow);

                    // Copy item feature with and without templates.
                    $('.button-replicate', $listing).filter((index, element) => $(element).closest('.ui-listing').is($listing)).once('ui-listing-replicate').on('click.uiListingReplicate', event => {
                        // Prevent the replicate link targets ('#') from being triggered.
                        event.preventDefault();

                        // The button or link that has been clicked.
                        const $addButton = $(event.currentTarget);
                        const listNamespace = $listing.data('namespace');

                        // Original item.
                        const $originalItem = $addButton.closest('.ui-listing-item:not(.hide)');

                        // Get template ID.
                        let templateId = $addButton.data('template');

                        // Detach modules.
                        Brixx.detachModules($listing);

                        // Holds the new row until it has been added to the list.
                        const $newItem = Brixx.modules.uiListings.createListingItem($list, templateId);

                        // Replicate values.
                        $(':input[name]', $originalItem).not(':button, :submit, :reset').each((inputIdx, inputElement) => {
                            const $inputElement = $(inputElement);
                            const fullName = $inputElement.attr('name');
                            const fieldName = brixxUtils.getFieldName(fullName);
                            const $target = $newItem.find(':input[name^="' + listNamespace + '"][name$="[' + fieldName + ']"]');

                            if ($target.length === 0) {
                                return;
                            }

                            if ($inputElement.is(':checkbox, :radio')) {
                                $target.prop('checked', $inputElement.is(':checked'));
                            }
                            else {
                                $target.val($inputElement.val());
                            }
                        });

                        // Append the clone as new row.
                        $newItem.insertAfter($originalItem);

                        // Reindex items.
                        Brixx.modules.uiListings.reIndexItems($list);

                        // Hide remove buttons, if only one row exists.
                        Brixx.modules.uiListings.toggleDeleteAndDragHandle($list);

                        // (Re-)Attach modules.
                        Brixx.attachModules($listing);

                        // Re-calculate, if we are within one of the BRIXX
                        // calculation forms.
                        $list.closest('form').trigger('calculate');

                        // Set focus on first focusable element within the new row.
                        $newItem.find(':focusable').first().focus();
                    });
                }

                // Remove row button.
                if ($listing.hasClass('removable')) {
                    $('.button-remove', $list).filter((index, element) => $(element).closest('.ui-listing').is($listing)).once('ui-listing-remove').on('click.uiListingRemove', event => {
                        const $button = $(event.currentTarget);
                        const $element = $button.closest('.ui-listing-item');

                        if ($element.length === 0) {
                            return;
                        }

                        // Detach modules from the element to be deleted.
                        Brixx.detachModules($element);

                        // Whether to hide the element rather than deleting it
                        // from the DOM.
                        if ($button.is('[data-remove-target]')) {
                            $element.addClass('hide');
                            $($button.data('remove-target'), $element).val(1);
                        }
                        else {
                            // Do remove the element.
                            $element.remove();
                        }

                        // Reindex items.
                        Brixx.modules.uiListings.reIndexItems($list);

                        // Hide or display the remove buttons depending on
                        // amount of remaining rows.
                        Brixx.modules.uiListings.toggleDeleteAndDragHandle($list);

                        // Re-calculate, if we are within one of the BRIXX
                        // calculation forms.
                        $list.closest('form').trigger('calculate');
                    });
                    Brixx.modules.uiListings.toggleDeleteAndDragHandle($list);
                }
            });

        },

        /**
         * Detach module callback.
         *
         * @type {Brixx~modulesDetach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to detach from.
         */
        detach: context => {
            $('.ui-listing-list', context).each((index, list) => {
                const $list = $(list);

                // Determine the related items list.
                const $listing = $list.closest('.ui-listing');
                if ($listing.length === 0) {
                    return;
                }

                $('.button-add', $listing)
                    .findOnce('ui-listing-add')
                    .off('click.uiListingAdd')
                    .removeOnce('ui-listing-add');

                $('.button-replicate', $listing)
                    .findOnce('ui-listing-replicate')
                    .off('click.uiListingReplicate')
                    .removeOnce('ui-listing-replicate');

                $('.button-remove', $listing)
                    .findOnce('ui-listing-remove')
                    .off('click.uiListingRemove')
                    .removeOnce('ui-listing-remove');
            });
        }

    };

})(jQuery, Brixx, brixxUtils, Theme);
