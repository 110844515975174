// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// jQuery actual.
import '../lib/jquery.actual';
// Brixx object.
import Brixx from '../misc/brixx';
// BRIXX UI ajax.
import '../components/ui-ajax';
// Utility functions.
import brixxUtils from '../misc/brixxUtils';
// Translator.
import Translator from '../misc/translator';
// Awesomplete.
import Awesomplete from 'awesomplete';
// BRIXX notification messages.
import '../components/ui-messages';
// File uploader.
import '../lib/jquery.fileuploader';
// Foundation framework.
// Not explicitly required in this file, but we use Foundation events,
// so we make sure the bundler will run the Foundation initialization
// before this very module.
import './foundation';
// Datepicker.
import duDatepicker from '@dmuy/datepicker';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utilities.
 * @param {Translator} Translator
 *   BRIXX translator.
 * @param {Awesomplete} Awesomplete
 *   Awesomplete plugin.
 */
(($, Brixx, brixxUtils, Translator, Awesomplete, duDatepicker) => {

    // Whether the forms module has already been initialized.
    if (typeof Brixx.modules.uiForms !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * Form helpers.
     *
     * @namespace Brixx.forms
     * @type {object}
     * @tutorial brixx-ui-forms
     */
    Brixx.forms = {

        /**
         * Stores a reference to the last clicked element.
         */
        prevClick: $(),

        /**
         * Retrieves an element value.
         *
         * Different from {@link Brixx.forms.getNumberValue}, this function
         * will not force numeric results, but tries to determine the result
         * type by the element context. Empty values may be represented by
         * `null`, `false` or empty strings.
         *
         * This helper is used for evaluating element values during form
         * validation.
         *
         * @param {string|HTMLElement|jQuery} element
         *   The (form) element to retrieve the value for.
         * @param {boolean} [comparable=false]
         *   (Optional) Whether a comparable value should be returned. This
         *   affects date values only, which will be parsed and returned as
         *   integer timestamp, if the flag is set.
         *   Defaults to `false`.
         *
         * @return {string|number|boolean|null}
         *   The element value.
         *
         * @memberof Brixx.forms
         * @method getValue
         * @tutorial brixx-ui-forms
         */
        getValue: (element, comparable) => {
            const $element = $(element);
            const groupSeparator = brixxUtils.numberFormatGroupSeparator();
            const decimalSeparator = brixxUtils.numberFormatDecimalSeparator();
            comparable = comparable || false;

            let value;

            // Whether no element could be found.
            if ($element.length === 0) {
                // Return an empty value.
                return null;
            }
            // Determine checkbox and radio values.
            else if ($element.is('[type="checkbox"]') || $element.is('[type="radio"]')) {
                return $element.is(':checked') ? ($element.attr('value') || 'on') : null;
            }
            // Whether the element has a locale-aware format and was
            // already converted by the attach handlers (has a `brixx-type`
            // jQuery-Once data attribute with value `true`).
            else if ($element.is('[data-brixx-type]') && $element.data('jquery-once-brixx-type') === true) {
                const typeInfo = Brixx.forms.getBrixxTypeInfo($element);
                if (typeInfo) {
                    let format;

                    switch (typeInfo.baseType) {
                        case 'currency':
                        case 'decimal':
                            // Retrieve the field value using the number format parser.
                            value = brixxUtils.parseNumberFormat($element.val(), typeInfo.isNullable ? null : 0);
                            // Whether a certain number of decimal digits is implied by
                            // the number format and the value is not empty.
                            if (value && typeInfo.decimals !== null && typeInfo.decimals > 0) {
                                // Truncate the digits.
                                value = brixxUtils.numberRound(value, typeInfo.decimals);
                            }
                            return value;

                        case 'int':
                            value = brixxUtils.parseNumberFormat($element.val(), typeInfo.isNullable ? null : 0);
                            return value ? Math.trunc(value) : value;

                        case 'datex':
                            if ($element.is('input') || $element.is('textarea') || $element.is('select')) {
                                return brixxUtils.parseDateFormat($element.val(), typeInfo.format) || typeInfo.isNullable ? null : 0;
                            }
                            return brixxUtils.parseDateFormat($element.html(), typeInfo.format) || typeInfo.isNullable ? null : 0;

                        case 'date':
                            if (!comparable) {
                                break;
                            }

                            if ($element.is('input') || $element.is('textarea') || $element.is('select')) {
                                format = typeInfo.format;
                                if (!format && $element.data('date-format')) {
                                    format = $element.data('date-format').toString().toUpperCase();
                                }
                                return brixxUtils.parseDateFormat($element.val(), format) || 0;
                            }
                            return brixxUtils.parseDateFormat($element.html(), format) || 0;

                        case 'formula':
                            value = $element.val().toString().replace(new RegExp(brixxUtils.escapeRegExp(groupSeparator), 'g'), '').replace(decimalSeparator, '.').replace(',', '.').replace('x', '*').replace(':', '/');
                            return value || (typeInfo.isNullable ? null : '0');

                    }
                }
            }

            return $element.is('input') || $element.is('textarea') || $element.is('select') ? $element.val() : $element.html();
        },

        /**
         * Validates a given form.
         *
         * This function uses validator elements with `data-validate` attribute
         * to evaluate/validate the value of its target element.
         *
         * Each validator element may reference one target element and multiple
         * validation constraints. Validation constraints are given using
         * data attributes.
         *
         * Supported constraints are:
         * - `data-notblank`: A message to be shown, if the element value is empty.
         *
         * @param {string|HTMLElement|jQuery} form
         *   The form to validate.
         *
         * @return {boolean}
         *   `true`, if the form is valid, `false` otherwise.
         *
         * @memberof Brixx.forms
         * @method validate
         * @tutorial brixx-ui-forms
         */
        validate: form => {
            const $form = $(form);
            let isValid = true;

            $('[data-validate]', $form).each((index, validator) => {
                const $validator = $(validator);
                let hasError = false;
                $validator.html('');

                const $target = $($validator.data('validate'), $form);

                // Whether the target element is not found or not visible.
                if ($target.length === 0 || !$target.is(':visible')) {
                    return;
                }

                let targetValue = Brixx.forms.getValue($target);

                if ($validator.data('notblank') && !targetValue) {
                    hasError = true;
                    $validator.append('<div class="validation-error">' + $validator.data('notblank') + '</div>');
                }

                if ($validator.data('email') && targetValue && !brixxUtils.isValidEmailAddress(targetValue)) {
                    hasError = true;
                    $validator.append('<div class="validation-error">' + $validator.data('email') + '</div>');
                }

                if ($validator.data('phone') && targetValue && !brixxUtils.isValidPhone(targetValue)) {
                    hasError = true;
                    $validator.append('<div class="validation-error">' + $validator.data('phone') + '</div>');
                }

                if ($validator.data('formula') && targetValue && !brixxUtils.isValidFormula(targetValue)) {
                    hasError = true;
                    $validator.append('<div class="validation-error">' + $validator.data('formula') + '</div>');
                }

                // Comparision validators.
                if ($validator.data('validate-value') || $validator.data('validate-ref')) {
                    targetValue = Brixx.forms.getValue($target, true);
                    let refValue;

                    if ($validator.data('validate-ref')) {
                        const $ref = $($validator.data('validate-ref'), $form);

                        if ($ref.length !== 0) {
                            refValue = Brixx.forms.getValue($ref, true);
                        }
                    }
                    else {
                        refValue = $validator.data('validate-value');
                    }

                    if ($validator.data('gt') && targetValue.toString().localeCompare(refValue.toString(), Brixx.settings.locale, {numeric: true}) <= 0) {
                        hasError = true;
                        $validator.append('<div class="validation-error">' + $validator.data('gt') + '</div>');
                    }
                    if ($validator.data('eq') && targetValue.toString().localeCompare(refValue.toString(), Brixx.settings.locale, {numeric: true}) !== 0) {
                        hasError = true;
                        $validator.append('<div class="validation-error">' + $validator.data('eq') + '</div>');
                    }
                    if ($validator.data('lt') && targetValue.toString().localeCompare(refValue.toString(), Brixx.settings.locale, {numeric: true}) >= 0) {
                        hasError = true;
                        $validator.append('<div class="validation-error">' + $validator.data('lt') + '</div>');
                    }
                    if ($validator.data('gteq') && targetValue.toString().localeCompare(refValue.toString(), Brixx.settings.locale, {numeric: true}) < 0) {
                        hasError = true;
                        $validator.append('<div class="validation-error">' + $validator.data('gteq') + '</div>');
                    }
                    if ($validator.data('lteq') && targetValue.toString().localeCompare(refValue.toString(), Brixx.settings.locale, {numeric: true}) > 0) {
                        hasError = true;
                        $validator.append('<div class="validation-error">' + $validator.data('lteq') + '</div>');
                    }
                    if ($validator.data('range') && targetValue) {
                        let [min, max] = refValue.toString().split('|');
                        if (
                            targetValue.toString().localeCompare(min, Brixx.settings.locale, {numeric: true}) < 0
                            || targetValue.toString().localeCompare(max, Brixx.settings.locale, {numeric: true}) > 0
                        ) {
                            hasError = true;
                            $validator.append('<div class="validation-error">' + $validator.data('range') + '</div>');
                        }
                    }
                }

                // Apply validation CSS classes to the element and validator.
                if (hasError) {
                    $validator.addClass('has-errors');
                    $target.addClass('error');
                    isValid = false;
                }
                else {
                    $validator.removeClass('has-errors');
                    $target.removeClass('error');
                }
            });

            // Apply validation CSS classes to the form.
            if (!isValid) {
                $form.addClass('has-errors');
            }
            else {
                $form.removeClass('has-errors');
            }

            return isValid;
        },

        /**
         * Ajax submission handler for forms.
         *
         * This handler performs BRIXX UI Actions, if any such are
         * returned by the BRIXX App, or replaces the entire form with
         * the received data, if this data does not contain BRIXX UI
         * Actions.
         *
         * @param {string|HTMLDocument|HTMLElement} form
         *   Form object or selector.
         * @param {boolean} [validate=true]
         *   (Optional) Whether to validate the form before submission
         *   and cancel submission, if the form data is invalid.
         *   Defaults to `true`.
         * @param {object} [data]
         *   (Optional) Form data to be submitted. This parameter
         *   allows to process form data before submission and
         *   passing it as serialized object. If no data is given,
         *   `$.serialize()` will be used on the form to retrieve
         *   the data for submission.
         * @param {HTMLInputElement|HTMLButtonElement} [submitter]
         *   (Optional) Submit button or input element that triggered
         *   the form submission. If this element has a name attribute
         *   and no form data was given when calling this function,
         *   the element name/value will be added to the form data.
         *
         * @return {jqXHR|null}
         *   The jQuery jqXHR deferred object of the Ajax request.
         *
         * @memberof Brixx.forms
         * @method ajaxSubmitReplace
         * @tutorial brixx-ui-forms
         * @tutorial brixx-ui-actions
         */
        ajaxSubmitReplace: (form, validate = true, data, submitter) => {
            const $form = $(form);
            const $loader = $('.ajax-loader', $form);

            // Whether to validate the form and its data is invalid.
            if (validate && !Brixx.forms.validate($form)) {
                // Return a rejected deferred promise.
                const invalidDeferred = $.Deferred();
                invalidDeferred.reject({uiActions: []}, 'invalid');
                return invalidDeferred.promise();
            }

            // Allow to configure AJAX retries using data attributes.
            const timeout = ($form[0].hasAttribute('data-ajax-timeout')) ? parseInt($form.data('ajax-timeout')) : Brixx.settings.ajaxTimeout;
            const retries = ($form[0].hasAttribute('data-ajax-retries')) ? parseInt($form.data('ajax-retries')) : Brixx.settings.ajaxRetries;
            const retriesInterval = ($form[0].hasAttribute('data-ajax-retries-interval')) ? parseInt($form.data('ajax-retries-interval')) : Brixx.settings.ajaxRetriesInterval;

            // Whether no form data was given.
            if (typeof data === 'undefined') {
                // Allow modules to revert form field formatting.
                Brixx.detachModules($form, null, 'serialize');

                // Serialize the form data.
                data = $form.serializeArray();
                // Optionally, add name and value of clicked button/
                // input element.
                if (submitter && $(submitter).attr('name')) {
                    data.push({name: $(submitter).attr('name'), value: $(submitter).val()});
                }

                // Allow modules to re-apply form field formatting.
                Brixx.attachModules($form);
            }

            // Disable submit button(s).
            $('[type="submit"]', $form).attr('disabled', 'disabled');

            $loader.removeClass('hide');
            return $.ajax({
                timeout: timeout,
                retries: retries,
                retriesInterval: retriesInterval,
                method: $form.attr('method') || 'POST',
                type: $form.attr('method') || 'POST',
                url: $form.attr('action'),
                enctype: 'multipart/form-data',
                processData: false,
                data: Array.isArray(data) ? $.param(data) : data
            })
                .done(responseData => {
                    if (!Brixx.uiAjaxResponseHandler(responseData, $form)) {
                        // Detach triggers from the existing form.
                        Brixx.detachModules($form, null, 'unload');

                        // Replace the form contents.
                        $form.html(responseData);

                        // Initialize form elements/handlers.
                        Brixx.attachModules($form);
                    }
                })
                .fail((responseData, statusText) => {
                    Brixx.uiAjaxResponseHandler(responseData, $form, statusText);
                })
                .always(() => {
                    $loader.addClass('hide');
                });
        },

        /**
         * Returns the BRIXX type information of an element.
         *
         * @param {HTMLElement|jQuery} element
         *   The element to extract BRIXX type information from.
         *
         * @return {object|null}
         *   Object containing BRIXX type information, or `null`, if
         *   the elment does not have a valid `data-brixx-type`
         *   attribute.
         *
         * @memberof Brixx.forms
         * @method getBrixxTypeInfo
         */
        getBrixxTypeInfo: (element) => {
            const $element = $(element);

            // Whether the element has no type data attribute.
            if (!$element.data('brixx-type')) {
                return null;
            }

            // Split type definitions as `decimal(4)` into format and decimals.
            const typeInfo = $element.data('brixx-type').replace(/s+/g, '').split(/[(|)]+/);
            // Set the format.
            const type = typeInfo[0];
            const baseType = brixxUtils.trimLeft(type, '?');

            // Determine decimals/format.
            let decimals = null;
            let format = null;
            let suffix = null;

            switch (baseType) {
                case 'int':
                    decimals = 0;
                    suffix = (typeInfo.length > 1) ? typeInfo[1] : '';
                    break;

                case 'currency':
                    decimals = 2;
                    suffix = (typeInfo.length > 1) ? typeInfo[1] : '';
                    break;

                case 'decimal':
                    decimals = (typeInfo.length > 1) ? (parseInt(typeInfo[1], 10) || -1) : -1;
                    suffix = (typeInfo.length > 2) ? typeInfo[2] : '';
                    break;

                case 'datex':
                    format = (typeInfo.length > 1) ? typeInfo[1] : 'L';
                    break;

            }

            return type ? {
                type: type,
                baseType: baseType,
                isNullable: type.charAt(0) === '?',
                decimals: decimals,
                suffix: suffix,
                format: format
            } : null;
        },

        /**
         * Sets the value of a (form) field.
         *
         * @param {string|HTMLElement|jQuery} element
         *   The (form) field element or a valid jQuery selector for
         *   the element.
         * @param {string|number|null} value
         *   The value to set.
         *
         * @return {jQuery}
         *   The matched jQuery element(s) for chaining.
         *
         * @memberof Brixx.forms
         * @method setValue
         * @tutorial brixx-ui-forms
         */
        setValue: (element, value) => {
            const $elements = $(element);

            // Whether no element matches the given parameter.
            if ($elements.length === 0) {
                // Bail out.
                return $elements;
            }

            $elements.each((index, el) => {
                const $element = $(el);
                if ($element.is(':input')) {
                    // Whether the element has a locale-aware format and was
                    // already converted by the attach handlers (has a `brixx-type`
                    // jQuery-Once data attribute with value `true`).
                    if ($element.is('[data-brixx-type]') && $element.data('jquery-once-brixx-type') === true) {
                        const typeInfo = Brixx.forms.getBrixxTypeInfo($element);
                        if (typeInfo) {
                            if (!value && typeInfo.isNullable) {
                                $element.val('');
                            }
                            else if (typeInfo.baseType === 'int') {
                                $element.val(brixxUtils.numberFormat(value || 0, 0) + (typeInfo.suffix ? (' ' + typeInfo.suffix) : ''));
                            }
                            else if (typeInfo.baseType === 'decimal' || typeInfo.baseType === 'currency') {
                                $element.val(brixxUtils.numberFormat(value || 0, typeInfo.decimals) + (typeInfo.suffix ? (' ' + typeInfo.suffix) : ''));
                            }
                            else if (typeInfo.baseType === 'datex') {
                                $element.val(brixxUtils.dateFormat(value, typeInfo.format));
                            }
                            else {
                                $element.val(value);
                            }
                        }
                        else {
                            $element.val(value);
                        }
                    }
                    else {
                        $element.val(value);
                    }
                }
                // Whether the target element is an image tag.
                else if ($element.is('img')) {
                    // Set the `src` attribute of the image.
                    $element.attr('src', value);
                }
                else {
                    if ($element.is('[data-brixx-type]')) {
                        const typeInfo = Brixx.forms.getBrixxTypeInfo($element);
                        if (typeInfo) {
                            if (!value && typeInfo.isNullable) {
                                $element.html('');
                            }
                            else if (typeInfo.baseType === 'int') {
                                $element.html(brixxUtils.numberFormat(value, 0) + (typeInfo.suffix ? (' ' + typeInfo.suffix) : ''));
                            }
                            else if (typeInfo.baseType === 'decimal' || typeInfo.baseType === 'currency') {
                                $element.html(brixxUtils.numberFormat(value, typeInfo.decimals) + (typeInfo.suffix ? (' ' + typeInfo.suffix) : ''));
                            }
                            else if (typeInfo.baseType === 'datex') {
                                $element.html(brixxUtils.dateFormat(value, typeInfo.format));
                            }
                            else {
                                $element.html(value);
                            }
                        }
                        else {
                            $element.html(value);
                        }
                    }
                    else {
                        $element.html(value);
                    }
                }
            });

            return $elements;
        },

        /**
         * Retrieves a numeric value from a (form) field.
         *
         * @param {string|HTMLElement|jQuery} element
         *   The (form) field element or a valid jQuery selector for
         *   the element.
         * @param {number} [defaultValue=0]
         *   (Optional) The numeric default value to return, if the
         *   named field could not be found within the given context.
         *   Defaults to `0`.
         *
         * @return {number}
         *   The extracted number or `defaultValue`, if the named
         *   field could not be found within the given context.
         *
         * @memberof Brixx.forms
         * @method getNumberValue
         * @tutorial brixx-ui-forms
         */
        getNumberValue: (element, defaultValue) => {
            defaultValue = defaultValue || 0;
            const $element = $(element);
            // Whether no element matches the given parameter.
            if ($element.length === 0) {
                // Return the default value.
                return defaultValue;
            }
            // Whether the element has a locale-aware format and was
            // already converted by the attach handlers (has a `brixx-type`
            // jQuery-Once data attribute with value `true`).
            else if ($element.is('[data-brixx-type]') && $element.data('jquery-once-brixx-type') === true) {
                const typeInfo = Brixx.forms.getBrixxTypeInfo($element);
                if (typeInfo) {
                    let value;

                    switch (typeInfo.baseType) {
                        case 'currency':
                        case 'decimal':
                            // Retrieve the field value using the number format parser.
                            value = brixxUtils.parseNumberFormat($element.val(), defaultValue);
                            // Whether a certain number of decimal digits is implied by
                            // the number format and the value is not empty.
                            if (value !== 0 && typeInfo.decimals !== null && typeInfo.decimals > 0) {
                                // Truncate the digits.
                                value = brixxUtils.numberRound(value, typeInfo.decimals);
                            }

                            return value;

                        case 'int':
                            return Math.trunc(brixxUtils.parseNumberFormat($element.val(), defaultValue));

                    }
                }
            }

            // Use `parseFloat()` for regular elements.
            return parseFloat($element.val()) || defaultValue;
        },

        /**
         * Retrieves a numeric value from a (form) field by its jQuery selector.
         *
         * @param {string} selector
         *   jQuery selector for the (form) field.
         * @param {HTMLDocument|HTMLElement|jQuery} [context=document]
         *   (Optional) Context within to search for the form field.
         *   This allows to limit the search to a specific form.
         *   Defaults to the entire document.
         * @param {number} [defaultValue=0]
         *   (Optional) The numeric default value to return, if the
         *   named field could not be found within the given context.
         *   Defaults to `0`.
         *
         * @return {number}
         *   The extracted number or `defaultValue`, if the named
         *   field could not be found within the given context.
         *
         * @memberof Brixx.forms
         * @method getNumberValueBySelector
         * @tutorial brixx-ui-forms
         */
        getNumberValueBySelector: (selector, context, defaultValue) => Brixx.forms.getNumberValue($(selector, context || document), defaultValue),

        /**
         * Retrieves a float value from a named form field by its name.
         *
         * @param {string} name
         *   The name of the form field.
         * @param {HTMLDocument|HTMLElement|jQuery} [context=document]
         *   (Optional) Context within to search for the form field.
         *   This allows to limit the search to a specific form.
         *   Defaults to the entire document.
         * @param {number} [defaultValue=0]
         *   (Optional) The numeric default value to return, if the
         *   named field could not be found within the given context.
         *   Defaults to `0`.
         *
         * @return {number}
         *   The extracted number or `defaultValue`, if the named
         *   field could not be found within the given context.
         *
         * @memberof Brixx.forms
         * @method getNumberValueByName
         * @tutorial brixx-ui-forms
         */
        getNumberValueByName: (name, context, defaultValue) => Brixx.forms.getNumberValueBySelector('[name="' + name + '"]', context, defaultValue),

        /**
         * Retrieves multiple currency float values from named form fields.
         *
         * @param {Array.<String>} names
         *   The names of the form fields to extract.
         * @param {HTMLDocument|HTMLElement|jQuery} [context=document]
         *   (Optional) Context within to search for the form fields.
         *   This allows to limit the search to a specific form.
         *   Defaults to the entire document.
         * @param {number} [defaultValue=0]
         *   (Optional) The numeric default value to use, if a
         *   named field could not be found within the given context.
         *   Defaults to `0`.
         *
         * @return {object.<String, Number>}
         *   The extracted numbers or `defaultValue` keyed by their
         *   names.
         *
         * @memberof Brixx.forms
         * @method getNumberValuesByNames
         * @tutorial brixx-ui-forms
         */
        getNumberValuesByNames: (names, context, defaultValue) => {
            const values = {};
            const contextNamespace = $(context).data('namespace');

            names.forEach(name => {
                if (contextNamespace) {
                    values[name] = Brixx.forms.getNumberValueByName(contextNamespace + '[' + name + ']', context, defaultValue);
                }
                else {
                    values[name] = Brixx.forms.getNumberValueByName(name, context, defaultValue);
                }
            });

            return values;
        }
    };

    /**
     * BRIXX forms module.
     *
     * Provides general tweaks for BRIXX form elements.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiForms = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {
            // Prevent 'enter' key from submitting forms.
            $('form :input').once('enter-submit-prevention').on('keydown', (event) => {
                // Whether the pressed key wasn't the enter key.
                if (event.keyCode !== 13) {
                    // Bail out.
                    return;
                }

                // Whether the current element is a textarea or has the
                // `.allow-enter` CSS class.
                const $element = $(event.currentTarget);
                if ($element.is('textarea') || $element.is('input[type="submit"]') || $element.is('input[type="button"]') || $element.is('button') || $element.hasClass('.allow-enter') || $element.is('#_password')) {
                    // Bail out.
                    return true;
                }

                // Stop event propagation.
                event.preventDefault();

                // Simulate tab key by moving the focus to the next
                // focusable element.
                const $focusables = $(':focusable');
                const currentIndex = $focusables.index($(':focus'));
                $focusables.eq(currentIndex + 1).focus();

                return false;
            });

            // Allow anchors and buttons to be triggered by space bar.
            $('form a, form button').once('enter-space-click').on('keydown', (event) => {
                // Whether the pressed key wasn't the space key.
                if (event.keyCode !== 13 && event.keyCode !== 32) {
                    // Bail out.
                    return true;
                }

                // Stop event propagation.
                event.preventDefault();

                // Simulate click event.
                const $element = $(event.currentTarget);
                $element.trigger('click');

                return false;
            });

            $('[data-min]', context).once('brixx-min').on('change.brixxMin', event => {
                const $element = $(event.currentTarget);
                if ($element.val() === '') {
                    return;
                }

                const minValue = $element.data('min');
                if (Brixx.forms.getNumberValue($element) < minValue) {
                    Brixx.forms.setValue($element, minValue);
                }
            });

            $('[data-max]', context).once('brixx-max').on('change.brixxMax', event => {
                const $element = $(event.currentTarget);
                if ($element.val() === '') {
                    return;
                }

                const maxValue = $element.data('max');
                if (Brixx.forms.getNumberValue($element) > maxValue) {
                    Brixx.forms.setValue($element, maxValue);
                }
            });

            // Locale-aware formats.
            $('[data-brixx-type]', context).once('brixx-type').each((index, element) => {
                const $element = $(element);
                const typeInfo = Brixx.forms.getBrixxTypeInfo($element);

                // Whether the element has no valid `data-brixx-type`
                // attribute.
                if (!typeInfo) {
                    return;
                }

                let value;

                switch (typeInfo.type) {
                    // Number formats.
                    case 'currency':
                    case 'decimal':
                    case 'int':
                        if ($element.is(':input')) {
                            $element.val(brixxUtils.numberFormat(parseFloat($element.val() || 0), typeInfo.decimals) + (typeInfo.suffix ? (' ' + typeInfo.suffix) : ''));
                            $element.on('change.brixxType', event => {
                                Brixx.forms.setValue($element, Brixx.forms.getNumberValue($element));
                            });
                        }
                        else {
                            $element.html(brixxUtils.numberFormat(parseFloat($element.html() || 0), typeInfo.decimals) + (typeInfo.suffix ? (' ' + typeInfo.suffix) : ''));
                        }
                        break;

                    // Number formats with empty value on `0`.
                    case '?currency':
                    case '?decimal':
                    case '?int':
                        if ($element.is(':input')) {
                            value = parseFloat($element.val() || 0);
                            $element.val(value ? (brixxUtils.numberFormat(value, typeInfo.decimals) + (typeInfo.suffix ? (' ' + typeInfo.suffix) : '')) : '');

                            $element.on('change.brixxType', event => {
                                Brixx.forms.setValue($element, Brixx.forms.getNumberValue($element));
                            });
                        }
                        else {
                            value = parseFloat($element.html() || 0);
                            $element.html(value ? (brixxUtils.numberFormat(value, typeInfo.decimals) + (typeInfo.suffix ? (' ' + typeInfo.suffix) : '')) : '');
                        }
                        break;

                    case '?datex':
                        if ($element.is(':input')) {
                            value = brixxUtils.dateFormat(parseInt($element.val()), typeInfo.format);
                            $element.val(value || '');

                            $element.on('change.brixxType', event => {
                                value = brixxUtils.parseDateFormat($element.val(), typeInfo.format);
                                $element.val(value ? brixxUtils.dateFormat(value, typeInfo.format) : '');
                            });
                        }
                        else {
                            value = brixxUtils.dateFormat(parseInt($element.html()), typeInfo.format);
                            $element.html(value || '');
                        }
                        break;
                }
            });

            // Awesomplete.
            $('input.awesomplete, textarea.awesomplete', context).once('awesomplete').each((index, element) => {
                const $element = $(element);
                const $form = $element.closest('form');
                const $label = $('label[for="' + $element.attr('id') + '"]', $form) || $element.prev('label') || $element.next('label');

                let aweOptions = {};
                if ($element.data('json-list')) {
                    let list = $element.data('json-list');
                    if (brixxUtils.hasJsonStructure(list)) {
                        list = JSON.parse(list);
                    }
                    if (list) {
                        aweOptions = {...aweOptions, ...{
                            list: list
                        }};
                    }
                }

                // Initialize Awesomplete.
                $element.awesomplete = new Awesomplete(element, aweOptions);

                // Move the label back after the input field.
                if ($label.length > 0) {
                    $label.detach().insertAfter($element);
                }

                // Whether to add lock icon.
                if ($element.hasClass('add-locks') && $element.closest('.form-element').find('.lock').length === 0) {
                    const $lock = $('<div class="awesomplete-lock lock"><i class="fas fa-lock"></i><i class="fas fa-unlock"></i></div>');
                    if ($element.hasClass('awe-found')) {
                        $lock.addClass('locked');
                    }
                    $lock.insertBefore($element);
                    Brixx.attachModules($lock.parent());
                }

                // Bind selectcomplete handler for form recalculation.
                $element.on('awesomplete-selectcomplete', event => {
                    // Form recalculation is bound to the `keyup` event, so we
                    // just trigger that event on select-complete.
                    $element.closest('form').trigger('keyup');

                    // Execute custom selectcomplete handlers, if there are any
                    // defined.
                    if ($element.data('selectcomplete')) {
                        brixxUtils.executeFunctionByName($element.data('selectcomplete'), event.currentTarget, event);
                    }
                });
            });

            // Material locks.
            $('.awesomplete-lock', context).once('awesomplete-lock').on('click.awesompleteLock', event => {
                const $lock = $(event.currentTarget);
                if ($lock.hasClass('locked')) {
                    const $parent = $lock.parent();
                    $('input.awesomplete, textarea.awesomplete, input.awesompletex, textarea.awesompletex', $parent).each((index, input) => {
                        $(input).val($(input).val() + ' ');
                    });
                    $lock.removeClass('locked');
                }
            });

            // Growing textareas.
            $('.ta-grow textarea', context).once('ta-grow').each((index, textarea) => {
                const $textarea = $(textarea);
                let $awesompleteWrap;

                // Try finding grow dummy. Especially after Awesomplete changed the DOM
                // around our growing textbox, the dummy sometimes doesn't get removed in
                // the detach handlers. In this case, we re-use the existing dummy element.
                let $dummy = $textarea.closest('.ta-grow').find('.ta-grow-dummy');
                if ($dummy.length === 0) {
                    $dummy = $('<span />').addClass('ta-grow-dummy');
                }
                else {
                    $dummy.detach();
                }

                $awesompleteWrap = $textarea.closest('.ta-grow').find('div.awesomplete');
                if ($awesompleteWrap.length > 0) {
                    $dummy.insertBefore($awesompleteWrap);
                }
                else {
                    $dummy.insertBefore($textarea);
                }

                // Ensure the padding/font CSS properties of the dummy element are
                // the same as those of the textarea.
                for (const cssProp of [
                    'padding',
                    'font-size',
                    'line-height',
                    'border',
                    'margin'
                ]) {
                    $dummy.css(cssProp, $textarea.css(cssProp));
                }

                // Set the dummy element HTML to the same text as the textarea content.
                $dummy.html(brixxUtils.nl2br($textarea.val()) + '&nbsp;');

                // Bind ke, focus and blur events to textarea.
                $textarea
                    .on('keyup.taGrow', () => {
                        $dummy.html(brixxUtils.nl2br($textarea.val()) + '&nbsp;');
                    })
                    .on('focus.taGrow', () => {
                        $dummy.css('display', 'block');
                    })
                    .on('blur.taGrow', () => {
                        $dummy.css('display', 'none');
                    });
            });

            // Single-choice checkboxes.
            // Unchecks all other checkboxes, if their names match the
            // name of the current checkbox.
            $('input[type="checkbox"].radio', context).once('checkboxes-single-choice').on('click', (event) => {
                const $box = $(event.currentTarget);

                if ($box.is(':checked')) {
                    var group = 'input[name="' + $box.attr('name') + '"]';

                    $(group).prop('checked', false);
                    $box.prop('checked', true);
                }
                else {
                    $box.prop('checked', false);
                }
            });

            // Members form adaptive placeholders tweak.
            $('.members-plugin', context).once('adaptive-placeholders').each((index, membersForm) => {
                const $membersForm = $(membersForm);

                $('input[type="text"],input[type="password"],input[type="email"]', $membersForm).each((index, input) => {
                    const $input = $(input);
                    const $label = $('label[for="' + $input.attr('id') + '"]', $membersForm);
                    if ($label.length !== 0) {
                        $input.attr('required', 'required');
                        $label
                            .attr('placeholder', $label.text())
                            .attr('alt', $label.text())
                            .attr('required', 'required')
                            .addClass('required')
                            .text('')
                            .detach()
                            .insertAfter($input);
                    }
                });
                $membersForm.removeClass('transp');
            });

            // Icon picker.
            $('.icon-picker', context).once('icon-picker').each((index, iconPicker) => {
                const $iconPicker = $(iconPicker);
                const $icons = $('.icon-picker-icon', $iconPicker);
                $icons.on('click', (event) => {
                    const $icon = $(event.currentTarget);
                    const icon = $icon.data('icon');
                    const inputId = '#' + $icon.data('inputid');
                    const pickerId = inputId + '__iconpicker_dropdown';
                    const imageId = inputId + '__icon';
                    $icons.removeClass('active');
                    $icon.addClass('active');
                    $(inputId).val(icon);
                    $(imageId).attr('src', settings.iconsPath + icon);
                    $(pickerId).foundation('close');
                });
            });

            // Date picker element for input elements with CSS class `datepicker`.
            // BRIXX uses the duDatepicker to allow selecting a date on click.
            $('.datepicker', context).once('datepicker').each((index, element) => {
                const $element = $(element);
                const format = $element.data('date-format') || 'dd.mm.yyyy';

                duDatepicker(element, {
                    // Close immediately after selection.
                    auto: true,
                    // Set the date format.
                    format: format,
                    // Set the datepicker language.
                    i18n: settings.language
                });

                // duDatepicker sets the actual input field to `readonly`. We don't want
                // this behavior, as it also clashes with the `required` check that is
                // used for the adaptive placeholder field labels. So we remove the readonly
                // attribute after duDatepicker initialization.
                $element.attr('readonly', false);
            });
            $('.btn-datepicker[data-target]', context).once('datepicker').on('click.datepicker', (event) => {
                const $target = $($(event.currentTarget).data('target'));
                if ($target.length) {
                    $target.trigger('click');
                }
            });

            // Select all text within an input or textarea element on click, if
            // it has the class `clickselect`.
            $('body').once('text-clickselect').each(() => {
                // Bind a click and focusin event to all DOM elements of the document.
                $(document).on('click.clickselect, focusin.clickselect', '*', event => {
                    const $element = $(event.target || event.srcElement);

                    // Whether the last clicked or focused element is the current element.
                    if ($element.is(Brixx.forms.prevClick)) {
                        // Bail out.
                        return;
                    }

                    // Remember the current element as last element clicked or with focus.
                    Brixx.forms.prevClick = $element;

                    // Whether the element is an element with `clickselect` CSS class.
                    if ($element.is('input.clickselect, textarea.clickselect')) {
                        // Select all text within using a little timeout.
                        setTimeout(() => {
                            $element.select();
                        }, 50);
                    }
                });
            });

            // FileUploaderType Symfony FormType.
            $('input[type="file"][data-fileuploader-element]', context).once('fileuploader-element').each((index, element) => {
                const $element = $(element);
                const elementName = $element.attr('name');
                const listName = (brixxUtils.getFieldNameGroup(elementName) !== '') ? (brixxUtils.getFieldNameGroup(elementName) + '[files]') : (brixxUtils.getFieldName(elementName) + 'Files');
                const idName = (brixxUtils.getFieldNameGroup(elementName) !== '') ? (brixxUtils.getFieldNameGroup(elementName) + '[id]') : (brixxUtils.getFieldName(elementName) + 'Id');
                const $idElement = $('[name="' + idName + '"]');

                if ($idElement.length < 1) {
                    return;
                }
                if (!$idElement.val()) {
                    $idElement.val(brixxUtils.generateUuid());
                }

                $element.fileuploader({
                    dragDrop: true,
                    enableApi: true,
                    listInput: listName,
                    thumbnails: {
                        // canvasImage: false,
                        canvasImage: {
                            height: $element.data('fileuploader-thumb-height') || 75,
                            width: $element.data('fileuploader-thumb-width') || 75
                        }
                    },
                    upload: {
                        url: '/' + settings.language + '/fileupload',
                        data: {
                            element: elementName,
                            elementList: listName,
                            elementId: $idElement.val()
                        },
                        type: 'POST',
                        enctype: 'multipart/form-data',
                        start: true,
                        synchron: true,
                        chunk: false,
                        onSuccess: function (data, item, listEl, parentEl, newInputEl, inputEl, textStatus, jqXHR) {
                            const api = $.fileuploader.getInstance(inputEl);
                            $.each(jqXHR.responseJSON.files, (index, file) => {
                                if (file.old_name !== item.name) {
                                    return;
                                }
                                api.update(item, file);
                            });
                            setTimeout(function () {
                                item.html.find('.progress-bar2').fadeOut(400);
                            }, 400);
                        },
                        onError: function (item, listEl, parentEl, newInputEl, inputEl, jqXHR, textStatus, errorThrown) {
                            var progressBar = item.html.find('.progress-bar2');

                            if (progressBar.length > 0) {
                                progressBar.find('span').html(0 + '%');
                                progressBar.find('.fileuploader-progressbar .bar').width(0 + '%');
                                item.html.find('.progress-bar2').fadeOut(400);
                            }

                            if (item.upload.status !== 'cancelled' && item.html.find('.fileuploader-action-retry').length === 0) {
                                item.html.find('.column-actions').prepend(
                                    '<a class="fileuploader-action fileuploader-action-retry" title="Retry"><i></i></a>'
                                );
                            }
                        },
                        onProgress: function (data, item, listEl, parentEl, newInputEl, inputEl) {
                            var progressBar = item.html.find('.progress-bar2');

                            if (progressBar.length > 0) {
                                progressBar.show();
                                progressBar.find('span').html(data.percentage + '%');
                                progressBar.find('.fileuploader-progressbar .bar').width(data.percentage + '%');
                            }
                        },
                        onComplete: function (listEl, parentEl, newInputEl, inputEl, jqXHR, textStatus) {
                            if ($(inputEl).is('[data-complete-submit]')) {
                                $(inputEl).closest('form').submit();
                            }
                        }
                    },
                    captions: {
                        button: function (options) {
                            return (options.limit === 1 ? Translator.translate('ui.uploader.button.single') : Translator.translate('ui.uploader.button.multi'));
                        },
                        feedback: function (options) {
                            return (options.limit === 1 ? Translator.translate('ui.uploader.button.single') : Translator.translate('ui.uploader.button.multi'));
                        },
                        feedback2: function (options) {
                            return Translator.translate((options.length === 1 ? 'ui.uploader.chosen.single' : 'ui.uploader.chosen.multi'), {'@count': options.length});
                        },
                        confirm: Translator.translate('ui.uploader.confirm'),
                        cancel: Translator.translate('ui.uploader.cancel'),
                        name: Translator.translate('ui.uploader.name'),
                        type: Translator.translate('ui.uploader.type'),
                        size: Translator.translate('ui.uploader.size'),
                        dimensions: Translator.translate('ui.uploader.dimensions'),
                        duration: Translator.translate('ui.uploader.duration'),
                        crop: Translator.translate('ui.uploader.crop'),
                        rotate: Translator.translate('ui.uploader.rotate'),
                        sort: Translator.translate('ui.uploader.sort'),
                        download: Translator.translate('ui.uploader.download'),
                        remove: Translator.translate('ui.uploader.remove'),
                        drop: Translator.translate('ui.uploader.drop'),
                        paste: Translator.translate('ui.uploader.paste'),
                        removeConfirmation: Translator.translate('ui.uploader.removeConfirmation'),
                        errors: {
                            filesLimit: Translator.translate('ui.uploader.errors.filesLimit'),
                            filesType: Translator.translate('ui.uploader.errors.filesType'),
                            fileSize: Translator.translate('ui.uploader.errors.fileSize'),
                            filesSizeAll: Translator.translate('ui.uploader.errors.filesSizeAll'),
                            fileName: Translator.translate('ui.uploader.errors.filesName'),
                            folderUpload: Translator.translate('ui.uploader.errors.folderUpload')
                        }
                    }
                });

                if ($element.data('fileuploader-theme') === 'brixx-profile' || $element.closest('.fileuploader-theme-brixx-profile').length > 0) {
                    $element.closest('.fileuploader').prepend('<img src="/bundles/brixx/img/profile.png" class="imagepreview" width="' + ($element.data('fileuploader-thumb-width') || 75) + '" height="' + ($element.data('fileuploader-thumb-height') || 75) + '">');
                }
                else if ($element.data('fileuploader-theme') === 'brixx-single' || $element.closest('.fileuploader-theme-brixx-single').length > 0) {
                    $element.closest('.fileuploader').prepend('<img src="/bundles/brixx/img/placeholder-' + ($element.data('fileuploader-thumb-width') || 75) + 'x' + ($element.data('fileuploader-thumb-height') || 75) + '.png" class="imagepreview" width="' + ($element.data('fileuploader-thumb-width') || 75) + '" height="' + ($element.data('fileuploader-thumb-height') || 75) + '">');
                }
            });

            // Descriptions toogle.
            $('.checkbox-desc', context).once('toggle-descriptions').each((index, showDescriptionsCheck) => {
                const $showDescriptionsCheck = $(showDescriptionsCheck);
                const $target = $showDescriptionsCheck.closest('form');

                if ($target.length === 0) {
                    return;
                }

                const toggleDescriptions = show => {
                    if (show) {
                        $target.removeClass('hide-descriptions');
                    }
                    else {
                        $target.addClass('hide-descriptions');
                    }
                };

                toggleDescriptions($showDescriptionsCheck.is(':checkbox') ? $showDescriptionsCheck.is(':checked') : $showDescriptionsCheck.val());
                $showDescriptionsCheck.on('change', () => {
                    toggleDescriptions($showDescriptionsCheck.is(':checkbox') ? $showDescriptionsCheck.is(':checked') : $showDescriptionsCheck.val());
                });
            });

            $('.profile-form-inline', context).once('profile-form-inline').each((index, element) => {
                const $form = $(element);
                const typeChoiceName = 'input[name="' + $('.profile-form-inline-type input[type="radio"]', $form).attr('name') + '"]';

                const setVisibility = (event) => {
                    if (typeof event !== 'undefined') {
                        event.preventDefault();
                    }

                    const $typeChoice = $(typeChoiceName + ':checked', $form);

                    switch ($typeChoice.val()) {
                        case 'new':
                            $('.profile-form-inline-new', $form).show();
                            $('.profile-form-inline-existing', $form).hide();
                            break;

                        case 'existing':
                            $('.profile-form-inline-new', $form).hide();
                            $('.profile-form-inline-existing', $form).show();
                            break;

                        default:
                            $('.profile-form-inline-existing, .profile-form-inline-new', $form).hide();
                    }
                };

                $(typeChoiceName, $form).on('change', setVisibility);
                setVisibility();
            });

            $('.cssColorClassSelect').once('cssColorClassSelect').each((index, element) => {
                const $element = $(element);
                const $select = $element.find('select');

                $element.addClass('value-' + $select.val());

                $select.on('change', event => {
                    let i;
                    let matches;
                    let classes = $element.attr('class').split(' ');
                    for (i = 0; i < classes.length; i++) {
                        matches = /^value-/.exec(classes[i]);
                        if (matches !== null) {
                            $element.removeClass(classes[i]);
                        }
                    }

                    $element.addClass('value-' + $select.val());
                });
            });

            $('[data-select-all]').once('selectAll').each((index, element) => {
                const $element = $(element);
                const $choices = $element.find($element.data('select-all'));

                // Whether no checkboxes were found.
                if ($choices.length === 0) {
                    return;
                }

                // Prepend a select-all-wrapper to the element containing
                // links to select all or unselect all cehckboxes.
                const $selectAllLink = $('<a></a>')
                    .html(Translator.translate('ui.action.select-all'));
                const $unSelectAllLink = $('<a></a>')
                    .html(Translator.translate('ui.action.select-none'));
                const $selectAllWrapper = $('<div class="select-all-wrapper"></div>')
                    .append($selectAllLink)
                    .append(' | ')
                    .append($unSelectAllLink);
                $element.prepend($selectAllWrapper);

                // Adjust padding top of container element, as the
                // select-all-wrapper is positioned absolute.
                let elementPaddingTop = brixxUtils.parseUnitValue($element.css('padding-top'));
                if (elementPaddingTop !== 0 && brixxUtils.parseUnitValue($element.css('padding-top'), 'unit') === 'em') {
                    elementPaddingTop = brixxUtils.convertEmToPx(elementPaddingTop);
                }
                elementPaddingTop = elementPaddingTop + $selectAllWrapper.actual('outerHeight');
                elementPaddingTop = elementPaddingTop + brixxUtils.convertEmToPx(0.5);
                $element.css('padding-top', elementPaddingTop + 'px');

                $selectAllLink.on('click', event => {
                    $choices.each((idxChoice, choice) => $(choice).prop('checked', true));
                });
                $unSelectAllLink.on('click', event => {
                    $choices.each((idxChoice, choice) => $(choice).prop('checked', false));
                });
            });
            $('.accordion .accordion-item.error a.accordion-title', context).once('accordionError').each((index, element) => {
                const $element = $(element);
                const $accordion = $element.closest('.accordion');
                console.log($accordion, $element);
                $element.trigger('click');
            });
            $('.accordion[data-multi-expand="false"][data-accordion-group]', context).once('accordionGroupExpand').each((index, accordion) => {
                const $accordion = $(accordion);
                const accordionGroup = $accordion.data('accordion-group');
                if (!accordionGroup) {
                    return;
                }
                $('.accordion-title', $accordion).on('click', event => {
                    $('.accordion[data-multi-expand="false"][data-accordion-group="' + accordionGroup + '"]')
                        .filter((idx, element) => !$(element).is($accordion))
                        .each((idx, element) => {
                            $(element).foundation('up', $('.accordion-item.is-active .accordion-content', element));
                        });
                });
            });
            $('.font-face-choice', context).once('fontFaceExample').each((index, element) => {
                const $element = $(element);
                let fontFace = $element.val();
                const $label = $element.closest('label');
                if ((!fontFace) || $label.length === 0) {
                    return;
                }
                if (fontFace.toString().indexOf(' ') >= 0) {
                    fontFace = "'" + fontFace + "'";
                }
                const $example = $($.parseHTML('<div class="font-face-example">Typisch fiese Kater würden Vögel bloß zum Jux quälen.<br />0123456789 .,\'"!?()%&^#@()[]{};:\\/-+=~`</div>'));
                $label
                    .addClass('clearfix my-3')
                    .css('font-family', fontFace)
                    .append($example);
            });
        },

        /**
         * Detach module callback.
         *
         * Removes the form module bindings.
         *
         * @type {Brixx~modulesDetach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to detach from.
         */
        detach: context => {
            // Convert locale-aware formats back to system formats.
            $('[data-brixx-type]', context).findOnce('brixx-type').each((index, element) => {
                const $element = $(element);
                const typeInfo = Brixx.forms.getBrixxTypeInfo($element);

                // Whether the element has no `data-brixx-type` attribute.
                if (!typeInfo) {
                    $element.off('change.brixxType').removeOnce('brixx-type');
                    return;
                }

                // Do the actual formatting reversion.
                switch (typeInfo.type) {
                    // Number formats.
                    case 'currency':
                    case 'decimal':
                    case 'int':
                        $element.val(brixxUtils.numberTruncate(brixxUtils.parseNumberFormat($element.val(), 0), typeInfo.decimals));
                        break;

                    // Number formats with empty value on `0`.
                    case '?currency':
                    case '?decimal':
                    case '?int':
                        $element.val(brixxUtils.numberTruncate(brixxUtils.parseNumberFormat($element.val(), 0), typeInfo.decimals) || '');
                        break;

                    case '?datex':
                        if ($element.is('input') || $element.is('textarea') || $element.is('select')) {
                            $element.val(brixxUtils.parseDateFormat($element.val(), typeInfo.format) || '');
                        }
                        else {
                            $element.html(brixxUtils.parseDateFormat($element.html(), typeInfo.format) || '');
                        }
                        break;

                }

                $element.off('change.brixxType').removeOnce('brixx-type');
            });

            $('[data-min]', context)
                .findOnce('brixx-min')
                .off('change.brixxMin')
                .removeOnce('brixx-min');

            $('[data-max]', context)
                .findOnce('brixx-max')
                .off('change.brixxMax')
                .removeOnce('brixx-max');

            // Detach growing textareas `.ta-grow`.
            $('.ta-grow textarea', context).each((index, textarea) => {
                const $textarea = $(textarea);
                $textarea.closest('.ta-grow').find('.ta-grow-dummy').remove();
                $textarea.off('keyup.taGrow focus.taGrow blur.taGrow').removeOnce('ta-grow');
            });

            // Detach Awesomplete.
            $('input.awesomplete, textarea.awesomplete', context).each((index, element) => {
                const $element = $(element);

                // Destroy the Awesomplete bindings.
                if (typeof $element.awesomplete !== 'undefined') {
                    $element.awesomplete.destroy();
                    delete $element.awesomplete;
                }

                // Whether to remove lock icon.
                if ($element.hasClass('add-locks')) {
                    const $lock = $element.closest('.form-element').find('.lock');
                    if ($lock.length !== 0) {
                        $lock.remove();
                    }
                }

                // Remove any possibly remaining awesomplete wrapper elements.
                const $awesompleteWrapper = $element.closest('div.awesomplete');
                if ($awesompleteWrapper.length > 0) {
                    $awesompleteWrapper.children().not('ul[role="listbox"], span[role="status"]').detach().insertBefore($awesompleteWrapper);
                    $awesompleteWrapper.remove();
                }

                // Disable all awesomplete event listeners.
                $element
                    .off('awesomplete-close')
                    .off('awesomplete-loadcomplete')
                    .off('awesomplete-match')
                    .off('awesomplete-prepop')
                    .off('awesomplete-select')
                    .off('awesomplete-selectcomplete');
                // Remove once.
                $element
                    .removeOnce('awesomplete');
            });

            // Detach Locks.
            $('.awesomplete-lock', context).each((index, element) => {
                const $element = $(element);
                $element.removeOnce('awesomplete-lock');
                $element.off('click.awesompleteLock').removeClass('locked');
            });

            // Date picker element for input elements with CSS class `datepicker`.
            // BRIXX uses the duDatepicker to allow selecting a date on click.
            $('.datepicker', context).findOnce('datepicker').each((index, element) => {
                const $element = $(element);
                duDatepicker(element, 'destroy');
                $element.removeOnce('datepicker');
            });
            $('.btn-datepicker[data-target]', context).findOnce('datepicker').each((index, element) => {
                const $element = $(element);
                $element
                    .off('click.datepicker')
                    .removeOnce('datepicker');
            });
        }

    };

})(jQuery, Brixx, brixxUtils, Translator, Awesomplete, duDatepicker);
