// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Animate on scroll.
import AOS from 'aos';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {object} AOS
 *   Animate on scroll.
 */
(($, Brixx, AOS) => {

    // Whether the scroll module has already been initialized.
    if (typeof Brixx.modules.uiScroll !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * AOS settings.
     *
     * @type {object}
     */
    const aosSettings = {
        duration: 1000
    };

    /**
     * BRIXX module for UI scroll animations.
     *
     * The animations use the animate on scroll (AOS) plugin.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiScroll = {

        /**
         * Prevent the given element from scrolling.
         *
         * @param {HTMLDocument|HTMLElement|jQuery|string} element
         *   The element to prevent from scrolling.
         * @param {string} [direction='v']
         *   (Optional) The scroll direction to prevent.
         *   Either 'v' for vertical scrolling, or 'h' for
         *   horizontal scrolling.
         *   Defaults to 'v'.
         */
        noScroll: (element, direction) => {
            if (typeof direction === 'undefined' || (direction !== 'v' && direction !== 'h')) {
                direction = 'v';
            }

            const $element = $(element);
            $element.once('uiNoScroll' + direction.toUpperCase()).on('scroll.uiNoScroll' + direction.toUpperCase(), () => {
                if (direction === 'v') {
                    if ($element.scrollTop() !== 0) {
                        $element.scrollTop(0);
                    }
                }
                else {
                    if ($element.scrollLeft() !== 0) {
                        $element.scrollLeft(0);
                    }
                }
            });
        },

        /**
         * Module initialization.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            $('body').once('ui-animate-on-scroll').each(() => {
                AOS.init(aosSettings);
            });

            $('.no-scroll', context).each((index, element) => {
                Brixx.modules.uiScroll.noScroll(element, 'v');
                Brixx.modules.uiScroll.noScroll(element, 'h');
            });

            $('.no-hscroll', context).each((index, element) => {
                Brixx.modules.uiScroll.noScroll(element, 'h');
            });

            $('.no-vscroll', context).each((index, element) => {
                Brixx.modules.uiScroll.noScroll(element, 'v');
            });
        },

        /**
         * Detach module callback.
         *
         * Removes the form module bindings.
         *
         * @type {Brixx~modulesDetach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to detach from.
         */
        detach: context => {
            $('.no-scroll, .no-hscroll', context)
                .findOnce('uiNoScrollH')
                .off('scroll.uiNoScrollH')
                .removeOnce('uiNoScrollH');

            $('.no-scroll, .no-vscroll', context)
                .findOnce('uiNoScrollV')
                .off('scroll.uiNoScrollV')
                .removeOnce('uiNoScrollV');
        }

    };

})(jQuery, Brixx, AOS);
