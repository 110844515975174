// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Brixx utils.
import brixxUtils from '../misc/brixxUtils';
// File uploader.
import '../lib/jquery.fileuploader';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   The BRIXX utility functions.
 */
(($, Brixx, brixxUtils) => {

    // Whether the invoices module has already been initialized.
    if (typeof Brixx.modules.employees !== 'undefined') {
        return;
    }

    /**
     * The employees module of the BRIXX App.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.employees = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            $('.employee-create-button', context).once('employee-create-form').on('click', event => {
                event.preventDefault();
                $('#dd-form .employee-create-tab-title [data-tabs-target]').trigger('click');
            });

            // Handle employee form name field changes and pass them on to the
            // profile create form.
            $('.employee-form .employee-form-name').once('employee-form-name').on('change', event => {
                const $nameField = $(event.currentTarget);
                const $form = $nameField.closest('form');

                $('.profile-form-inline-new-name', $form).val($nameField.val());
            }).trigger('change');

            // Handle contact option changes and pass them on to the profile create form.
            const setContactOptionValue = event => {
                const $element = $(event.currentTarget);
                const $form = $element.closest('form');
                const $listing = $element.closest('.ui-listing-list');
                let phoneSet = false;
                let mailSet = false;

                $('.ui-listing-item', $listing).each((index, item) => {
                    const $item = $(item);
                    const $select = $('select', $item);

                    if ($select.length === 0) {
                        return;
                    }

                    switch ($select.val()) {
                        case 'phone':
                            if (!phoneSet) {
                                $('.profile-form-inline-new-phone', $form).val($('input[name="' + brixxUtils.getFieldNameGroup($select.attr('name')) + '[contactValue]"]').val());
                                phoneSet = true;
                            }
                            break;

                        case 'mail':
                            if (!mailSet) {
                                $('.profile-form-inline-new-mail', $form).val($('input[name="' + brixxUtils.getFieldNameGroup($select.attr('name')) + '[contactValue]"]').val());
                                mailSet = true;
                            }
                            break;
                    }

                    if (mailSet && phoneSet) {
                        return false;
                    }
                });
            };

            $('.employee-form .employee-form-contact-options .ui-listing-item').once('employee-form-contact-option').each((index, element) => {
                $('input, select', $(element)).on('change', setContactOptionValue).first().trigger('change');
            });

            // Adapt the time records form to show its fields dependeing on form
            // view mode (employee or project) and selected time record type (work or
            // others).
            $('form[name="time_spent_form"]', context).once('employee-times-spent').each((index, element) => {
                const $form = $(element);
                // Whether the form mode is 'employee' (which requires to type
                // in a project name, so the related field won't be hidden).
                if ($('input[name="time_spent_form[projectName]"][type="hidden"]', $form).length === 0) {
                    // Add a 'change' event callback to the time record type
                    // select element for setting visibility according to the
                    // current selection.
                    $('select[name="time_spent_form[timeSpentType]"]', $form).on('change', event => {
                        const $element = $(event.currentTarget);
                        // Selection is 'work'.
                        if ($element.val() === 'work') {
                            // Allow a single day entry only.
                            $('.times-single', $form).removeClass('hide');
                            $('.times-multi', $form).addClass('hide');
                        }
                        // All other time record types.
                        else {
                            // Allow to enter an entire week at once.
                            $('.times-single', $form).addClass('hide');
                            $('.times-multi', $form).removeClass('hide');
                            if ($element.val() === 'leave') {
                                $('.hours-value', $form).addClass('hide');
                                $('.hours-empty', $form).removeClass('hide');
                            }
                            else {
                                $('.hours-value', $form).removeClass('hide');
                                $('.hours-empty', $form).addClass('hide');
                            }
                        }
                    }).trigger('change');
                }
                // The form mode is 'project times'.
                else {
                    // Allow to enter multiple times at once, hide the time record type
                    // select element, and require an employee name.
                    $('.times-single', $form).addClass('hide');
                    $('.times-multi', $form).removeClass('hide');
                    $('select[name="time_spent_form[timeSpentType]"]', $form).closest('.cell').addClass('hide');
                    $('[name="time_spent_form[employeeName]"]', $form).closest('.cell').removeClass('hide');
                }
            });
        },

        /**
         * Detach module callback.
         *
         * Removes the employees module bindings.
         *
         * @type {Brixx~modulesDetach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to detach from.
         */
        detach: context => {
        }

    };

})(jQuery, Brixx, brixxUtils);
