// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// UI listings.
import '../components/ui-listings';
// Foundation framework.
import '../components/foundation';

/**
 * Contains all scripts specific to the the Modules settings.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 *
 * @todo Finish migrating the related scripts into this module.
 */
(($, Brixx) => {

    // Whether the modules module has already been initialized.
    if (typeof Brixx.modules.modules !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for the Modules settings.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.modules = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {

        }
    };

})(jQuery, Brixx);
