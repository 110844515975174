// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';
// Utility functions.
import brixxUtils from '../misc/brixxUtils';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utility functions.
 * @param {object} window
 *   The global window object.
 */
(($, Brixx, brixxUtils, window) => {

    // Whether the general module has already been initialized.
    if (typeof Brixx.modules.uiGeneral !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX UI module.
     *
     * Provides general tweaks for the BRIXX UI.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiGeneral = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         */
        attach: () => {
            $('body').once('ui-general').each((index, element) => {
                const $body = $(element);

                $(window).on('resize', () => {
                    if (brixxUtils.matchMedia('screen and (min-width: 64em)')) {
                        $('.brixx-ui .ui-tab-content > .tabs-content').css('min-height', ($(window).height() - 325) + 'px');
                    }
                    else {
                        $('.brixx-ui .ui-tab-content > .tabs-content').css('min-height', '');
                    }
                }).trigger('resize');

                const $activeMainNavTabs = $('.li-level-one.tabs-title.is-active', $body);
                if ($activeMainNavTabs.length > 0) {
                    $activeMainNavTabs[0].scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            });

            $('body').once('ui-general-focus-active').each(() => {
                $(window).on('resize', () => {
                    if (brixxUtils.matchMedia('screen and (min-width: 64em)')) {
                        $('.brixx-ui .ui-tab-content > .tabs-content').css('min-height', ($(window).height() - 325) + 'px');
                    }
                    else {
                        $('.brixx-ui .ui-tab-content > .tabs-content').css('min-height', '');
                    }
                }).trigger('resize');
            });
        }

    };

})(jQuery, Brixx, brixxUtils, window);
