// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// simpleLightbox jQuery plugin.
import '../lib/simple-lightbox';
// Brixx object.
import Brixx from '../misc/brixx';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    // Whether the gallery module has already been initialized.
    if (typeof Brixx.modules.uiGallery !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX gallery module.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiGallery = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            $('.gallery', context).once('gallery-lightbox').each((index, gallery) => {
                const $gallery = $(gallery);
                const $links = $('a.lightbox', $gallery);
                if ($links.length > 0) {
                    $gallery.data('lightbox', $('#' + $gallery.attr('id') + ' a.lightbox').simpleLightbox());
                }
            });
        },

        /**
         * Detach module callback.
         *
         * @type {Brixx~modulesDetach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to detach from.
         */
        detach: context => {
            $('.gallery', context).findOnce('gallery-lightbox').each((index, gallery) => {
                const $gallery = $(gallery);
                if ($gallery.data('lightbox')) {
                    $gallery.data('lightbox').destroy();
                    $gallery.removeData('lightbox');
                }
                $gallery.removeOnce('gallery-lightbox');
            });
        }
    };

})(jQuery, Brixx);
