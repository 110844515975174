// jQuery.
import jQuery from 'jquery';
// Brixx object.
import Brixx from '../misc/brixx';
// BRIXX forms.
import '../components/ui-forms';
// BRIXX notification messages.
import '../components/ui-messages';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {object} window
 *   The global window object.
 */
(($, Brixx, window) => {

    // Whether the UI actions have already been initialized.
    if (typeof Brixx.executeUiActions !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX UI Action.
     *
     * @callback Brixx.actions~uiAction
     *
     * @param {object} data
     *   The action arguments.
     *
     * @see Brixx.executeUiAction
     */

    /**
     * BRIXX UI actions.
     *
     * Actions to be performed after a BRIXX Ajax response
     * has been received from the BRIXX App.
     *
     * @namespace Brixx.actions
     * @type {object.<string, Brixx.actions~uiAction>}
     * @tutorial brixx-ui-actions
     */
    Brixx.actions = {

        /**
         * Shows a dismissable message to the user.
         *
         * @type {Brixx.actions~uiAction}
         *
         * @param {object} data
         *   The action arguments.
         * @param {string} data.message
         *   The message to be shown.
         * @param {object.<string, string>} [data.args]
         *   (Optional) An object of replacements pairs to make after translation.
         *   Incidences of any key in this object are replaced with the
         *   corresponding value.
         *   Defaults to an empty object.
         * @param {string} [data.type]
         *   (Optional) Message type. Allowed values are 'alert', 'success',
         *   'error', 'warning' and 'info'.
         *   Defaults to 'info'.
         *
         * @tutorial brixx-ui-actions
         */
        showMessage: data => {
            Brixx.showMessage(data.message, data.args || {}, data.type || 'info');
        },

        /**
         * Reloads the current page.
         *
         * This method does not change the window location.
         * Dynamically loaded content may be lost, if it is
         * not referenced in the current URL.
         *
         * @type {Brixx.actions~uiAction}
         *
         * @param {object} data
         *   The action arguments.
         * @param {number} [data.timeout]
         *   (Optional) Time to wait until the reload shall be triggered in
         *   milliseconds.
         *   Defaults to `0`.
         *
         * @tutorial brixx-ui-actions
         */
        reload: data => {
            const timeout = data.timeout || 0;

            if (timeout > 0) {
                setTimeout(() => {
                    window.location.reload(true);
                }, timeout);
            }
            else {
                window.location.reload(true);
            }
        },

        /**
         * Redirects to the given new location.
         *
         * @type {Brixx.actions~uiAction}
         *
         * @param {object} data
         *   The action arguments.
         * @param {string} data.location
         *   The target URL to redirect to. This should be an absolute URL.
         * @param {number} [data.timeout]
         *   (Optional) Time to wait until the redirect shall be triggered in
         *   milliseconds.
         *   Defaults to `0`.
         *
         * @tutorial brixx-ui-actions
         */
        redirect: data => {
            const location = data.location || window.location.href;
            const timeout = data.timeout || 0;

            const doRedirect = () => {
                if (window.location.href !== location) {
                    window.location.href = location;
                }
                else {
                    window.location.reload(true);
                }
            };

            if (timeout > 0) {
                setTimeout(doRedirect, timeout);
            }
            else {
                doRedirect();
            }
        },

        /**
         * Alters the browsers' location URL without actually loading the given URL.
         *
         * This action allows to switch the browser URL after a UI tab changed its
         * content, so that the user may load the very special content when doing
         * a page reload.
         *
         * @type {Brixx.actions~uiAction}
         *
         * @param {object} data
         *   The action arguments.
         * @param {string} data.location
         *   The new URL to set. This should be an absolute URL.
         * @param {object<String, String>} [data.localizedLocations]
         *   (Optional) Localized URLs for the language switcher keyed
         *   by their locale IDs. If given, the language switcher target
         *   URLs will be updated accordingly.
         *   Defaults to `{}`.
         * @param {string} [data.pageTitle]
         *   (Optional) A page title to set for the location in the browsers'
         *   browsing history.
         *   Defaults to `''`.
         * @param {object} [data.state]
         *   (Optional) State content to set for the location in the
         *   browsers' browsing history.
         *   Defaults to `{}`.
         *
         * @tutorial brixx-ui-actions
         */
        updateLocation: data => {
            const state = data.state || {};
            const pageTitle = data.pageTitle || '';
            if (pageTitle) {
                state.pageTitle = pageTitle;
            }
            // Update the browser history only, if the target location is
            // different from the current location. This prevents multiple
            // same sequential entries in the browsing history.
            if (window.location.href !== data.location) {
                window.history.pushState(state, pageTitle, data.location);
            }
            // Update the document title with the given page title.
            if (pageTitle) {
                window.document.title = pageTitle;
            }
            // Update the language switcher URLs, if according localized URLs
            // were given.
            if (data.localizedLocations) {
                for (let locale in data.localizedLocations) {
                    if (Object.prototype.hasOwnProperty.call(data.localizedLocations, locale)) {
                        $('a.lang-switch[data-locale="' + locale + '"]').attr('href', data.localizedLocations[locale]);
                    }
                }
            }
        },

        /**
         * Replaces content of the given DOM wrapper element.
         *
         * This action will properly run `{@link Brixx.detachModules}()` and
         * `{@link Brixx.attachModules}()` on the old and new content of the
         * wrapping element.
         *
         * @type {Brixx.actions~uiAction}
         *
         * @param {object} data
         *   The action arguments.
         * @param {string} data.wrapper
         *   jQuery identifier of the target DOM element.
         * @param {string} data.content
         *   The new HTML markup content to set as the wrappers' inner HTML.
         *
         * @tutorial brixx-ui-actions
         */
        replace: data => {
            const $target = $(data.wrapper);
            if ($target.length === 0) {
                return;
            }
            Brixx.detachModules($target);
            $target.html(data.content);
            Brixx.attachModules($target);
        },

        /**
         * Activates and shows a navigation tab.
         *
         * @param {object} data
         *   The action arguments.
         * @param {string} data.target
         *   jQuery selector for the main navigation tab to show.
         * @param {string} [data.subTarget]
         *   (Optional) jQuery selector for the sub navigation tab
         *   to show.
         */
        navActivate: data => {
            const $target = $(data.target);
            if ($target.length === 0) {
                return;
            }
            $('#tabslevel1').foundation('selectTab', $target, true);
            if (typeof data.subTarget === 'undefined') {
                return;
            }
            const $subNav = $target.find('.ui-nav-tabs.ui-nav-tabs-secondary');
            const $subTarget = $(data.subTarget);
            if ($subNav.length === 0 || $subTarget.length === 0) {
                return;
            }
            $subNav.foundation('selectTab', $subTarget, true);
        },

        /**
         * Runs the given BRIXX UI JavaScript callback function by name.
         *
         * @param {object} data
         *   The action arguments.
         * @param {string} data.callback
         *   The name of the callback function to execute. Can be
         *   namespaced. E.g., `'BRIXX.moduleName.myCallback'`.
         * @param {string} [data.callbackArguments]
         *   (Optional) Array of arguments to pass to the callback
         *   function.
         *   Defaults to an empty array.
         */
        callback: data => {
            const namespaceParts = (data.callback || '').toString().split('.');
            if (namespaceParts.length === 0) {
                return;
            }
            let parent = window;
            for (let property of namespaceParts) {
                if (!Object.prototype.hasOwnProperty.call(parent, property)) {
                    return;
                }
                parent = parent[property];
            }
            if (typeof parent === 'function') {
                parent.apply(null, data.callbackArguments || []);
            }
        }
    };

    /**
     * Runs the given BRIXX UI action.
     *
     * BRIXX UI Actions enable the server-side BRIXX App to trigger
     * interactions within the client-side BRIXX UI, if e.g., Ajax
     * requests are answered by the BRIXX App and require a certain
     * change or automatic action within the BRIXX UI.
     *
     * @param {function} action
     *   The name of the UI action to call.
     * @param {...args} args
     *   Additional arguments to pass along to the action.
     *
     * @memberof Brixx
     * @method executeUiAction
     * @tutorial brixx-ui-actions
     */
    Brixx.executeUiAction = (action, ...args) => {
        if (action in Brixx.actions) {
            Brixx.actions[action](...args);
        }
    };

    /**
     * Runs all actions of the given BRIXX UI actions array.
     *
     * BRIXX UI Actions enable the server-side BRIXX App to trigger
     * interactions within the client-side BRIXX UI, if e.g., Ajax
     * requests are answered by the BRIXX App and require a certain
     * change or automatic action within the BRIXX UI.
     *
     * @param {Array<Object>} actions
     *   The UI actions to execute.
     *
     * @memberof Brixx
     * @method executeUiActions
     * @tutorial brixx-ui-actions
     */
    Brixx.executeUiActions = actions => {
        actions.forEach(action => {
            Brixx.executeUiAction(action.name, action.arguments || {});
        });
    };

})(jQuery, Brixx, window);
