// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// Brixx object.
import Brixx from '../misc/brixx';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    // Whether the UI lists search module has already been initialized.
    if (typeof Brixx.modules.uiListsSearch !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for in-browser filtering of ui-lists.
     *
     * @type {Brixx~module}
     *
     * @todo Integrate the module to the UI listings module.
     */
    Brixx.modules.uiListsSearch = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            const $searchBox = $('.list-filter', context);

            if ($searchBox.length === 0) {
                return;
            }

            const $listItems = $searchBox.closest('.module-item-listing-wrapper').find('ul.ui-item-list li');
            const $clear = $searchBox.parent().find('.list-filter-clear');

            $clear.once('lists-search').on('click', () => {
                $searchBox.val('').trigger('keyup');
                $clear.css('display', 'none');
            });

            if ($listItems.length === 0) {
                return;
            }

            $searchBox.once('lists-search').on('keyup change', event => {
                const $element = $(event.currentTarget);
                let searchWords = $element.val().toLowerCase().split(' ');

                $listItems.css('display', '');
                $clear.css('display', '');

                if (searchWords.length === 0) {
                    $clear.css('display', 'none');
                    return;
                }

                $listItems.each((index, item) => {
                    const $item = $(item);
                    let found = true;
                    let text = '';

                    $item.find('.filterable').each((index, filterable) => {
                        text += $(filterable).text().toLowerCase() + ' ';
                    });

                    searchWords.forEach(function (value, index, array) {
                        found = found && text.indexOf(value) > -1;
                    });
                    if (!found) {
                        $item.css('display', 'none');
                    }
                });
            });

        }

    };

})(jQuery, Brixx);
