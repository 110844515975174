// jQuery.
import $ from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// Brixx object.
import Brixx from '../misc/brixx';
// Translator.
import Translator from '../misc/translator';
// BRIXX brixxUtils.
import brixxUtils from '../misc/brixxUtils';
// jQuery Datatables plugin; binds itself to the jQuery object.
import 'datatables.net';
// datatables buttons
import JSZip from 'jszip/dist/jszip.min.js';
import 'datatables.net-buttons/js/dataTables.buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
// jQuery Datatables plugin; adds responsive functionality.
import 'datatables.net-responsive';
// datatables addons
import {datatablesFilter, datatablesButtons} from '../components/datatables-addons';
// Foundation
import {Foundation} from '../lib/foundation-explicit-pieces';



/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {Translator} Translator
 *   BRIXX translator.
 * @param {brixxUtils} brixxUtils
 *   The BRIXX brixxUtils.
 */
(($, Brixx, Translator, brixxUtils, datatablesFilter, datatablesButtons, Foundation, JSZip) => {

    // Whether the offers module has already been initialized.
    if (typeof Brixx.modules.offers !== 'undefined') {
        return;
    }

    // Set JSZip as global variable for datatables buttons.
    window.JSZip = JSZip;

    /**
     * Timestamp DataTables cell renderer.
     *
     * @param {number|null} d
     *   Timestamp.
     * @param {string} type
     *   Render type.
     * @param {object} row
     *   Row data.
     *
     * @return {number|string}
     *   Formatted UNIX timestamp or the plain timestamp of a
     *   DataTables field (cell).
     */
    $.fn.dataTable.render.timestamp = (d, type, row) => {
        if (!d) {
            return type === 'sort' || type === 'type' ? 0 : d;
        }

        // Order and type get a number value, everything else
        // sees a human-readable date string.
        return type === 'sort' || type === 'type' ? d : brixxUtils.dateFormat(d);
    };

    // Add generate pdf button and consider the following filters.
    const filters = {
        year: '.offers-list input.year-filter',
        daterange: '.offers-list input.daterange-filter',
        search: '.offers-list .dt-search-form input[type="search"]'
    };

    datatablesButtons.addButtonGeneratePdf('offers-table', filters);

    /**
     * The offers module of the BRIXX App.
     *
     * Initializes the offers DataTable of the BRIXX Apps'
     * offers feature.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.offers = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {
            $(context).foundation();
            $('.offers-list', context).once('offers-list').each((index, offersList) => {
                var $list = $(offersList);
                var $table = $('#offers-table', $list);
                // var $statusFilter = $('.offers-filters .status-filter', $list);
                var listUrl = $table.data('list-url');
                var dataTable = $table.DataTable({
                    order: [[0, 'desc']],
                    stateSave: true,
                    lengthMenu: [[25, 50, 100, -1], [25, 50, 100, Translator.translate('ui.overview.offers.table-pagination-all')]],
                    pageLength: 25,
                    paging: false,
                    responsive: true,
                    dom: '<"dt-search-form" f><"dt-filter-wrapper"><"dt-length" l><"dt-actions" B><"dt-period-wrapper">tipr',
                    buttons: [
                        {
                            extend: 'spacer',
                            text: Translator.translate('ui.overview.offers.buttons-label')
                        },
                        {
                            extend: 'generatepdf-offers-table',
                            text: Translator.translate('ui.overview.offers.list.export-pdf'),
                            titleAttr: Translator.translate('ui.overview.offers.list.export-pdf')
                        },
                        {
                            extend: 'csv',
                            text: Translator.translate('ui.overview.offers.list.export-csv'),
                            titleAttr: Translator.translate('ui.overview.offers.list.export-csv'),
                            exportOptions: {
                                columns: [0, 1, 2, 3, 4, 5, 6, 7]
                            }
                        },
                        {
                            extend: 'excel',
                            extension: '.xlsx',
                            text: Translator.translate('ui.overview.offers.list.export-xls'),
                            titleAttr: Translator.translate('ui.overview.offers.list.export-xls'),
                            exportOptions: {
                                columns: [0, 1, 2, 3, 4, 5, 6, 7]
                            }
                        },
                        {
                            extend: 'print',
                            text: Translator.translate('ui.overview.offers.list.print'),
                            titleAttr: Translator.translate('ui.overview.offers.list.print'),
                            exportOptions: {
                                columns: [0, 1, 2, 3, 4, 5, 6, 7]
                            }
                        }
                    ],
                    language: {
                        emptyTable: Translator.translate('ui.overview.offers.table-empty'),
                        info: Translator.translate('ui.overview.offers.table-info'),
                        infoEmpty: Translator.translate('ui.overview.offers.table-empty'),
                        infoFiltered: Translator.translate('ui.overview.offers.table-info-filtered'),
                        infoPostFix: '',
                        infoThousands: brixxUtils.numberFormatGroupSeparator(),
                        lengthMenu: Translator.translate('ui.overview.offers.table-length-menu'),
                        loadingRecords: Translator.translate('ui.overview.offers.table-loading'),
                        processing: Translator.translate('ui.overview.offers.table-processing'),
                        search: Translator.translate('ui.overview.offers.table-search'),
                        zeroRecords: Translator.translate('ui.overview.offers.table-empty'),
                        paginate: {
                            first: Translator.translate('ui.overview.offers.table-pager-first'),
                            previous: Translator.translate('ui.overview.offers.table-pager-previous'),
                            next: Translator.translate('ui.overview.offers.table-pager-next'),
                            last: Translator.translate('ui.overview.offers.table-pager-last')
                        }
                    },
                    ajax: {
                        url: listUrl,
                        type: 'POST',
                        data: {
                            // Limit the returned offers information to the
                            // required fields only.
                            fields: [
                                'id',
                                'devisType',
                                'devisnumber',
                                'auftraggeber',
                                'customer',
                                'baustelle',
                                'baustelle2',
                                'catalogsTotal',
                                'devistotal',
                                'date',
                                'pdfAssetId',
                                'viewUrl',
                                'downloadUrl',
                                'editUrl'
                            ]
                        },
                        dataSrc: json => {
                            return json.items;
                        }
                    },
                    columnDefs: [
                        {
                            targets: [0],
                            render: $.fn.dataTable.render.timestamp,
                            responsivePriority: 1
                        },
                        {
                            targets: [1],
                            responsivePriority: 10
                        },
                        {
                            targets: [2],
                            responsivePriority: 2
                        },
                        {
                            targets: [3],
                            responsivePriority: 4
                        },
                        {
                            targets: [4],
                            responsivePriority: 4
                        },
                        {
                            targets: [5],
                            className: 'dt-body-center',
                            responsivePriority: 5
                        },
                        {
                            targets: [6, 7],
                            className: 'dt-body-right',
                            responsivePriority: 3
                        },
                        {
                            targets: [8],
                            searchable: false,
                            responsivePriority: 1
                        }
                    ],
                    columns: [
                        {
                            data: 'date'
                        },
                        {
                            data: 'devisType'
                        },
                        {
                            data: null,
                            render: (data, type, row) => {
                                return (row.viewUrl) ? ('<a href="' + row.viewUrl + '">' + row.devisnumber + '</a>') : row.devisnumber;
                            }
                        },
                        {
                            data: 'customer'
                        },
                        {
                            data: null,
                            render: (data, type, row) => {
                                var baustelle = row.baustelle;
                                if (row.baustelle2 && row.baustelle2 !== 'null') {
                                    baustelle = baustelle + '<br />' + row.baustelle2;
                                }
                                return baustelle;
                            }
                        },
                        {
                            defaultContent: 'CHF'
                        },
                        {
                            data: null,
                            render: (data, type, row) => {
                                return brixxUtils.numberFormat(parseFloat(row.catalogsTotal), 2);
                            }
                        },
                        {
                            data: null,
                            render: (data, type, row) => {
                                return brixxUtils.numberFormat(parseFloat(row.devistotal), 2);
                            }
                        },
                        {
                            data: null,
                            render: (data, type, row) => {
                                var markup = '';
                                markup += '<button class="btn-kebab-toggle" type="button" data-toggle="kebab-menu-' + row.id + '">Toggle Dropdown</button><div class="dropdown-pane" data-position="bottom" data-alignment="right" id="kebab-menu-' + row.id + '" data-dropdown data-auto-focus="true" data-close-on-click="true">';
                                markup += '<ul class="vertical menu">';
                                markup += (row.editUrl) ? '<li><a href="' + row.editUrl + '" class="edit-link" target="_blank">' + Translator.translate('ui.overview.offers.single.edit') + '</a></li>' : '';
                                markup += (row.downloadUrl) ? '<li><a href="' + row.downloadUrl + '" class="download-link-pdf">' + Translator.translate('ui.overview.offers.single.export-pdf') + '</a></li>' : '';
                                markup += '</ul>';
                                markup += '</div>';
                                return markup;
                            }
                        }
                    ],
                    footerCallback: (row, data, start, end, display) => {
                        if (!data || data.length === 0 || display.length === 0) {
                            $('tfoot .total-net', $table).html(brixxUtils.numberFormat(0, 2));
                            $('tfoot .total-gross', $table).html(brixxUtils.numberFormat(0, 2));
                        }
                        let totalNetto = 0;
                        let totalGross = 0;
                        display.forEach(index => {
                            const current = data[index];
                            totalNetto += parseFloat(parseFloat(current.catalogsTotal).toFixed(2));
                            totalGross += parseFloat(parseFloat(current.devistotal).toFixed(2));
                        });
                        $('tfoot .total-net', $table).html(brixxUtils.numberFormat(totalNetto, 2));
                        $('tfoot .total-gross', $table).html(brixxUtils.numberFormat(totalGross, 2));
                    },
                    drawCallback: (settings) => {
                        $(context).foundation();
                        // Init foundation dropdown for all kebab menus with class btn-kebab-toggle.
                        $('.btn-kebab-toggle', $list).each((index, kebabToggle) => {
                            new Foundation.Dropdown($(kebabToggle), {});
                        });
                    },
                    initComplete: function (settings, json) {
                        $('.dataTables_filter input', $list).once('datatable-input-wrap').each((index, filterInput) => {
                            const $filterInput = $(filterInput);
                            $filterInput.parent().append($('<div class="input-wrapper"></div>').append($filterInput.detach()));
                        });
                        $('.datatables-filters', $list).once('datatable-filter-wrap').each((index, filterWrapper) => {
                            const $filterWrapper = $(filterWrapper);
                            $('.dt-filter-wrapper', $list).append($filterWrapper.detach());
                        });
                        $('.period-inputs', $list).once('datatable-period-wrap').each((index, periodInputs) => {
                            const $periodInputs = $(periodInputs);
                            $('.dt-period-wrapper', $list).append($periodInputs.detach());
                        });
                    }
                });

                // Date filter
                datatablesFilter.addDateFilter($table, dataTable, settings, 0, 'DD.MM.YYYY', filters.year, filters.daterange);
            });
        }
    };
})($, Brixx, Translator, brixxUtils, datatablesFilter, datatablesButtons, Foundation, JSZip);
