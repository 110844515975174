// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// Utility functions.
import brixxUtils from '../misc/brixxUtils';
// BRIXX UI Forms component.
import '../components/ui-forms';
// Awesomplete plugin.
import 'awesomplete';
// Translator.
import Translator from '../misc/translator';
// Datepicker.
import duDatepicker from '@dmuy/datepicker';

/**
 * Contains all scripts specific to Regie forms.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {brixxUtils} brixxUtils
 *   BRIXX utilities.
 * @param {Translator} Translator
 *   BRIXX translator.
 * @param {duDatepicker} duDatepicker
 */
(($, Brixx, brixxUtils, Translator, duDatepicker) => {

    // Whether the calculation-regie module has already been initialized.
    if (typeof Brixx.modules.moduleCalcRegie !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * Calculates regie sums within the form.
     *
     * @param {HTMLElement|null} elem
     *   The form element.
     */
    const funcCalcRowsRegie = elem => {
        if (!elem.length) {
            elem = this;
        }

        const $form = $(elem);

        // Recalculate hours.
        let totalHours = 0;
        $('.ui-listing.expenses-items .ui-listing-item', $form).each((index, row) => {
            const $row = $(row);

            const $hrAmount = $('.xp-hours', $row);
            const $hrNumber = $('.xp-amount', $row);
            const $hrValue = $('.xp-price', $row);
            const $hrSum = $('.xp-total', $row);

            // Recalculate row.
            const valNumber = Math.max(1, Brixx.forms.getNumberValue($hrNumber));
            let valAmount = 0;
            for (let day = 1; day <= 6; day++) {
                valAmount += Brixx.forms.getNumberValue($('.xp-day-' + day, $row));
            }
            valAmount = brixxUtils.currencySumRound(valAmount * valNumber);
            const valValue = Brixx.forms.getNumberValue($hrValue);
            const valSum = brixxUtils.currencySumRound(valAmount * valValue);
            totalHours += valSum;

            // Set values.
            Brixx.forms.setValue($hrAmount, valAmount);
            // Brixx.forms.setValue($hrValue, valValue);
            Brixx.forms.setValue($hrSum, valSum);
            $('.hr-sum-display', $row).html($hrSum.val());
        });
        Brixx.forms.setValue($('.expenses-total', $form), totalHours);

        // Recalculate material.
        let totalMaterial = 0;
        $('.ui-listing.material-items .ui-listing-item', $form).each((index, row) => {
            const $row = $(row);

            const $maAmount = $('.ma-amount', $row);
            const $maValue = $('.ma-price', $row);
            const $maSum = $('.ma-total', $row);

            // Recalculate row.
            const valAmount = Brixx.forms.getNumberValue($maAmount);
            const valValue = Brixx.forms.getNumberValue($maValue);
            const valSum = brixxUtils.currencySumRound(valAmount * valValue);
            totalMaterial += valSum;

            // Set values.
            // $maAmount.val(brixxUtils.numberFormat(valAmount));
            // $maValue.val(brixxUtils.numberFormat(valValue, 2));
            Brixx.forms.setValue($maSum, valSum);
        });
        Brixx.forms.setValue($('.material-total', $form), totalMaterial);

        // Material deduction.
        let $materialDeduction = $('.deductiongroup.deduction-material .deduction-value', $form);
        if ($materialDeduction.length !== 0) {
            let materialDeduction = Brixx.forms.getNumberValue($materialDeduction);
            let materialDeductionTotal = brixxUtils.currencySumRound(totalMaterial * materialDeduction / 100);
            Brixx.forms.setValue($('.deductiongroup.deduction-material .deduction-total', $form), materialDeductionTotal);
            totalMaterial += materialDeductionTotal;
        }
        Brixx.forms.setValue($('.material-deducted', $form), totalMaterial);

        let total = totalHours + totalMaterial;

        // Net deduction.
        let $netDeduction = $('.deductiongroup.deduction-net .deduction-value', $form);
        if ($netDeduction.length !== 0) {
            let netDeduction = Brixx.forms.getNumberValue($netDeduction);
            let netDeductionTotal = brixxUtils.currencySumRound(total * netDeduction / 100);
            Brixx.forms.setValue($('.deductiongroup.deduction-net .deduction-total', $form), netDeductionTotal);
            total += netDeductionTotal;
        }
        Brixx.forms.setValue($('.net-deducted', $form), total);

        // Discounts and cashbacks.
        ['discounts', 'cashbacks'].forEach(deductionType => {
            let $deduction = $('.deductiongroup.deduction-' + deductionType + ' .deduction-value', $form);
            if ($deduction.length === 0) {
                return;
            }
            let deduction = Brixx.forms.getNumberValue($deduction);
            let deductionTotal = brixxUtils.currencySumRound(total * deduction / 100);
            Brixx.forms.setValue($('.deductiongroup.deduction-' + deductionType + ' .deduction-total', $form), deductionTotal);
            total -= deductionTotal;
            Brixx.forms.setValue($('.deductiongroup.deduction-' + deductionType + ' .subtotal', $form), total);
        });

        // General deductions.
        let totalGeneral = 0;
        $('.deductiongroup.deduction-general .ui-listing-item:not(.hide) .deduction', $form).each((deductionIndex, deduction) => {
            const $deduction = $(deduction);
            if ($deduction.length === 0) {
                return;
            }
            // Calculated total value of the deduction.
            let deductionTotal = 0;

            // Get deduction value.
            const deductionValue = Brixx.forms.getNumberValue($('.deduction-value', $deduction), 0);

            // Whether the user has given a non-empty deduction value.
            if (deductionValue) {
                // Determine deduction type.
                const deductionType = $('.deduction-type', $deduction).val();
                switch (deductionType) {
                    // Percent value.
                    case 'percent':
                    case 'vat':
                        deductionTotal = brixxUtils.numberRound(total * deductionValue / 100, 2);
                        break;

                    // Absolute value.
                    case 'absolute':
                        deductionTotal = deductionValue;
                        break;

                    // Unknown/unsupported deduction type.
                    default:
                        // Bail out.
                        return;
                }

                deductionTotal = brixxUtils.currencySumRound(deductionTotal);

                totalGeneral += deductionTotal;
            }

            Brixx.forms.setValue($('.deduction-total', $deduction), deductionTotal);
        });

        Brixx.forms.setValue($('.deductiongroup.deduction-general .deductiongroup-total', $form), totalGeneral);
        total -= totalGeneral;
        Brixx.forms.setValue($('.deductiongroup.deduction-general .subtotal, .total-net', $form), total);


        // Vat.
        let $vatDeduction = $('.deductiongroup.deduction-vat .deduction-value', $form);
        if ($vatDeduction.length !== 0) {
            let vatDeduction = Brixx.forms.getNumberValue($vatDeduction);
            if (!vatDeduction) {
                $('.deductiongroup.deduction-vat .deduction-round-wrapper', $form).addClass('hide');
                $('.deductiongroup.deduction-vat .deduction-empty-wrapper', $form).removeClass('hide');
            }
            else {
                $('.deductiongroup.deduction-vat .deduction-round-wrapper', $form).removeClass('hide');
                $('.deductiongroup.deduction-vat .deduction-empty-wrapper', $form).addClass('hide');
            }
            let vatDeductionTotal = brixxUtils.numberRound(total * vatDeduction / 100, 2);
            let $roundingElement = $('.deductiongroup.deduction-vat .deduction-round', $form);
            let round = $roundingElement.is('[type="checkbox"]:checked') || ($roundingElement.is('[type="hidden"]') && $roundingElement.val());
            if (round) {
                vatDeductionTotal = brixxUtils.currencySumRound(vatDeductionTotal);
            }

            Brixx.forms.setValue($('.deductiongroup.deduction-vat .deduction-total', $form), vatDeductionTotal);
            total += vatDeductionTotal;
        }

        Brixx.forms.setValue($('.total-gross', $form), total);
    };

    /**
     * BRIXX module for calculation-regie lists and forms.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.moduleCalcRegie = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {
            // Form calculation events.
            $('.module-item-regie-form', context).once('calc-regie-form-events')
                .on('keyup calculate', event => {
                    funcCalcRowsRegie(event.currentTarget);
                })
                .trigger('calculate');

            // Date picker element for input elements with CSS class `daterangepicker`.
            // BRIXX uses the duDatepicker to allow selecting a daterange on click.
            $('.xp-date', context).once('datepicker').each((index, element) => {
                const $element = $(element);
                const format = $element.data('date-format') || 'dd. mmm yyyy';

                duDatepicker(element, {
                    // Close immediately after selection.
                    auto: true,
                    // Set the date format.
                    format: format,
                    range: false,
                    rangeDelim: '-',
                    // Set Monday as first day of the week.
                    firstDay: 1,
                    // Set the datepicker language.
                    i18n: settings.language,
                    events: {
                        dateChanged: function (data, datepicker) {
                            const $target = $element.closest('.ui-listing-item').find('.xp-desc');
                            if ($target.length === 0) {
                                return;
                            }

                            const date = data._date;
                            // Get the first and last day of the week for the selected date.
                            const monday = brixxUtils.getMonday(date);
                            const saturday = brixxUtils.getSaturday(date);
                            // Get week number for date.
                            const week = brixxUtils.getWeek(date);

                            // Construct the date string for the input field to match the
                            // format: yyyy KW ww | dd. mmm-dd. mmm
                            const dateString = date.getFullYear() + ' ' + Translator.translate('ui.regie.weeknumber-short') + ' ' + week + ' | ' + this.formatDate(monday, 'dd. mmm') + '-' + this.formatDate(saturday, 'dd. mmm');
                            // Get the data-target attribute of the input field.
                            $target.val(dateString);
                        }
                    }
                });
            });
        },

        detach: context => {
            $('.xp-date', context).findOnce('datepicker').each((index, element) => {
                const $element = $(element);
                duDatepicker(element, 'destroy');
                $element.removeOnce('datepicker');
            });
        }
    };

})(jQuery, Brixx, brixxUtils, Translator, duDatepicker);
