// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// jQuery UI.
import 'jquery-ui-bundle';
// Brixx object.
import Brixx from '../misc/brixx';
// BRIXX UI ajax handlers.
import '../components/ui-ajax';
// BRIXX UI messages.
import '../components/ui-messages';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 */
(($, Brixx) => {

    // Whether the forms module has already been initialized.
    if (typeof Brixx.modules.uiTabsSort !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * BRIXX module for dragn'n'drop sorting of UI tabs.
     *
     * After sorting, a callback will be performed to the server
     * to allow saving the new sort order.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiTabsSort = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {

            $('#dd-form', context).once('ui-tabs-sort').each((index, form) => {
                const $form = $(form);
                const $sortInput = $('#sort_order', $form);
                const $autoSubmit = $('#autoSubmit', $form);
                const $uiList = $('#tabslevel1', $form);
                const url = $form.attr('action');
                const responseAction = $form.attr('data-responseaction');
                const connectWith = '.teleport';
                let formData = '';

                const fnSubmit = save => {
                    var sortOrder = [];
                    $uiList.children('li').each((index, tab) => {
                        sortOrder.push($(tab).data('id'));
                    });
                    $sortInput.val(sortOrder.join(','));

                    if (save) {
                        formData =
                            'sort_order=' + $sortInput.val() +
                            '&ajax=' + $autoSubmit[0].checked +
                            '&do_submit=1&responseAction=' + responseAction;

                        $.ajax({
                            timeout: 0,
                            data: formData,
                            type: 'post',
                            url: url,
                            beforeSend: () => {
                                Brixx.showInfo('ui.general.sort-pre-submit');
                            },
                            complete: () => {
                                Brixx.showSuccess('ui.general.sort-complete');
                            }
                        });
                    }
                };

                const update = (e, ui) => {
                    if (ui.item.closest(connectWith).length > 0) {
                        if (ui.sender) {
                            const $tab = $(ui.item);

                            // Remove target tabs panel.
                            const tabTarget = $('a[data-tabs-target]', $tab).data('tabs-target');
                            if (tabTarget) {
                                $('#' + tabTarget).remove();
                            }

                            // @todo Create a dedicated endpoint instead of using the edit URL.
                            $.ajax({
                                url: $tab.data('url-edit'),
                                type: 'PUT',
                                contentType: 'application/json',
                                data: JSON.stringify($(e.target).data('teleport'))
                            }).done(responseData => {
                                Brixx.uiAjaxResponseHandler(responseData);
                            });
                        }
                    }
                    else {
                        fnSubmit($autoSubmit[0].checked);
                    }
                };

                $(connectWith).sortable({
                    handle: '.handle',
                    tolerance: 'pointer',
                    over: function () {
                        $(this).parent().addClass('highlight-outlined');
                        $(this).parent().siblings().addClass('highlight-outlined-siblings');
                    },
                    out: function () {
                        $(this).parent().removeClass('highlight-outlined');
                        $(this).parent().siblings().removeClass('highlight-outlined-siblings');
                    },
                    update
                });

                $uiList.sortable({
                    handle: '.handle',
                    connectWith,
                    tolerance: 'pointer',
                    update,
                    start: function () {
                        createBackdrop();
                    },
                    stop: function () {
                        removeBackdrop();
                    }
                });

                $uiList.disableSelection();

                function createBackdrop() {
                    let $listingNavContent = $('.listing-nav-content');
                    if ($listingNavContent.length === 0) {
                        return;
                    }
                    $listingNavContent.addClass('bg-backdrop');
                    let subtract = '';
                    let p = $listingNavContent[0].getBoundingClientRect();
                    subtract += `<rect x="${p.left}" y="${p.top}" width="${p.width}" height="${p.height}" fill="black"/>`;
                    document.querySelectorAll('.teleport').forEach(e => {
                        p = e.getBoundingClientRect();
                        subtract += `<rect x="${p.left}" y="${p.top}" width="${p.width}" height="${p.height}" fill="black"/>`;
                    });

                    const svgString = `
                      <svg id="backdrop-svg" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask1">
                          <rect width="100%" height="100%" fill="white"/>
                           ${subtract}
                        </mask>
                        <rect width="100vw" height="100vh" fill="black" mask="url(#mask1)"></rect>
                      </svg>
                    `;
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = svgString;
                    document.body.appendChild(tempDiv);
                }

                function removeBackdrop() {
                    const elem = document.querySelector('#backdrop-svg');
                    if (elem) {
                        elem.parentNode.removeChild(elem);
                    }
                    $('.listing-nav-content').removeClass('bg-backdrop');
                }

                $form.on('submit', event => {
                    if (event) {
                        event.preventDefault();
                    }
                    fnSubmit(true);
                });
            });

        }

    };

})(jQuery, Brixx);
