// jQuery.
import $ from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// Brixx object.
import Brixx from '../misc/brixx';
// Translator.
import Translator from '../misc/translator';
// BRIXX brixxUtils.
import brixxUtils from '../misc/brixxUtils';
// jQuery Datatables plugin; binds itself to the jQuery object.
import 'datatables.net';
// datatables buttons
import JSZip from 'jszip/dist/jszip.min.js';
import 'datatables.net-buttons/js/dataTables.buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
// jQuery Datatables plugin; adds responsive functionality.
import 'datatables.net-responsive';
// datatables addons
import {datatablesFilter, datatablesButtons} from '../components/datatables-addons';
// Foundation
import {Foundation} from '../lib/foundation-explicit-pieces';



/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {Translator} Translator
 *   BRIXX translator.
 * @param {brixxUtils} brixxUtils
 *   The BRIXX brixxUtils.
 */
(($, Brixx, Translator, brixxUtils, datatablesFilter, datatablesButtons, Foundation, JSZip) => {

    // Whether the time reports module has already been initialized.
    if (typeof Brixx.modules.timeReportsProject !== 'undefined') {
        return;
    }

    // Set JSZip as global variable for datatables buttons.
    window.JSZip = JSZip;

    /**
     * Timestamp DataTables cell renderer.
     *
     * @param {number|null} d
     *   Timestamp.
     * @param {string} type
     *   Render type.
     * @param {object} row
     *   Row data.
     *
     * @return {number|string}
     *   Formatted UNIX timestamp or the plain timestamp of a
     *   DataTables field (cell).
     */
    $.fn.dataTable.render.timestamp = (d, type, row) => {
        if (!d) {
            return type === 'sort' || type === 'type' ? 0 : d;
        }

        // Order and type get a number value, everything else
        // sees a human-readable date string.
        return type === 'sort' || type === 'type' ? d : brixxUtils.dateFormat(d);
    };

    // Add generate pdf button and consider the following filters.
    const filters = {
        year: '.time-reports-project-list input.year-filter',
        daterange: '.time-reports-project-list input.daterange-filter',
        project: '.time-reports-project-list select.project-filter',
        search: '.time-reports-project-list .dt-search-form input[type="search"]'
    };

    datatablesButtons.addButtonGeneratePdf('time-reports-project-table', filters);

    /**
     * The time reports module of the BRIXX App.
     *
     * Initializes the time reports DataTable of the BRIXX Apps'
     * time reports feature.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.timeReportsProject = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {
            $(context).foundation();
            $('.time-reports-project-list', context).once('time-reports-project-list').each((index, list) => {
                var $list = $(list);
                var $table = $('#time-reports-project-table', $list);
                var $projectFilter = $('.time-reports-project-filters .project-filter', $list);
                var $dateFilter = $('.time-reports-project-filters .date-filter', $list);
                var listUrl = $table.data('list-url');
                $table.DataTable({
                    order: [[0, 'desc']],
                    stateSave: true,
                    lengthMenu: [[25, 50, 100, -1], [25, 50, 100, Translator.translate('ui.overview.time-reports.table-pagination-all')]],
                    pageLength: 25,
                    paging: false,
                    responsive: true,
                    autoWidth: false,
                    dom: '<"dt-search-form" f><"dt-filter-wrapper"><"dt-length" l><"dt-actions" B><"dt-period-wrapper">tipr',
                    buttons: [
                        {
                            extend: 'spacer',
                            text: Translator.translate('ui.overview.time-reports.buttons-label')
                        },
                        {
                            extend: 'generatepdf-time-reports-project-table',
                            text: Translator.translate('ui.overview.time-reports.list.export-pdf'),
                            titleAttr: Translator.translate('ui.overview.time-reports.list.export-pdf')
                        },
                        {
                            extend: 'csv',
                            text: Translator.translate('ui.overview.time-reports.list.export-csv'),
                            titleAttr: Translator.translate('ui.overview.time-reports.list.export-csv'),
                            exportOptions: {
                                columns: [0, 1, 2, 3]
                            }
                        },
                        {
                            extend: 'excel',
                            extension: '.xlsx',
                            text: Translator.translate('ui.overview.time-reports.list.export-xls'),
                            titleAttr: Translator.translate('ui.overview.time-reports.list.export-xls'),
                            exportOptions: {
                                columns: [0, 1, 2, 3]
                            }
                        },
                        {
                            extend: 'print',
                            text: Translator.translate('ui.overview.time-reports.list.print'),
                            titleAttr: Translator.translate('ui.overview.time-reports.list.print'),
                            exportOptions: {
                                columns: [0, 1, 2, 3]
                            }
                        }
                    ],
                    language: {
                        emptyTable: Translator.translate('ui.overview.time-reports.table-empty'),
                        info: Translator.translate('ui.overview.time-reports.table-info'),
                        infoEmpty: Translator.translate('ui.overview.time-reports.table-empty'),
                        infoFiltered: Translator.translate('ui.overview.time-reports.table-info-filtered'),
                        infoPostFix: '',
                        infoThousands: brixxUtils.numberFormatGroupSeparator(),
                        lengthMenu: Translator.translate('ui.overview.time-reports.table-length-menu'),
                        loadingRecords: Translator.translate('ui.overview.time-reports.table-loading'),
                        processing: Translator.translate('ui.overview.time-reports.table-processing'),
                        search: Translator.translate('ui.overview.time-reports.table-search'),
                        zeroRecords: Translator.translate('ui.overview.time-reports.table-empty'),
                        paginate: {
                            first: Translator.translate('ui.overview.time-reports.table-pager-first'),
                            previous: Translator.translate('ui.overview.time-reports.table-pager-previous'),
                            next: Translator.translate('ui.overview.time-reports.table-pager-next'),
                            last: Translator.translate('ui.overview.time-reports.table-pager-last')
                        }
                    },
                    ajax: {
                        url: listUrl,
                        type: 'POST',
                        data: {
                            // Limit the returned information to the
                            // required fields only.
                            fields: [
                                'id',
                                'projectKey',
                                'projectName',
                                'employeeName',
                                'date',
                                'hours',
                                'viewUrl',
                                'editUrl'
                            ]
                        },
                        dataSrc: json => {
                            return json.timeReports;
                        }
                    },
                    columnDefs: [
                        {
                            targets: [0],
                            render: $.fn.dataTable.render.timestamp,
                            responsivePriority: 1
                        },
                        {
                            targets: [1],
                            responsivePriority: 10
                        },
                        {
                            targets: [2],
                            responsivePriority: 2
                        },
                        {
                            targets: [3],
                            responsivePriority: 3
                        },
                        {
                            targets: [4],
                            searchable: false,
                            responsivePriority: 1,
                            width: 50
                        }
                    ],
                    columns: [
                        {
                            data: 'date'
                        },
                        {
                            data: 'hours'
                        },
                        {
                            data: 'employeeName'
                        },
                        {
                            data: 'projectName'
                        },
                        {
                            data: null,
                            render: (data, type, row) => {
                                var markup = '';
                                markup += '<button class="btn-kebab-toggle" type="button" data-toggle="kebab-menu-' + row.id + '">Toggle Dropdown</button><div class="dropdown-pane" data-position="bottom" data-alignment="right" id="kebab-menu-' + row.id + '" data-dropdown data-auto-focus="true" data-close-on-click="true">';
                                markup += '<ul class="vertical menu">';
                                markup += (row.editUrl) ? '<li><a href="' + row.editUrl + '" class="edit-link" target="_blank">' + Translator.translate('ui.overview.time-reports.single.edit') + '</a></li>' : '';
                                markup += '</ul>';
                                markup += '</div>';
                                return markup;
                            }
                        }
                    ],
                    footerCallback: (row, data, start, end, display) => {
                        if (!data || data.length === 0 || display.length === 0) {
                            $('tfoot .total-hours', $table).html(0);
                        }
                        let totalHours = 0;
                        display.forEach(index => {
                            const current = data[index];
                            totalHours += current.hours;
                        });
                        $('tfoot .total-hours', $table).html(totalHours);
                    },
                    drawCallback: (settings) => {
                        $(context).foundation();
                        // Init foundation dropdown for all kebab menus with class btn-kebab-toggle.
                        $('.btn-kebab-toggle', $list).each((index, kebabToggle) => {
                            new Foundation.Dropdown($(kebabToggle), {});
                        });
                    },
                    initComplete: function (settings, json) {
                        $('.dataTables_filter input', $list).once('datatable-input-wrap').each((index, filterInput) => {
                            const $filterInput = $(filterInput);
                            $filterInput.parent().append($('<div class="input-wrapper"></div>').append($filterInput.detach()));
                        });
                        $('.datatables-filters', $list).once('datatable-filter-wrap').each((index, filterWrapper) => {
                            const $filterWrapper = $(filterWrapper);
                            $('.dt-filter-wrapper', $list).append($filterWrapper.detach());
                        });

                        $('.dt-period-wrapper', $list).hide();
                        $('.period-inputs', $list).once('datatable-period-wrap').each((index, periodInputs) => {
                            const $periodInputs = $(periodInputs);
                            $('.dt-period-wrapper', $list).append($periodInputs.detach());
                            $('.dt-period-wrapper', $list).show();
                        });
                    }
                });

                $dateFilter.once('project-overview-date-filter').on('change', function () {
                    const url = new URL(window.location.href);
                    const params = new URLSearchParams(url.search);

                    params.set('mode', this.value);
                    params.delete('year');
                    params.delete('month');
                    params.delete('day');
                    params.delete('fraction');

                    url.search = params.toString();

                    Brixx.uiAjax($(this), {url: url.toString()});
                });

                $projectFilter.once('project-overview-project-filter').on('change', function () {
                    const url = new URL(window.location.href);
                    const params = new URLSearchParams(url.search);

                    if (this.value === 'all') {
                        params.delete('project');
                    }
                    else {
                        params.set('project', this.value);
                    }

                    url.search = params.toString();

                    Brixx.uiAjax($(this), {url: url.toString()});
                });
            });
        }
    };
})($, Brixx, Translator, brixxUtils, datatablesFilter, datatablesButtons, Foundation, JSZip);
