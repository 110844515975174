// jQuery.
import jQuery from 'jquery';
// jQuery Once.
import 'jquery-once';
// jQuery Ajax with automatic retries.
import '../misc/jquery.ajax';
// Brixx object.
import Brixx from '../misc/brixx';
// Translator.
import Translator from '../misc/translator';
// BRIXX UI actions.
import '../components/ui-actions';
// BRIXX notification messages.
import '../components/ui-messages';
// BRIXX forms.
import '../components/ui-forms';

/**
 * Module enclosure.
 *
 * @param {jQuery} $
 *   jQuery.
 * @param {Brixx} Brixx
 *   The BRIXX base class.
 * @param {Translator} Translator
 *   The BRIXX translation engine.
 */
(($, Brixx, Translator) => {

    // Whether the UI ajax handlers have already been initialized.
    if (typeof Brixx.modules.uiAjax !== 'undefined') {
        // Bail out.
        return;
    }

    /**
     * Execute ajax call and execute UI actions
     *
     * BRIXX UI Actions enable the server-side BRIXX App to trigger
     * interactions within the client-side BRIXX UI, if e.g., Ajax
     * requests are answered by the BRIXX App and require a certain
     * change or automatic action within the BRIXX UI.
     *
     * @param {string|Document|HTMLElement|jQuery} element
     *   An element to attach to.
     * @param {object} ajaxOptions
     *  Ajax options.
     */
    Brixx.uiAjax = (element, ajaxOptions) => {
        const $element = $(element);

        if ($element.length === 0) {
            return;
        }

        let $loader = null;
        if (!$element.is('.no-loader')) {
            $loader = $element.data('loader') ? $($element.data('loader')).removeClass('hide') : $('<span class="ui-actions-loader"></span>').insertAfter($element);
            if ($loader.length === 0) {
                $loader = null;
            }
        }

        let $wrapper = $element.data('ajax-wrapper') ? $($element.data('ajax-wrapper')) : null;

        $.ajax(ajaxOptions)
            .done(responseData => {
                if (!Brixx.uiAjaxResponseHandler(responseData, $wrapper) && $wrapper.length > 0) {
                    Brixx.detachModules($wrapper);
                    $wrapper.html(responseData);
                    Brixx.attachModules($wrapper);
                }
            })
            .fail((responseData, statusText) => {
                Brixx.uiAjaxResponseHandler(responseData, $wrapper, statusText);
            })
            .always(() => {
                if ($loader) {
                    if ($element.data('loader') && $loader.is($element.data('loader'))) {
                        $loader.addClass('hide');
                    }
                    else {
                        $loader.remove();
                    }
                }
            });
    };

    /**
     * Handles BRIXX UI AJAX response data.
     *
     * @param {*} responseData
     *   The data returned from an AJAX call.
     * @param {string|Document|HTMLElement|jQuery} uiWrapper
     *   (Optional) Target content wrapper element(s).
     * @param {*} [errorStatus]
     *   (Optional) Status text returned in case of an error.
     *
     * @return {boolean}
     *   `true`, if the response was successfully handled,
     *   `false` otherwise.
     */
    Brixx.uiAjaxResponseHandler = (responseData, uiWrapper, errorStatus) => {
        let uiResponseSuccess = false;
        const $uiWrapper = typeof uiWrapper !== 'undefined' && uiWrapper ? $(uiWrapper) : null;

        // Update BRIXX JS settings.
        if (typeof responseData.settings !== 'undefined') {
            Brixx.addSettings(responseData.settings);
        }

        // Handle possible errors.
        if (
            typeof errorStatus !== 'undefined'
            || typeof responseData.message !== 'undefined'
            || typeof responseData.error !== 'undefined'
        ) {
            let errorMessage = 'ui.general.data-invalid';
            if (typeof errorStatus !== 'undefined' && errorStatus === 'timeout') {
                errorMessage = 'ui.error.408.message';
            }
            else {
                if (typeof responseData.message !== 'undefined' && responseData.message) {
                    errorMessage = responseData.message;
                }
                if (typeof responseData.error !== 'undefined' && responseData.error) {
                    errorMessage = responseData.error;
                }
            }

            Brixx.showError(errorMessage);
            if ($uiWrapper && $uiWrapper.length !== 0) {
                // Detach triggers from the uiWrapper element's children.
                Brixx.detachModules($uiWrapper, null, 'unload');
                $uiWrapper.html('<div class="error">' + Translator.translate(errorMessage) + '</div>');
            }
        }

        // Handle UI actions.
        if (typeof responseData.uiActions !== 'undefined') {
            uiResponseSuccess = true;
            Brixx.executeUiActions(responseData.uiActions);
        }
        else if (
            typeof responseData.responseJSON !== 'undefined'
            && typeof responseData.responseJSON.uiActions !== 'undefined'
        ) {
            uiResponseSuccess = true;
            Brixx.executeUiActions(responseData.responseJSON.uiActions);
        }

        return uiResponseSuccess;
    };

    /**
     * BRIXX UI ajax module.
     *
     * Binds Ajax request handlers to elements with
     * `.use-ajax` CSS class.
     *
     * These elements MUST have either a `data-ajax-url`
     * (e.g. buttons, images, and other DOM elements) or a
     * `href` attribute (links) referring to a BRIXX App
     * endpoint that returns a JSON response with `uiActions`.
     *
     * An optional `data-ajax-event` attribute allows
     * to use an alternative to the default `click` event for
     * binding the Ajax request trigger.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.uiAjax = {

        /**
         * Attach module callback.
         *
         * Initializes the module after page loads and Ajax requests.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         * @param {object} settings
         *   An object containing settings for the current context.
         */
        attach: (context, settings) => {
            $('.use-ajax', context).once('useAjax').each((index, element) => {
                const $element = $(element);

                if ($element.is('form')) {
                    $element.on('submit.useAjax', event => {
                        event.preventDefault();
                        let data;
                        let submitter;
                        if (typeof event.submitter !== 'undefined') {
                            submitter = event.submitter;
                        }
                        else if (typeof event.originalEvent !== 'undefined' && typeof event.originalEvent.submitter !== 'undefined') {
                            submitter = event.originalEvent.submitter;
                        }

                        let validate = true;
                        if (submitter && $(submitter).hasClass('no-validate')) {
                            validate = false;
                        }
                        Brixx.forms.ajaxSubmitReplace(event.currentTarget, validate, data, submitter);
                    });

                    return;
                }

                const url = $element.data('ajax-url') || $element.attr('href') || '';
                let triggers = $element.data('ajax-event') || 'click';

                // Allow to configure AJAX retries using data attributes.
                const timeout = ($element[0].hasAttribute('data-ajax-timeout')) ? parseInt($element.data('ajax-timeout')) : settings.ajaxTimeout;
                const retries = ($element[0].hasAttribute('data-ajax-retries')) ? parseInt($element.data('ajax-retries')) : settings.ajaxRetries;
                const retriesInterval = ($element[0].hasAttribute('data-ajax-retries-interval')) ? parseInt($element.data('ajax-retries-interval')) : settings.ajaxRetriesInterval;
                const dataTargets = ($element[0].hasAttribute('data-ajax-data')) ? $element.data('ajax-data').split(',') : [];

                if (triggers && url) {
                    let triggerElements = triggers.toString().split(' ');
                    triggers = '';
                    $.each(triggerElements, (index, trigger) => {
                        triggers = triggers + ' ' + trigger + '.useAjax';
                    });
                    $element.on(triggers, event => {
                        event.preventDefault();

                        const ajaxOptions = {
                            timeout: timeout,
                            retries: retries,
                            retriesInterval: retriesInterval,
                            url: url
                        };
                        if (dataTargets.length > 0) {
                            let data = {};
                            let i = 0;
                            for (; i < dataTargets.length; i++) {
                                let parts = dataTargets[i].split(':');
                                if (parts.length !== 2) {
                                    continue;
                                }
                                data[parts[0].trim()] = $(parts[1]).val();
                            }
                            ajaxOptions.data = data;
                        }
                        Brixx.uiAjax($element, ajaxOptions);

                        if ($element[0].hasAttribute('data-ajax-actions') && $element.data('ajax-actions')) {
                            const actions = $element.data('ajax-actions');
                            Brixx.executeUiActions(actions);
                        }
                    });
                }
            });
        },

        /**
         * Detach module callback.
         *
         * Removes the form module bindings.
         *
         * @type {Brixx~modulesDetach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to detach from.
         */
        detach: context => {
            // Convert locale-aware formats back to system formats.
            $('.use-ajax', context).findOnce('useAjax').each((index, element) => {
                const $element = $(element);

                if ($element.is('form')) {
                    $element.off('submit.useAjax').removeOnce('useAjax');
                    return;
                }

                let triggers = $element.data('ajax-event') || 'click';
                let triggerElements = triggers.toString().split(' ');
                triggers = '';
                $.each(triggerElements, (index, trigger) => {
                    triggers = triggers + ' ' + trigger + '.useAjax';
                });

                $element.off(triggers).removeOnce('useAjax');
            });
        }

    };

})(jQuery, Brixx, Translator);
