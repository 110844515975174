import jQuery from 'jquery';
import Brixx from './brixx';
import brixxUtils from './brixxUtils';

(($, Brixx, brixxUtils, window) => {

    /**
     * A simple translator for the BRIXX website.
     */
    class Translator {

        /**
         * Initialize the Translator by providing options.
         *
         * @param {object} [options=Translator.defaultConfig]
         *   (Optional) Translator options.
         *   Defaults to `Translator.defaultConfig`.
         */
        constructor(options = {}) {
            if (typeof options !== 'object' || Array.isArray(options)) {
                options = {};
            }

            this.languages = new Map();
            this.config = Object.assign(Translator.defaultConfig, options);

            const {registerGlobally, detectLanguage} = this.config;

            if (registerGlobally) {
                this._globalObject[registerGlobally] = this.translate.bind(this);
            }

            if (detectLanguage && this._env === 'browser') {
                this._detectLanguage();
            }
        }

        /**
         * Return the global object, depending on the environment.
         *
         * If the script is executed in a browser, return the window object,
         * otherwise, in Node.js, return the global object.
         *
         * @return {object}
         *   The global object.
         */
        get _globalObject() {
            if (this._env === 'browser') {
                return window;
            }

            return global;
        }

        /**
         * Check and return the environment in which the script is executed.
         *
         * @return {string} The environment
         */
        get _env() {
            if (typeof window !== 'undefined') {
                return 'browser';
            }
            else if (typeof module !== 'undefined' && module.exports) {
                return 'node';
            }

            return 'browser';
        }

        /**
         * Detect the users preferred language. If the language is stored in
         * localStorage due to a previous interaction, use it.
         * If no localStorage entry has been found, use the default browser language.
         */
        _detectLanguage() {
            const inMemory = localStorage.getItem(this.config.persistKey);

            if (inMemory) {
                this.config.defaultLanguage = inMemory;
            }
            else {
                const lang = navigator.languages
                    ? navigator.languages[0]
                    : navigator.language;

                this.config.defaultLanguage = lang.substr(0, 2);
            }
        }

        /**
         * Get a translated value from a JSON by providing a key. Additionally,
         * the target language can be specified as the second parameter.
         *
         * @param {string} key
         *   The translation key.
         * @param {string} toLanguage
         *   The target language.
         *
         * @return {string}
         *   The translated value.
         */
        _getMessageFromTranslations(key, toLanguage) {
            const json = this.languages.get(toLanguage);

            return key.split('|').reduce((obj, i) => (obj ? obj[i] : null), json);
        }

        /**
         * Translate a given key into the specified language if it exists
         * in the translation file. If not or if the language hasn't been added yet,
         * the return value is `null`.
         *
         * @param {string} key
         *   The message key to translate.
         * @param {object.<string, string>} [args]
         *   An object of replacements pairs to make after translation. Incidences
         *   of any key in this object are replaced with the corresponding value.
         *   See {@link Translator.formatString}.
         * @param {string} domain
         *   (Optional) The message domain.
         *   Defaults to this._defaultDomain.
         * @param {string} language
         *   (Optional) The target language
         *
         * @return {string}
         *   The translated message.
         */
        translate(key, args, domain = this.config.defaultDomain, language = this.config.defaultLanguage) {
            if (typeof key !== 'string') {
                return '';
            }
            if (key.length === 0) {
                return '';
            }

            if (!this.languages.has(language)) {
                return key;
            }

            let text = this._getMessageFromTranslations(domain + '|' + key, language);

            if (!text) {
                return key;
            }

            if (args) {
                text = brixxUtils.formatString(text, args);
            }

            return text;
        }

        /**
         * Formats a string containing a count of items.
         *
         * This function ensures that the string is pluralized correctly.
         *
         * @param {number} count
         *   The item count to display.
         * @param {string} singular
         *   The string for the singular case. Please make sure it is clear this is
         *   singular, to ease translation (e.g. use "1 new comment" instead of "1
         *   new"). Do not use @count in the singular string.
         * @param {string} plural
         *   The string for the plural case. Please make sure it is clear this is
         *   plural, to ease translation. Use @count in place of the item count, as in
         *   "@count new comments".
         * @param {object} [args]
         *   An object of replacements pairs to make after translation. Incidences
         *   of any key in this array are replaced with the corresponding value.
         *   See {@link Brixx.formatString}.
         *   Note that you do not need to include @count in this array.
         *   This replacement is done automatically for the plural case.
         * @param {string} domain
         *   (Optional) The message domain.
         *   Defaults to this._defaultDomain.
         * @param {string} language
         *   (Optional) The target language
         *
         * @return {string}
         *   A translated string.
         */
        translatePlural(count, singular, plural, args, domain = this.config.defaultDomain, language = this.config.defaultLanguage) {
            args = args || {};
            args['@count'] = count;

            const translations = [
                Translator.translate(singular, args, domain, language),
                Translator.translate(plural, args, domain, language)
            ];
            let index = 0;

            // Determine the index of the plural form.
            if (args['@count'] !== 0) {
                index = 1;
            }

            return translations[index];
        }

        /**
         * Add a translated message to the translations.
         *
         * @param {string} key
         *   The message key.
         * @param {string} message
         *   The translated message.
         * @param {string} domain
         *   The target domain.
         * @param {string} language
         *   The language identifier.
         *
         * @return {object}
         *   Translator instance.
         */
        add(key, message, domain, language) {
            // Invalid parameters.
            if (typeof key !== 'string' || typeof message !== 'string' || typeof domain !== 'string' || typeof language !== 'string') {
                return this;
            }
            if (key.length === 0 || domain.length === 0 || language.length === 0) {
                return this;
            }

            if (!this.languages.has(language)) {
                this.languages.set(language, {
                    domain: {
                        key: message
                    }
                });
            }
            else {
                let domains = this.languages.get(language);
                domains[domain][key] = message;
            }

            return this;
        }

        /**
         * Add a translation resource to the Translator object. The language
         * can then be used to translate single keys or the entire page.
         *
         * @param {string} language The target language to add
         * @param {string} json The language resource file as JSON
         * @return {object} Translator instance
         */
        addLanguage(language, json) {
            if (typeof language !== 'string') {
                return this;
            }

            if (language.length === 0) {
                return this;
            }

            if (Array.isArray(json) || typeof json !== 'object') {
                return this;
            }

            if (Object.keys(json).length === 0) {
                return this;
            }

            this.languages.set(language, json);

            return this;
        }

        /**
         * Remove a translation resource from the Translator object. The language
         * won't be available afterwards.
         *
         * @param {string} language
         *   The target language to remove.
         * @param {string} domain
         *   (Optional) The target domain to remove. If left empty, the entire
         *   language will be removed.
         *
         * @return {object}
         *   Translator instance
         */
        remove(language, domain) {
            // Language not given. Do nothing.
            if (typeof language !== 'string') {
                return this;
            }

            // Empty language. Do nothing.
            if (language.length === 0) {
                return this;
            }

            if (typeof domain !== 'string' || domain.length === 0) {
                this.languages.delete(language);
            }
            else {
                // Delete domain only.
            }

            return this;
        }

        /**
         * Set the default language.
         *
         * @param {string} language
         *   The new default language.
         *
         * @return {object}
         *   Translator instance
         */
        setDefaultLanguage(language) {
            // Language not given. Do nothing.
            if (typeof language !== 'string') {
                return this;
            }

            // Empty language. Do nothing.
            if (language.length === 0) {
                return this;
            }

            this.config.defaultLanguage = language;

            return this;
        }

        /**
         * Set the default domain.
         *
         * @param {string} domain
         *   The new default domain.
         *
         * @return {object}
         *   Translator instance
         */
        setDefaultDomain(domain) {
            // Domain not given. Do nothing.
            if (typeof domain !== 'string') {
                return this;
            }

            // Empty domain. Do nothing.
            if (domain.length === 0) {
                return this;
            }

            this.config.defaultDomain = domain;

            return this;
        }

        /**
         * Return the default config object whose keys can be overriden
         * by the user's config passed to the constructor.
         *
         * @return {object}
         *   The default configuration.
         */
        static get defaultConfig() {
            return {
                defaultLanguage: 'en',
                defaultDomain: 'messages',
                detectLanguage: true,
                registerGlobally: '__'
            };
        }
    }

    // Register the translator globally and bind the translate function
    // to the global '__()' function.
    // To add translations, either use the Translator.add() or the
    // Translator.addLanguage() function.
    if (typeof window.Translator === 'undefined') {
        window.Translator = new Translator();
    }

    /**
     * Initialization of translation strings.
     *
     * This initialization assumes a global window.uiTranslations object with
     * all translation strings. It may be provided by the BRIXX Apps'
     * Translator controller, which generates an according JavaScript
     * snippet.
     *
     * @type {Brixx~module}
     */
    Brixx.modules.Translator = {

        /**
         * Attach module callback.
         *
         * @type {Brixx~modulesAttach}
         *
         * @param {HTMLDocument|HTMLElement|jQuery} context
         *   An element to attach to.
         */
        attach: context => {
            $('script[type="application/json"][data-brixx-selector="brixx-translations-json"]', context).once('init-translations').each((index, json) => {
                const translations = JSON.parse($(json).html());

                if (typeof translations.uiLocale !== 'undefined') {
                    window.Translator.setDefaultLanguage(translations.uiLocale);
                }
                if (typeof translations.uiTranslations !== 'undefined') {
                    for (let language in translations.uiTranslations) {
                        if (Object.prototype.hasOwnProperty.call(translations.uiTranslations, language)) {
                            window.Translator.addLanguage(language, translations.uiTranslations[language]);
                        }
                    }
                }
            });
        }

    };

})(jQuery, Brixx, brixxUtils, window);

export default window.Translator;
